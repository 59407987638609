import { Languages } from '@/core/typing'
import { create } from 'zustand'

interface StoreLanguageState {
    lang: Languages
    setLang: (lang: Languages) => void
    clearLang: () => void
}

export const useLanguageStore = create<StoreLanguageState>(set => ({
    lang: Languages.EN,
    setLang: (data: Languages) => set({ lang: data }),
    clearLang: () => set({ lang: Languages.EN }),
}))
