import { GoBack } from '@/core/components'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { AccountGroupWidget } from '@/core/widgets'
import fullLogo from '@/assets/images/full-logo.svg'

interface IWeatherLocation {
    city: string
    country: string
    region: string
}
interface IProps {
    weatherLocation: IWeatherLocation
    clearSearchCity: () => void
}
export const MobileHeaderWeather = ({
    weatherLocation,
    clearSearchCity,
}: IProps) => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        clearSearchCity()
        navigate(-1)
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.row}>
                    <GoBack onClick={handleGoBack} />
                    <img src={fullLogo} className={styles.logo} />
                </div>
                <AccountGroupWidget />
            </div>
            <div className={styles.location}>
                <p className={styles.label}>
                    {weatherLocation.city}{' '}
                    <span>{`(${weatherLocation.region} ${weatherLocation.country})`}</span>
                </p>
            </div>
        </div>
    )
}
