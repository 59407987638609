import { WindDirection } from '../typing/enums'

export const windDirecrionConfig: any = {
    [WindDirection.North]: 0,
    [WindDirection.NorthNortheast]: 22.5,
    [WindDirection.Northeast]: 45,
    [WindDirection.EastNortheast]: 67.5,
    [WindDirection.East]: 90,
    [WindDirection.EastSoutheast]: 112.5,
    [WindDirection.Southeast]: 135,
    [WindDirection.SouthSoutheast]: 157,
    [WindDirection.South]: 180,
    [WindDirection.SouthSouthwest]: 202.5,
    [WindDirection.Southwest]: 225,
    [WindDirection.WestSouthwest]: 247.5,
    [WindDirection.West]: 270,
    [WindDirection.WestNorthwest]: 292.5,
    [WindDirection.Northwest]: 315,
    [WindDirection.NorthNorthwest]: 337.5,
}
