import { useResizeWindow } from '@/core/hooks'
import styles from './styles.module.css'
import { FaqQuestion } from '../faq-question'
import { FAQsConfig } from '../../config'

export const FrequentlyAsked = () => {
    const { width: windowWidth } = useResizeWindow()

    const title = windowWidth < 768 ? 'FAQs' : 'Frequently asked questions'
    return (
        <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            <div className={styles.list}>
                {FAQsConfig.map(it => (
                    <FaqQuestion
                        idCard={it.id}
                        question={it.question}
                        answer={it.answer}
                    />
                ))}
            </div>
        </div>
    )
}
