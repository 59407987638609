import { useEffect, useRef } from 'react'

export const usePlacesService = (mapRef: React.RefObject<HTMLDivElement>) => {
    const getShortCountryName = (names: any) => names['short_name']
    const getLongCountryName = (names: any) => names['long_name']
    const placesServiceRef = useRef<google.maps.places.PlacesService | null>(
        null,
    )

    useEffect(() => {
        placesServiceRef.current = new google.maps.places.PlacesService(
            mapRef.current!,
        )

        return () => {
            placesServiceRef.current = null
        }
    }, [mapRef.current])

    const getCountryInfo = async (placeId: string) => {
        if (!placesServiceRef.current) return null

        return new Promise<{ shortName: string; longName: string } | null>(
            (resolve, reject) => {
                placesServiceRef.current!.getDetails(
                    { placeId },
                    (placeResult, status) => {
                        if (
                            status === google.maps.places.PlacesServiceStatus.OK
                        ) {
                            const shortName = getShortCountryName(
                                placeResult.address_components[0],
                            )
                            const longName = getLongCountryName(
                                placeResult.address_components[0],
                            )
                            resolve({ shortName, longName })
                        } else {
                            reject(
                                new Error(
                                    `Error fetching place details: ${status}`,
                                ),
                            )
                        }
                    },
                )
            },
        )
    }

    return { getCountryInfo, getShortCountryName }
}
