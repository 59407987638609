import { useState } from 'react'
import styles from '../styles.module.css'
import { AdvantesAtom } from './advantes.atom'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    includeBasic: boolean
    advantes?: string[]
}
export const InterectiveBenefitsAtom = ({ advantes }: IProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()
    const toggleOpen = () => setOpen(!open)
    return (
        <div className={styles.interective_benefits}>
            <div onClick={toggleOpen} className={styles.row_title_benefits}>
                <p>{t('benefits')}</p>
                <div
                    className={`${styles.arrow} ${open ? styles.up : styles.down}`}>
                    <Icon name='arrow-down' size={24} color='#0F0E0E' />
                </div>
            </div>
            <div
                className={`${styles.benefits_list} ${open ? styles.show : styles.hide}`}>
                <AdvantesAtom list={advantes} />
            </div>
        </div>
    )
}
