import {
    BaseDatePicker,
    Error,
    FormControll,
    Loader,
    PrimaryButton,
    SelectControl,
    UploadPhoto,
} from '@/core/components'
import { useForm } from '@/core/hooks'
import { IAccountForm } from '../../typing/interfaces'
import { validateAccount } from '../../validators'
import { SelectCountry } from '@/core/widgets'
import { genderOptions } from '../select-gender/gender.options'
import moment from 'moment'
import styles from './styles.module.css'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import {
    finishUploadAvatar,
    getUploadAvatarUrl,
    updateAccountReq,
} from '../../api'
import { message } from 'antd'
import { IFile } from '@/core/typing'
import { presignedUploaderService } from '@/core/services'
import dayjs from 'dayjs'
import { MobileTabFooterAtom } from '../mobile-account-tabs/atoms'
import * as Sentry from '@sentry/react'
import { CustomTooltip } from '@/core/components/tooltips'
import { useTranslation } from 'react-i18next'
import { getDataFromApiError } from '@/core/helpers'

const errorExistEmailKey = 'user_already_exists'
interface IProps {
    data: IAccountForm
    avatarUrl: string
    reloadAccount: () => Promise<void>
    editEmail: boolean
}
export const AccountForm = ({
    data,
    reloadAccount,
    avatarUrl,
    editEmail,
}: IProps) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [avatar, setAvatar] = useState(avatarUrl)
    const [file, setFile] = useState<IFile>(null)
    const [emailTooltip, setEmailTooltip] = useState(false)
    const refForm = useRef(null)

    const defaultValue: IAccountForm = {
        birthday: data?.birthday,
        email: data?.email,
        gender: data?.gender,
        name: data?.name,
        nationality: {
            countryCode: '',
            locationName: data?.nationality,
        },
        location: {
            countryCode: data?.location?.countryCode,
            locationName: data?.location?.locationName,
        },
    }
    const { values, setField, errors, onSubmit, set, setErrors, setError } =
        useForm<IAccountForm>(defaultValue, validateAccount)
    const onToggleEdit = (val: boolean) => {
        setIsEdit(val)
    }
    const starSymbol = '\u002A'

    const onChangeField = (key: keyof IAccountForm, val: any) => {
        setField(key, val)
    }

    const disabledDate = (current: any) => {
        return current && moment().endOf('day') < current
    }
    const resetForm = () => {
        set({
            nationality: {
                countryCode: '',
                locationName: _.defaultTo(data.nationality, ''),
            },
            email: _.defaultTo(data?.email, ''),
            name: _.defaultTo(data.name, ''),
            birthday: _.defaultTo(data?.birthday, ''),
            location: _.defaultTo(data.location, null),
            gender: _.defaultTo(data.gender, null),
        })
        setErrors(null)
        setFile(null)
        setAvatar(avatarUrl)

        onToggleEdit(false)
    }

    const updateAccount = async () => {
        setIsLoading(true)
        try {
            if (file) {
                await presignedUploaderService.upload(
                    file,
                    (params: any) => getUploadAvatarUrl(params),
                    (params: any) => finishUploadAvatar(params),
                    {},
                )
            }
            await updateAccountReq({
                ..._.omit(values, ['birthday', 'nationality']),
                birthday: values?.birthday,
                nationality: values.nationality?.locationName,
            })
            await reloadAccount()

            message.success(t('successUpdateAccount'))

            setIsEdit(false)
        } catch (error: any) {
            const errorApi: any = getDataFromApiError(error)

            if (errorApi.key === errorExistEmailKey) {
                message.error(t('errorExistEmail'))
                setError('email', t('errorExistEmail'))
            }
            Sentry.captureException(error)
        }
        setIsLoading(false)
    }
    const handleClickInside = () => {
        setEmailTooltip(() => !emailTooltip)
    }

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (refForm.current && !refForm.current.contains(event.target)) {
                setEmailTooltip(false)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    const translateOptions = genderOptions.map(it => ({
        ...it,
        label: t(it.label),
    }))

    return (
        <div
            style={{ height: isLoading && 318 }}
            className={styles.container}
            data-testid='profile-container'>
            <div className={styles.header_form}>
                <p className={styles.label_form}>{t('profile')}</p>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={resetForm}
                        className={`${styles.btn_cancel} ${!isEdit ? styles.show : styles.hide}`}
                        id='profile-cancel-button'
                    />

                    <PrimaryButton
                        label={isEdit ? t('save') : t('edit')}
                        onClick={() =>
                            !isEdit
                                ? onToggleEdit(true)
                                : onSubmit(updateAccount)
                        }
                        className={styles.btn_save}
                        id='profile-save-or-edit-button'
                    />
                </div>
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <div className={styles.form_fields} ref={refForm}>
                    <div className={styles.uploader_container}>
                        <UploadPhoto
                            value={file}
                            disabled={!isEdit}
                            onChangeFile={setFile}
                            clear={() => setFile(null)}
                            avatarUrl={avatar}
                        />
                    </div>
                    <div className={styles.column_fields} ref={refForm}>
                        <FormControll
                            setFocus={val => setEmailTooltip(!val)}
                            inputStyle={{ opacity: !isEdit ? 0.5 : 1 }}
                            disabled={!isEdit}
                            onChangeVal={val => onChangeField('name', val)}
                            value={values?.name}
                            label={`${t('fullname')} ${starSymbol}`}
                            error={errors?.name}
                            id='full-name'
                        />

                        <SelectCountry
                            setFocus={val => setEmailTooltip(!val)}
                            disabled={!isEdit}
                            onSelectCountry={val =>
                                onChangeField('location', val)
                            }
                            label={t('countryResidence')}
                            data={values?.location}
                            id='country-of-residence'
                        />

                        <SelectControl
                            setFocus={val => setEmailTooltip(!val)}
                            disabled={!isEdit}
                            value={values?.gender}
                            label={t('selectGender')}
                            items={translateOptions}
                            onChangeSelect={(val: any) =>
                                onChangeField('gender', val?.value)
                            }
                            id='select-gender'
                        />
                    </div>
                    <div className={styles.column_fields}>
                        <div className={styles.email_field_container}>
                            <CustomTooltip
                                isShow={emailTooltip}
                                txtContent={t('disabledEmail')}
                                stylePosition={{
                                    position: 'absolute',
                                    top: -50,
                                    left: 0,
                                    zIndex: 999,
                                }}
                            />
                            <FormControll
                                disabled={!isEdit}
                                inputStyle={{ opacity: !isEdit ? 0.5 : 1 }}
                                onClickForm={handleClickInside}
                                onChangeVal={val => {
                                    onChangeField('email', val)
                                }}
                                value={values?.email}
                                label={`${t('email')} ${starSymbol}`}
                                error={errors?.email}
                                id='email'
                                readOnly={!editEmail}
                            />
                        </div>

                        <SelectCountry
                            setFocus={val => setEmailTooltip(!val)}
                            disabled={!isEdit}
                            onSelectCountry={val => {
                                onChangeField('nationality', val)
                            }}
                            label={t('citizenship')}
                            data={values?.nationality}
                            id='citizenship'
                        />

                        <div style={{ width: '100%' }}>
                            <BaseDatePicker
                                setFocus={val => setEmailTooltip(!val)}
                                styleLabel={{ opacity: !isEdit ? 0.5 : 1 }}
                                disabled={!isEdit}
                                value={
                                    values?.birthday
                                        ? dayjs(values?.birthday)
                                        : null
                                }
                                defaultValue={
                                    values?.birthday
                                        ? dayjs(values?.birthday)
                                        : null
                                }
                                status={errors?.birthday ? 'error' : ''}
                                size='middle'
                                format={'DD/MM/YYYY'}
                                onChangeDate={val =>
                                    onChangeField(
                                        'birthday',
                                        val?.format('YYYY-MM-DD'),
                                    )
                                }
                                disabledDate={disabledDate}
                                id='birth-date'
                            />
                            <Error>{errors?.birthday}</Error>
                        </div>
                    </div>
                </div>
            )}

            <MobileTabFooterAtom
                cancel={resetForm}
                isChangePassword
                onChangeEdit={onToggleEdit}
                isEdit={isEdit}
                save={() => onSubmit(updateAccount)}
            />
        </div>
    )
}
