import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from 'firebase/auth'

const firebaseConfig = {
    apiKey: 'AIzaSyDbgAQFbWSVpPj9rVxshpwhv2ht3K_wID0',
    authDomain: 'guido360-a1417.firebaseapp.com',
    projectId: 'guido360-a1417',
    storageBucket: 'guido360-a1417.appspot.com',
    messagingSenderId: '119545383107',
    appId: '1:119545383107:web:21b36b7b4662e1831aba76',
    measurementId: 'G-LMRJLZGDLG',
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const providerGoogle = new GoogleAuthProvider()
export const providerFacebook = new FacebookAuthProvider()
providerFacebook.addScope('email')
providerFacebook.addScope('public_profile')
providerFacebook.setCustomParameters({
    display: 'popup',
})

export const auth = getAuth()
auth.languageCode = 'it'
