import { Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import './style.css'
import { useState } from 'react'

interface IProps extends SelectProps {
    onChangeSelect: (val: string | DefaultOptionType) => void
    items: DefaultOptionType[]
    label: string
    disabled?: boolean
    id?: string
    setFocus?: (val: boolean) => void
}

export const SelectControl = ({
    onChangeSelect,
    items,
    label,
    disabled,
    setFocus,
    ...props
}: IProps) => {
    const [focused, setFocused] = useState(false)

    const handleFocus = () => {
        setFocused(true)
        setFocus(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }

    const getClasseLabel = () => {
        let classNames = 'label'
        switch (true) {
            case focused && !props.value:
                classNames += ` focused-select label_up`
                break
            case focused && !!props.value:
                classNames += ` focused-select label_up`
                break
            case !focused && !!props.value:
                classNames += ` label_up`
                break
            case !focused && !props.value:
                classNames += ` label_down`
        }
        return classNames
    }

    const getClasseSelect = () => {
        let classNames = 'base-select'
        switch (true) {
            case focused && !props.value:
                classNames += ` focused-select`
                break
            case focused && !!props.value:
                classNames += ` focused-select`
                break
        }
        return classNames
    }

    const labelSelect = () => {
        return (
            <p
                className={getClasseLabel()}
                style={{ opacity: disabled ? 0.5 : 1 }}>
                {label}
            </p>
        )
    }

    return (
        <div className={'select_container'}>
            <>{labelSelect()}</>

            <Select
                disabled={disabled}
                allowClear
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={getClasseSelect()}
                onChange={(_, opt) => onChangeSelect(opt)}
                options={items}
                {...props}
            />
        </div>
    )
}
