import { createStyleSheet } from '@/core/helpers'

interface IHeaderContent {
    txt: string
}

export const FirstCellHeaderAtom = ({ txt }: IHeaderContent) => {
    return (
        <div style={styles.container}>
            <p style={styles.label}>{txt}</p>
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: -16,
        padding: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    label: { color: '#2975B7', fontSize: 16, fontWeight: '600' },
})
