import { ConfigProvider, DatePicker } from 'antd'
import type { DatePickerProps } from 'antd'
import './style.css'
import { CSSProperties, useState } from 'react'
import { Error } from '../typography'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Locale } from 'antd/es/locale'
import localeEn from 'antd/locale/en_US'
import localeEs from 'antd/locale/es_ES'
import localeFr from 'antd/locale/fr_FR'
import localeDe from 'antd/locale/de_DE'

import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import { useLanguageStore } from '@/store'

interface IProps extends Omit<DatePickerProps, 'value'> {
    onChangeDate: (val: any) => void
    value: any
    id?: string
    error?: string
    styleLabel?: CSSProperties
    setFocus?: (val: boolean) => void
}
export const BaseDatePicker = ({
    onChangeDate,
    value,
    id,
    error,
    styleLabel,
    setFocus,
    ...props
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const [focused, setFocused] = useState(false)

    const starSymbol = '\u002A'

    const handleFocus = () => {
        setFocused(true)
        setFocus(true)
    }

    const handleBlur = () => {
        setFocused(false)
    }

    const handleChange = (date: any) => {
        onChangeDate(date)
    }
    const getClasseLabel = () => {
        let classNames = 'label'
        switch (true) {
            case focused && !value:
                classNames += ` focused label_up`
                break
            case focused && !!value:
                classNames += ` focused label_up`
                break
            case !focused && !!value:
                classNames += ` label_up`
                break
            case !focused && !value:
                classNames += ` label_down`
        }
        return classNames
    }

    const getClassePicker = () => {
        let classNames = 'base-date-picker'
        switch (true) {
            case focused && !value:
                classNames += ` focused`
                break
            case focused && !!value:
                classNames += ` focused`
                break
        }
        return classNames
    }

    const getClassNames = () => {
        let classNames = 'calendar_container'
        switch (true) {
            case focused:
                classNames += ` focused`
                break

            default:
                break
        }
        return classNames
    }

    const label = () => {
        return (
            <p className={getClasseLabel()} style={styleLabel}>
                {t('birthday')} {starSymbol}
            </p>
        )
    }
    const localeConfig: Record<string, Locale> = {
        es: localeEs,
        en: localeEn,
        fr: localeFr,
        de: localeDe,
    }

    dayjs.locale(lang)
    return (
        <div style={{ position: 'relative' }}>
            <div className={getClassNames()}>
                <>{label()}</>
                <ConfigProvider locale={localeConfig[lang]}>
                    <DatePicker
                        id={id}
                        // value={value ? dayjs(value) : null}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder=''
                        size='large'
                        className={getClassePicker()}
                        onChange={handleChange}
                        showNow={false}
                        {...props}
                    />
                </ConfigProvider>
            </div>
            <Error style={{ position: 'absolute', bottom: -17 }}>{error}</Error>
        </div>
    )
}
