import { Error } from '../../typography'
import { createStyleSheet } from '@/core/helpers'
import OtpInput from 'react-otp-input'
import { InputHTMLAttributes } from 'react'
import './styles.css'

interface FormControllProps {
    onChangeVal: (val: string) => void
    value: string
    error?: string
    mb?: number
}
export const FormControlCode = (props: FormControllProps) => {
    return (
        <div style={styles.container}>
            <OtpInput
                skipDefaultStyles
                shouldAutoFocus
                value={props.value}
                onChange={props.onChangeVal}
                numInputs={4}
                inputStyle={`code-input ${props.error ? 'error_field' : ''}`}
                inputType='number'
                placeholder='----'
                renderSeparator={<span style={{ width: 16 }} />}
                renderInput={(
                    input: InputHTMLAttributes<HTMLInputElement>,
                    index: number,
                ) => <input id={`code cell ${index}`} {...input} key={index} />}
            />
            {props.error ? (
                <Error style={styles.error}>{props.error}</Error>
            ) : null}
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        position: 'relative',
    },
    error: {
        position: 'absolute',
    },
})
