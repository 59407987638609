import {
    BaseDatePicker,
    FormControll,
    FormControlPassword,
    PrimaryButton,
    SelectControl,
} from '@/core/components'
import { SelectCountry } from '@/core/widgets'
import { IRegisterForm } from '../../typing'
import moment from 'moment'
import { genderOptions } from '@/modules/user/components/select-gender/gender.options'
import { TermsPolicy } from '@/core/widgets/term-policy'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    values: IRegisterForm
    onChange: (key: keyof IRegisterForm, val: any) => void
    errors: Partial<IRegisterForm>
    submit: () => void
}
export const RegisterForm = ({ values, onChange, errors, submit }: IProps) => {
    const { t } = useTranslation()

    const translateOptions = genderOptions.map(it => ({
        ...it,
        label: t(it.label),
    }))
    const starSymbol = '\u002A'

    const disabledDate = (current: any) => {
        return current && moment().endOf('day') < current
    }

    return (
        <div>
            <div className={styles.form_container}>
                <FormControll
                    onChangeVal={val => onChange('name', val)}
                    value={values.name}
                    label={`${t('fullname')} ${starSymbol}`}
                    error={errors.name}
                />

                <SelectCountry
                    onSelectCountry={val => {
                        onChange('nationality', val?.locationName)
                    }}
                    label={t('citizenship')}
                />

                <FormControll
                    type='email'
                    onChangeVal={val => onChange('email', val)}
                    value={values.email}
                    label={`${t('email')} ${starSymbol}`}
                    error={errors.email}
                />

                <div style={{ width: '100%' }}>
                    <BaseDatePicker
                        error={errors.birthday}
                        value={values?.birthday}
                        status={errors.birthday ? 'error' : ''}
                        size='middle'
                        format={'DD/MM/YYYY'}
                        onChangeDate={val =>
                            onChange('birthday', val?.format('YYYY-MM-DD'))
                        }
                        disabledDate={disabledDate}
                    />
                </div>

                <SelectCountry
                    onSelectCountry={val => {
                        onChange('location', val)
                    }}
                    label={t('countryResidence')}
                />

                <SelectControl
                    value={values.gender}
                    label={t('gender')}
                    items={translateOptions}
                    onChangeSelect={(val: any) =>
                        onChange('gender', val?.value)
                    }
                />

                <FormControlPassword
                    value={values.password}
                    onChangeVal={val => onChange('password', val)}
                    label={`${t('password')} ${starSymbol}`}
                    error={errors.password}
                />

                <FormControlPassword
                    value={values.confirmPassword}
                    onChangeVal={val => onChange('confirmPassword', val)}
                    label={`${t('confirmPassword')} ${starSymbol}`}
                    error={errors.confirmPassword}
                />
            </div>

            <TermsPolicy />

            <div className={styles.submit}>
                <PrimaryButton
                    label={t('continue')}
                    onClick={submit}
                    style={{ width: '100%' }}
                    labelStyle={{ fontSize: 14 }}
                />
            </div>
        </div>
    )
}
