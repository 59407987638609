import { Modal } from 'antd'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import handleX from '@/assets/images/handle-x.svg'
import { useTranslation } from 'react-i18next'

interface IProps {
    isOpen: boolean
    confirm: () => void
    close: () => void
}
export const CancelSubscribeModal = ({ isOpen, close, confirm }: IProps) => {
    const { t } = useTranslation()
    return (
        <Modal
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            styles={{ body: { width: '100%' } }}
            open={isOpen}>
            <Icon
                onClick={close}
                name='x'
                size={24}
                color='#CED3E1'
                style={{ position: 'absolute', top: 20, right: 20 }}
            />

            <div className={styles.content_modal}>
                <img src={handleX} style={{ height: 100, width: 100 }} />

                <p className={styles.title}>
                    {t('sureSubscribtion')}
                    <br />
                    {`${t('cancelSubscribtion')}?`}
                </p>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={close}
                        className={styles.cancel_btn}
                        labelStyle={{ fontSize: 14, fontWeight: '600' }}
                    />

                    <PrimaryButton
                        label={t('unsubscribe')}
                        onClick={confirm}
                        className={styles.confirm_btn}
                        labelStyle={{ fontSize: 14, fontWeight: '600' }}
                    />
                </div>
            </div>

            <p className={styles.info}>{t('canSubscribe')}</p>
        </Modal>
    )
}
