import { Button } from 'antd'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full-logo.svg'
import { Icon } from '@/core/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    component: JSX.Element
    goBack?: () => void
}
export const SignInLayout = ({ component, goBack }: IProps) => {
    const { t } = useTranslation()
    const goBackButton = () => {
        return (
            <Button className={styles.go_back} type='text' onClick={goBack}>
                <Icon name='left-arrow' size={20} color='#000000' />{' '}
                <p>{t('back')}</p>
            </Button>
        )
    }
    return (
        <div className={styles.container}>
            <div className={styles.img_illustration}></div>
            <div className={styles.right_container}>
                <div className={styles.header}>
                    <>{goBackButton()}</>
                    <i
                        onClick={goBack}
                        className={`icon-left-arrow ${styles.left_arrow}`}
                    />
                    <img src={fullLogo} className={styles.fullLogo} />
                </div>
                <div className={styles.content}>{component}</div>
            </div>
        </div>
    )
}
