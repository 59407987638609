import { IDataDayTable } from '../typing/interface'
import { useTranslation } from 'react-i18next'

export const useGetRows = (data: any): IDataDayTable[] => {
    const { t } = useTranslation()
    if (!data) return
    return [
        {
            label: `${t('temperature')}, ℃`,
            night5: `${data['night'][1]?.temp_c.toFixed(0)}°`,
            night2: `${data['night'][0]?.temp_c.toFixed(0)}°`,
            morning8: `${data['morning'][0]?.temp_c.toFixed(0)}°`,
            morning11: `${data['morning'][1]?.temp_c.toFixed(0)}°`,
            day2: `${data['day'][0]?.temp_c.toFixed(0)}°`,
            day5: `${data['day'][1]?.temp_c.toFixed(0)}°`,
            evening8: `${data['evening'][0]?.temp_c.toFixed(0)}°`,
            evening11: `${data['evening'][1]?.temp_c.toFixed(0)}°`,
            iconName: 'thermometer',
        },
        {
            label: `${t('windMetric')}`,
            night5: data['night'][1]?.wind_kph,
            night2: data['night'][0]?.wind_kph,
            morning8: data['morning'][0]?.wind_kph,
            morning11: data['morning'][1]?.wind_kph,
            day2: data['day'][0]?.wind_kph,
            day5: data['day'][1]?.wind_kph,
            evening8: data['evening'][0]?.wind_kph,
            evening11: data['evening'][1]?.wind_kph,
            iconName: 'wind',
            wind_dir: {
                night2: data['night'][0]?.wind_dir,
                night5: data['night'][1]?.wind_dir,
                morning8: data['morning'][0]?.wind_dir,
                morning11: data['morning'][1]?.wind_dir,
                day2: data['day'][0]?.wind_dir,
                day5: data['day'][1]?.wind_dir,
                evening8: data['evening'][0]?.wind_dir,
                evening11: data['evening'][1]?.wind_dir,
            },
        },
        {
            label: t('feelsLike'),
            night5: `${data['night'][1]?.feelslike_c.toFixed(0)}°`,
            night2: `${data['night'][0]?.feelslike_c.toFixed(0)}°`,
            morning8: `${data['morning'][0]?.feelslike_c.toFixed(0)}°`,
            morning11: `${data['morning'][1]?.feelslike_c.toFixed(0)}°`,
            day2: `${data['day'][0]?.feelslike_c.toFixed(0)}°`,
            day5: `${data['day'][1]?.feelslike_c.toFixed(0)}°`,
            evening8: `${data['evening'][0]?.feelslike_c.toFixed(0)}°`,
            evening11: `${data['evening'][1]?.feelslike_c.toFixed(0)}°`,
            iconName: 'flower-lotus',
        },
        {
            label: t('viceMetric'),
            night5: data['night'][1].pressure_mb,
            night2: data['night'][0].pressure_mb,
            morning8: data['morning'][0].pressure_mb,
            morning11: data['morning'][1].pressure_mb,
            day2: data['day'][0].pressure_mb,
            day5: data['day'][1].pressure_mb,
            evening8: data['evening'][0].pressure_mb,
            evening11: data['evening'][1].pressure_mb,
            iconName: 'trend-up',
        },
        {
            label: t('humidityTable'),
            night5: data['night'][1]?.humidity,
            night2: data['night'][0]?.humidity,
            morning8: data['morning'][0]?.humidity,
            morning11: data['morning'][1]?.humidity,
            day2: data['day'][0]?.humidity,
            day5: data['day'][1]?.humidity,
            evening8: data['evening'][0]?.humidity,
            evening11: data['evening'][1]?.humidity,
            iconName: 'drop',
        },
        {
            label: t('precipitationProbability'),
            night5: data['night'][1]?.chance_of_rain,
            night2: data['night'][0]?.chance_of_rain,
            morning8: data['morning'][0]?.chance_of_rain,
            morning11: data['morning'][1]?.chance_of_rain,
            day2: data['day'][0]?.chance_of_rain,
            day5: data['day'][1]?.chance_of_rain,
            evening8: data['evening'][0]?.chance_of_rain,
            evening11: data['evening'][1]?.chance_of_rain,
            iconName: 'umbrella',
        },
    ]
}
