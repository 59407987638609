import { useSession } from '@/core/hooks'
import { AuthModule } from '../auth/auth.module'
import { useEffect } from 'react'
import { PrivateModule } from './private.module'
import 'intro.js/introjs.css'
export const Root = () => {
    const { token, getSession } = useSession()

    useEffect(() => {
        getSession()
    }, [])

    if (token) {
        return <PrivateModule />
    }
    return <AuthModule />
}
