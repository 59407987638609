import { Drawer } from 'antd'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { Icon, Loader, PrimaryButton } from '@/core/components'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { EntryCountries } from '../../typing'
import _ from 'lodash'
import { getEntryRequirementsReq } from '../../api'
import * as Sentry from '@sentry/react'
import logo from '@/assets/images/full_logo.png'
import { useLanguageStore } from '@/store'
import { useTranslation } from 'react-i18next'
import html2pdf from 'html2pdf.js'

export const RequrementsWidget = () => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [countries, setCountries] = useState<EntryCountries>(null)
    const [txt, setTxt] = useState('')
    const [isDb, setIsDb] = useState<boolean>()

    const getEntryRequirements = async () => {
        setIsLoading(true)
        try {
            const { data } = await getEntryRequirementsReq({
                from: countries?.from?.code,
                to: countries?.to?.code,
                citizenship: countries?.citizenship,
                lang,
            })
            setIsDb(data.source === 'db')

            setTxt(data.entryRequirements)
        } catch (error) {
            setTxt('')
            Sentry.captureException(error)
        }
        setIsLoading(false)
    }

    useEventsListener('requrements', source => {
        setOpen(source?.isShow)
        setCountries({
            from: source?.data?.from,
            to: source?.data?.to,
            citizenship: source?.data?.citizenship,
        })
    })

    useEffect(() => {
        if (open) {
            getEntryRequirements()
        }
    }, [open])

    const close = () => setOpen(false)
    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '736px'
        }
    }

    const notes = isDb
        ? `* ${t('artificialIntelligenc')}`
        : `* ${t('personalizedData')}`

    const convertHtmlToPdf = (): void => {
        const container = document.createElement('div')
        container.innerHTML = container.innerHTML = `
        <div style="text-align: center; margin-bottom: 20px;">
            <img src="${logo}" alt="Logo" style="width: 100px; height: auto;"/>
            <h1 style="font-size: 24px; margin-top: 10px;">${`${countries?.from?.name} to ${countries.to?.name}`}</h1>
        </div>
        <div style='line-height:20px;'>${txt}</div>
    `
        document.body.appendChild(container)

        const options = {
            margin: [10, 10, 10, 10],
            filename: 'entry-req.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2,
                logging: true,
                dpi: 192,
                letterRendering: true,
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }

        html2pdf()
            .from(container)
            .set(options)
            .save()
            .then(() => {
                document.body.removeChild(container)
            })
    }
    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            styles={{
                body: { padding: 0, paddingBottom: 113 },
                wrapper: { width: drawerWidth() },
            }}
            onClose={close}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.header}>
                        <p className={styles.label}>
                            {countries?.from?.name} to {countries.to?.name}
                        </p>
                        <div />

                        <div
                            style={{
                                display: 'flex',
                                gap: 20,
                                alignItems: 'center',
                            }}>
                            <PrimaryButton
                                label='Download docs'
                                className={styles.download_btn}
                                labelStyle={{ fontSize: 14 }}
                                onClick={convertHtmlToPdf}
                            />
                            <Icon
                                name='x'
                                size={24}
                                color='#717375'
                                onClick={close}
                            />
                        </div>
                    </div>

                    <div className={styles.drawer_container}>
                        {!txt ? (
                            <div className={styles.sorry_travy}>
                                <p className={styles.sorry_txt}>
                                    Travy tried to find it for you, but no
                                    information was found. Sorry Travy
                                </p>
                            </div>
                        ) : (
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{ __html: txt }}
                            />
                        )}
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.article_sticky_footer}>
                            <p className={styles.article_check}>{notes}</p>
                        </div>
                        <PrimaryButton
                            label='Download'
                            className={styles.footer_download}
                            labelStyle={{ fontSize: 14 }}
                            onClick={convertHtmlToPdf}
                        />
                    </div>
                </>
            )}

            {isLoading ? null : (
                <div className={styles.sticky_footer}>
                    <div className={styles.article_sticky_footer}>
                        <p className={styles.article_check}>{notes}</p>
                    </div>
                </div>
            )}
        </Drawer>
    )
}
