import USAflag from '@/assets/images/USA.svg'
import DEflag from '@/assets/images/Germany.svg'
import FRflag from '@/assets/images/🇫🇷  France.svg'
import ESflag from '@/assets/images/Spain.svg'
import { Languages } from '@/core/typing'

export interface ILanguagesOption {
    label: string
    value: string
    flagPath: string
}
export const languagesOptions: ILanguagesOption[] = [
    {
        label: 'English',
        value: Languages.EN,
        flagPath: USAflag,
    },
    {
        label: 'Español',
        value: Languages.ES,
        flagPath: ESflag,
    },
    {
        label: 'Français',
        value: Languages.FR,
        flagPath: FRflag,
    },
    {
        label: 'Deutsch',
        value: Languages.DE,
        flagPath: DEflag,
    },
]

export const languageConfig = {
    [Languages.EN]: {
        label: 'English',
        value: Languages.EN,
        flagPath: USAflag,
    },
    [Languages.ES]: {
        label: 'Español',
        value: Languages.ES,
        flagPath: ESflag,
    },
    [Languages.DE]: {
        label: 'Deutsch',
        value: Languages.DE,
        flagPath: DEflag,
    },
    [Languages.FR]: {
        label: 'Français',
        value: Languages.FR,
        flagPath: FRflag,
    },
}
