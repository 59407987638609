import { useEffect, useState } from 'react'
import { IMessageHistoryItem } from '../typing/interface'
import { usePaginationList } from '@/core/hooks'
import { getHistoryChatBotReq, sendMessageChatBotReq } from '../api'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

export interface IMessage {
    content: string
    isMy: boolean
}

export const useChatMessages = () => {
    const [isMyMessage, setIsMy] = useState<boolean>()
    const [answer, setAnswer] = useState('')
    const [answerLoading, setAnswerLoading] = useState(false)
    const [messages, setMessages] = useState<IMessage[]>([])
    const [page, setPage] = useState(1)

    const list = usePaginationList({
        fetchItems: getHistoryChatBotReq,
        needInit: true,
        clearWhenReload: false,
        limit: 9999999,
        loadParams: {
            sort: 'ASC',
            sortField: 'createdAt',
        },

        serrializatorItems: (items: IMessageHistoryItem[]) => {
            return items.map(it => ({
                content: it.content,
                isMy: it.authorRole === 'u',
            }))
        },
    })

    const createMessage = async (val: IMessage) => {
        setAnswerLoading(true)
        try {
            setMessages([...messages, { content: val.content, isMy: val.isMy }])
            const { data } = await sendMessageChatBotReq({
                question: val.content,
            })
            setIsMy(true)

            if (data) {
                setAnswer(data?.answer)
            }
        } catch (error: any) {
            Sentry.captureException(error)
        } finally {
            setAnswerLoading(false)
        }
    }

    useEffect(() => {
        if (_.isEmpty(messages)) setMessages(list.items)
    }, [messages, list?.items])

    useEffect(() => {
        if (answer) {
            setMessages([...messages, { content: answer, isMy: false }])
        }
    }, [answer])

    return {
        messages: messages,
        createMessage,
        isBotMessage: isMyMessage,
        answerLoading,
        page,
        onChangePage: (val: number) => setPage(val),
    }
}
