import { PrimaryButton, RadioButton } from '@/core/components'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { LayoutSettings } from '@/modules/notifications/components'
import _ from 'lodash'
import * as Sentry from '@sentry/react'
import { getChatSettingsReq, sendChatHistoryReq } from '../../api'
import { MobileTabFooterAtom } from '@/modules/user/components/mobile-account-tabs/atoms'
import { useTranslation } from 'react-i18next'
import { BlurSubscription } from '@/modules/subscribe/components'
import { useResizeWindow } from '@/core/hooks'

export const ChatBotSettingsWidget = () => {
    const { t } = useTranslation()
    const [isEdit, setIsEdit] = useState(false)
    const [allowed, setAllowed] = useState(true)
    const { width: windowWidth } = useResizeWindow()

    useEffect(() => {
        getChatsSettinds()
    }, [])

    const resetForm = () => {
        getChatsSettinds()
        setIsEdit(false)
    }

    const onToggleEdit = (val: boolean) => {
        setIsEdit(val)
    }

    const getChatsSettinds = async () => {
        try {
            const { data } = await getChatSettingsReq()
            setAllowed(data ? data?.sendHistory : true)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const updateSettings = async () => {
        try {
            await sendChatHistoryReq({ sendHistory: allowed })
            setIsEdit(false)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    return (
        <div className={styles.container} id='chatbot-settings'>
            {windowWidth < 850 ? null : <BlurSubscription />}
            <div className={styles.header_form}>
                <p className={styles.label_form}>{t('chatBotSettings')}</p>

                <div className={styles.btn_group}>
                    <PrimaryButton
                        label={t('cancel')}
                        onClick={resetForm}
                        id='chatbot-cancel-button'
                        className={`${styles.btn_cancel} ${!isEdit ? styles.show : styles.hide}`}
                    />

                    <PrimaryButton
                        label={isEdit ? t('save') : t('edit')}
                        onClick={() =>
                            !isEdit ? onToggleEdit(true) : updateSettings()
                        }
                        id='chatbot-save-or-edit-button'
                        className={styles.btn_save}
                    />
                </div>
            </div>
            <div className={styles.settings_box}>
                <LayoutSettings title={t('chatHistoryToEmail')}>
                    <div className={styles.setting_choice}>
                        <button
                            disabled={!isEdit}
                            style={{
                                cursor: !isEdit ? 'not-allowed' : 'pointer',
                            }}
                            className={styles.row_radio}
                            onClick={() => setAllowed(true)}>
                            <RadioButton
                                color={allowed ? '#F8B405' : ''}
                                isActive={allowed}
                                disabled={!isEdit}
                            />
                            <p>{t('allow')}</p>
                        </button>

                        <button
                            style={{
                                cursor: !isEdit ? 'not-allowed' : 'pointer',
                            }}
                            disabled={!isEdit}
                            className={styles.row_radio}
                            onClick={() => setAllowed(false)}>
                            <RadioButton
                                disabled={!isEdit}
                                color={!allowed ? '#F8B405' : ''}
                                isActive={!allowed}
                            />
                            <p>{t('deny')}</p>
                        </button>
                    </div>
                </LayoutSettings>
            </div>

            <div className={styles.footer}>
                <MobileTabFooterAtom
                    cancel={resetForm}
                    onChangeEdit={onToggleEdit}
                    isEdit={isEdit}
                    save={updateSettings}
                />
            </div>
        </div>
    )
}
