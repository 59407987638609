import logo from '@/assets/images/logo.svg'
import styles from './styles.module.css'
import { useCallback, useEffect } from 'react'
import { appEvents } from '@/core/events'
import {
    PaidElements,
    SubscriptionStatus,
    SubscriptionType,
} from '@/modules/subscribe/typing/enums'
import { useAccountStore } from '@/store'

export const GuidoBot = () => {
    const { account } = useAccountStore()

    const type = account?.subscription?.type
    const status = account?.subscription?.status
    const isBasic =
        type == SubscriptionType.Basic || type == SubscriptionType.Basic_3m
    const openPopUpSubscribe = () => {
        appEvents.emit('paidElements', { type: PaidElements.Maskot })
    }

    const openGuidoBot = useCallback(() => {
        const eventBot =
            status === SubscriptionStatus.Trial || isBasic ? 'faqs' : 'guidoBot'
        appEvents.emit(eventBot, { isShow: true })
    }, [])

    useEffect(() => {
        if (status === SubscriptionStatus.Trial) {
            const interval = setInterval(
                () => openPopUpSubscribe(),
                5 * 60 * 1000,
            )
            return () => clearInterval(interval)
        }
    }, [status])

    return (
        <div className={styles.container} onClick={openGuidoBot} id={'travy'}>
            <img src={logo} className={styles.guido_logo} />
        </div>
    )
}
