import { presenceCost, validate, validateEmailRule } from '@/core/tools'
import moment from 'moment'
const constraints = {
    name: { presence: presenceCost },
    email: { presence: presenceCost, email: validateEmailRule },
    birthday: {
        presence: presenceCost,
        datetime: {
            dateOnly: true,
            latest: moment.utc().subtract(18, 'years').toDate(),
            message: '^You need to be at least 18 years old',
        },
    },
}

export const validateAccount = (data: any) => {
    return validate<any>(data, constraints)
}
