import { create } from 'zustand'

interface StoreChatQuestionsStepState {
    step: number
    setStep: (val: number) => void
    resetSteps: () => void
}

export const useChatQuestionsStepStore = create<StoreChatQuestionsStepState>(
    set => ({
        step: 0,
        setStep: data => set({ step: data }),
        resetSteps: () => set({ step: null }),
    }),
)
