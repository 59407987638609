import moment from 'moment'
import { INotification } from '../../typing'
import styles from './styles.module.css'
import { CountriesFor } from '../contries-for'

interface IProps {
    item: INotification
    isFull: boolean
    isLast: boolean
    isRead?: boolean
}
export const NotificationItem = ({ item, isFull, isLast, isRead }: IProps) => {
    return (
        <div
            className={`${styles.container} ${isLast ? styles.last_notification : styles.notification_border}`}>
            <div className={styles.header}>
                <div>
                    <p
                        style={{ opacity: isRead ? 0.5 : 1 }}
                        className={styles.title}>
                        {item.title}
                    </p>
                    <CountriesFor listCode={item?.countries} />
                </div>
                <p className={styles.date}>
                    {moment(item.createdAt).format('DD.MM.YY')}
                </p>
            </div>
            <p
                style={{ opacity: isRead ? 0.5 : 1, marginBottom: 5 }}
                className={`${styles.content} ${isFull ? styles.full : styles.short}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
            />
        </div>
    )
}
