import styles from './styles.module.css'

export const RisklineNotes = () => {
    return (
        <div className={styles.container}>
            <p className={styles.txt}>
                Data provided by{' '}
                <a className={styles.link} href='https://riskline.com/'>
                    Riskline.com
                </a>
            </p>
        </div>
    )
}
