import { IntroStep } from '@/core/components'
import { stepsIntro } from '@/core/components/intro-step/step.config'
import { appEvents } from '@/core/events'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useState } from 'react'
import Joyride, { STATUS } from 'react-joyride'

export const IntroStepsWidgets = () => {
    const [run, setRun] = useState(false)
    const [isOnlyIntro, setOnlyIntro] = useState(false)
    const { width: widthWindow } = useResizeWindow()

    useEventsListener('intro', data => {
        setOnlyIntro(data?.onlyIntro)

        if (widthWindow < 768) {
            setRun(false)
        }
        setRun(data.isShow)
    })

    const handleJoyrideCallback = (data: any) => {
        const { status } = data

        if ([STATUS.FINISHED, STATUS.READY].includes(status)) {
            setRun(false)
            appEvents.emit('intro', { isShow: false })

            isOnlyIntro
                ? null
                : appEvents.emit('settingsNotifications', { isShow: true })
        }
    }

    const introStepTablet = stepsIntro.map(it => {
        if (it.target === '#legend') {
            return { ...it, target: '#tablet-legend' }
        }
        return it
    })
    if (!run || widthWindow < 768) return null

    return (
        <Joyride
            steps={widthWindow > 1040 ? stepsIntro : introStepTablet}
            continuous={true}
            showProgress={false}
            showSkipButton={false}
            disableOverlayClose
            callback={handleJoyrideCallback}
            run={run}
            disableScrolling
            disableScrollParentFix
            tooltipComponent={IntroStep}
            spotlightClicks={false}
            spotlightPadding={3}
            styles={{
                options: {
                    arrowColor: 'transparent',
                    zIndex: 999,
                },
            }}
        />
    )
}
