export enum SubscriptionType {
    Basic = 'b',
    Premium = 'p',
    Basic_3m = 'q',
    Premium_3m = 'v',
}

export enum SubscriptionStatus {
    Active = 'a',
    Trial = 't',
    Paused = 'p',
    Canceled = 'c',
    Unpaid = 'u',
    NonRenewing = 'n',
}

export enum PaidElements {
    Notification = 'notification',
    EntryReq = 'entry-requirements',
    Maskot = 'maskot',
}
