import { useRef, useEffect, useState } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer'
import { Raster as RasterSource, XYZ } from 'ol/source'
import { fromLonLat } from 'ol/proj'
import { ZoomControlAtom } from './atoms'
import { useEventsListener } from '@/core/hooks'
import './styles.css'
import { useCategoryRiskStore } from '@/store'
import { SubcategoryRisks } from '@/core/typing'

const key = import.meta.env.VITE_MAPTILER_API_KEY

const flood = (pixels: any, data: any) => {
    const pixel = pixels[0]
    if (pixel[3]) {
        const height =
            -10000 + (pixel[0] * 256 * 256 + pixel[1] * 256 + pixel[2]) * 0.1
        if (height <= data.level) {
            pixel[0] = 134
            pixel[1] = 203
            pixel[2] = 249
            pixel[3] = 255
        } else {
            pixel[3] = 0
        }
    }
    return pixel
}

export const SeaLevelMapWidget = () => {
    const mapRef = useRef()
    const { active } = useCategoryRiskStore()
    const rasterRef = useRef<RasterSource>()
    const [level, setLevel] = useState(-30)
    const [map, setMap] = useState(null)
    useEffect(() => {
        if (active?.subcategory !== SubcategoryRisks.SeaLevelRise) {
            setLevel(-20)
        }
    }, [active])

    useEventsListener(
        'seaLevel',
        data => {
            setLevel(data?.level)
        },
        [level, active],
    )
    useEffect(() => {
        const elevation = new XYZ({
            url: `https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=${key}`,
            tileSize: 512,
            maxZoom: 14,
            crossOrigin: '',
            interpolate: false,
        })

        const raster = new RasterSource({
            sources: [elevation],
            operation: flood,
        })
        rasterRef.current = raster
        const initMap = new Map({
            target: mapRef.current,
            controls: [],
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: `https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${key}`,
                        maxZoom: 910,
                        transition: 400,
                    }),
                }),
                new ImageLayer({
                    opacity: 0.6,
                    source: raster,
                }),
            ],
            view: new View({
                center: fromLonLat([50, 25]),
                zoom: 3,
                maxZoom: 7,
                minZoom: 3,
            }),
        })
        setMap(initMap)
        return () => initMap.setTarget(null)
    }, [])

    useEffect(() => {
        if (rasterRef.current) {
            rasterRef.current.changed()
            rasterRef.current.on('beforeoperations', event => {
                event.data.level = level
            })
        }
    }, [level, mapRef])

    const zoomIn = () => {
        const view = map.getView()
        view.animate({
            zoom: view.getZoom() + 1,
            duration: 800,
        })
    }

    const zoomOut = () => {
        const view = map.getView()
        view.animate({
            zoom: view.getZoom() - 1,
            duration: 800,
        })
    }

    return (
        <div>
            <div ref={mapRef} className='container_sea_level'></div>

            <ZoomControlAtom zoomIn={zoomIn} zoomOut={zoomOut} />
        </div>
    )
}
