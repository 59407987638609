import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import styles from './styles.module.css'
import { subscribeLabel } from '@/modules/subscribe/configs/advantes.config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IProps {
    type: SubscriptionType
    price: number
    date: string
}
export const HistorySubscribeItemAtom = ({ type, date }: IProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.item_row}>
                <p className={styles.label}>Type</p>
                <p className={styles.value}>{subscribeLabel[type]}</p>
            </div>

            {/* <div className={styles.item_row}>
                <p className={styles.label}>Summ</p>
                <p className={styles.value}>€{price.toFixed(2)}</p>
            </div> */}

            <div className={styles.item_row}>
                <p className={styles.label}>Date</p>
                <p className={styles.value}>
                    {dayjs.utc(date).format('DD.MM.YYYY')}
                </p>
            </div>
        </div>
    )
}
