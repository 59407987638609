import { Root } from './modules/root'
import { I18nextProvider } from 'react-i18next'

import i18n from '@/i18n'
import { useEffect } from 'react'
import { useSubscribeMessage } from './core/hooks/use-subscribe-message.hook'
import { storageService } from './core/services'
import { Languages } from './core/typing'
import { useLanguageStore } from './store'

function App() {
    useSubscribeMessage()

    const { lang, setLang } = useLanguageStore()

    const getLanguage = () => {
        const language = storageService.get('lang')
        if (!language) {
            storageService.set('lang', Languages.EN)
        } else {
            setLang(language as Languages)
        }
    }

    useEffect(() => {
        getLanguage()
    }, [lang])

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang])
    return (
        <I18nextProvider i18n={i18n}>
            <Root />
        </I18nextProvider>
    )
}

export default App
