import styles from './styles.module.css'
import plusIcon from '@/assets/images/plus.svg'
import minusIcon from '@/assets/images/minus.svg'

interface IProps {
    zoomIn: () => void
    zoomOut: () => void
}
export const ZoomControlAtom = ({ zoomOut, zoomIn }: IProps) => {
    return (
        <div className={styles.container_controls}>
            <div onClick={zoomIn} className={styles.btn_zoom}>
                <img src={plusIcon} className={styles.icon_zoom} />
            </div>
            <div onClick={zoomOut} className={styles.btn_zoom}>
                <img src={minusIcon} className={styles.icon_zoom} />
            </div>
        </div>
    )
}
