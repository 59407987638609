import { useResizeWindow } from '@/core/hooks'
import { columns } from './columns'
import './styles.css'
import { Table } from 'antd'
import { useLanguageStore } from '@/store'
import { Languages } from '@/core/typing'

interface IProps {
    data: any
}
export const WeatherTable = ({ data }: IProps) => {
    const { width: windowWidth } = useResizeWindow()
    const { lang } = useLanguageStore()

    const mobileWidth = lang === Languages.DE ? 275 : 195
    return (
        <Table
            columns={columns(windowWidth < 767 ? mobileWidth : 270)}
            dataSource={data}
            bordered
            scroll={{ y: 411 }}
            pagination={false}
            className={'table_container'}
        />
    )
}
