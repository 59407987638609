import { CardFaq, FrequentlyAsked } from '../../components'
import handPicture from '@/assets/images/hand.svg'
import noteAbout from '@/assets/images/note-about.svg'
import penPicture from '@/assets/images/pen.svg'
import styles from './styles.module.css'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useState } from 'react'
import { Drawer } from 'antd'
import { HeaderChat } from '@/modules/chat-bot/components'
import _ from 'lodash'
import { appEvents } from '@/core/events'

export const FAQsWidget = () => {
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [showAsked, setShowAsked] = useState(false)

    const closeDrawer = () => {
        setOpen(false)
        setShowAsked(false)
    }

    useEventsListener('faqs', (data: any) => {
        setOpen(data.isShow)
    })

    const goTariffsPlan = () => {
        setOpen(false)
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }
    const startIntro = () => {
        setOpen(false)
        appEvents.emit('intro', { isShow: true, onlyIntro: true })
    }
    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '736px'
        }
    }

    const isMobile = widthWindow < 768

    const subTitleContent = isMobile ? (
        <p className={styles.subTitle}>
            To use the AI chatbot, a full subscription <br />
            is required. In the meantime, сhoose
            <br /> what you want to do next
        </p>
    ) : (
        <p className={styles.subTitle}>
            To use the AI chatbot, a full subscription is required.
            <br /> In the meantime, сhoose what you want to do next.
        </p>
    )
    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            styles={{
                body: {
                    padding: 0,
                    overflow: 'hidden',
                },
                wrapper: { width: drawerWidth() },
            }}
            onClose={closeDrawer}>
            <HeaderChat closeChat={closeDrawer} />
            <div className={styles.container}>
                {showAsked ? (
                    <FrequentlyAsked />
                ) : (
                    <>
                        {' '}
                        <p className={styles.title}>Hello! I’m Travy</p>
                        {subTitleContent}
                        <div className={styles.cardContainer}>
                            <CardFaq
                                cardPicture={penPicture}
                                labelCard='Frequently asked questions'
                                labelBtn='Continue'
                                onClickCard={() => setShowAsked(true)}
                            />

                            <CardFaq
                                cardPicture={noteAbout}
                                labelCard='About web platform'
                                labelBtn='Continue'
                                onClickCard={startIntro}
                            />

                            <CardFaq
                                labelCard='Full subscription'
                                style={{
                                    gridColumn: isMobile ? 'auto' : 'span 2',
                                }}
                                cardPicture={handPicture}
                                labelBtn='Buy now'
                                onClickCard={goTariffsPlan}
                            />
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    )
}
