import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useState } from 'react'

interface IProps {
    idCard: string
    question: string
    answer: string
}
export const FaqQuestion = ({ question, answer }: IProps) => {
    const [active, setActive] = useState(false)

    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                <p className={styles.label}>{question}</p>
                <Icon
                    name={active ? 'minus-bold' : 'plus-bold'}
                    size={24}
                    color='#97999A'
                    onClick={() => setActive(!active)}
                />
            </div>

            <div
                className={`${styles.answer}`}
                style={{ display: active ? 'flex' : 'none' }}>
                <p className={styles.answer_txt}>{answer}</p>
            </div>
        </div>
    )
}
