import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { CheckAnswerAtom } from './atoms/check-answer.atom'
import { ActionFooter, QuestionsPagination } from '../../components'
import { IQuestion } from '@/modules/chat-bot/typing/interface'
import _ from 'lodash'
import { Error } from '@/core/components'
import { createStyleSheet } from '@/core/helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
    countApprove: number
    stepNumber: number
    question: string
    answers: string[]
    onChangeStep: (val: number) => void
    onSetAnswer: (val: IQuestion) => void
    close: () => void
    approve: IQuestion
}

export const MultiAnwerStep = ({
    question,
    answers,
    stepNumber,
    onChangeStep,
    onSetAnswer,
    close,
    countApprove,
    approve,
}: IProps) => {
    const { t } = useTranslation()
    const [answer, setAnswer] = useState<string[]>([])
    const isNotLogic = answer.length > 1 && answer.includes('No')
    useEffect(() => {
        setAnswer(approve?.answer ? approve?.answer : [])
    }, [approve, stepNumber])

    const isChecked = (key: string) => answer.includes(key)

    const onChangeAnswer = (val: string) => {
        if (answer.includes(val)) {
            setAnswer(prev => prev.filter(it => it !== val))
        } else {
            setAnswer([...answer, val])
        }
    }
    const handleApproveAnswer = () => {
        if (isNotLogic) return
        onSetAnswer({ question, answer, id: stepNumber })
        onChangeStep(stepNumber + 1)
    }

    return (
        <div className={styles.container}>
            <QuestionsPagination
                disabledNext={countApprove == stepNumber}
                current={stepNumber}
                prev={() => onChangeStep(stepNumber - 1)}
                next={() => onChangeStep(stepNumber + 1)}
                length={6}
            />

            <p className={styles.title}>{t(`${question}`)}</p>

            <div className={styles.answers_container}>
                {answers?.map(it => (
                    <CheckAnswerAtom
                        answer={it}
                        onChange={onChangeAnswer}
                        isChecked={isChecked(it)}
                    />
                ))}
                {isNotLogic ? (
                    <Error style={styl.error}>
                        Your answers contradict each other
                    </Error>
                ) : null}
            </div>

            <ActionFooter
                disabledNext={!answer.length}
                next={handleApproveAnswer}
                exit={close}
            />
        </div>
    )
}

const styl = createStyleSheet({
    error: {
        position: 'absolute',
        bottom: -25,
        fontSize: 12,
        fontWeight: '500',
    },
})
