import { Dropdown, Menu } from 'antd'
import { CSSProperties, PropsWithChildren, useEffect, useRef } from 'react'
import { NotificationItem } from '../notification-item'
import './styles.css'
import { Icon } from '@/core/components'
import { colorRiskSubCategory, iconSategoryConfig } from '../../configs'
import { createStyleSheet } from '@/core/helpers'
import { INotification } from '../../typing'
import { useTranslation } from 'react-i18next'

interface IProps {
    onClickItem: (item?: INotification) => void
    style?: CSSProperties
    notifications: INotification[]
    open: boolean
    setOpen: (val: boolean) => void
}
export const NotificationsShortList = ({
    children,
    notifications,
    onClickItem,
    open,
    setOpen,
}: PropsWithChildren<IProps>) => {
    const { t } = useTranslation()
    const menuRef = useRef(null)

    const scrollToTop = () => {
        if (menuRef.current) {
            menuRef.current?.menu?.list?.scrollTo({ top: 0 })
        }
    }

    useEffect(() => {
        if (open && menuRef.current) scrollToTop()
    }, [open, menuRef.current])

    const items = notifications.map((notification: INotification, index) => (
        <Menu.Item
            className='menu-item'
            onClick={() => {
                setOpen(false)
                onClickItem(notification)
            }}
            key={notification.id}>
            <div className='notification'>
                <div style={styles.box}>
                    {notification.isRead ? null : (
                        <div className={'mark-unread'} />
                    )}
                    <Icon
                        style={{
                            ...styles.iconContainer,
                            backgroundColor:
                                colorRiskSubCategory[notification.type],
                        }}
                        name={iconSategoryConfig[notification.type]}
                        size={18}
                        color='#FFFFFF'
                    />
                </div>
                <NotificationItem
                    isRead={notification.isRead}
                    isLast={index + 1 === notifications.length}
                    item={notification}
                    isFull={false}
                />
            </div>
        </Menu.Item>
    ))

    const menu = (
        <div style={{ position: 'relative' }}>
            <Menu ref={menuRef} className='menu-container'>
                {items}
            </Menu>
            <div
                onClick={() => {
                    setOpen(false)
                    onClickItem()
                }}
                key='viewAll'
                className='footer-list'>
                <p>{t('viewAll')}</p>
            </div>
        </div>
    )
    return (
        <Dropdown
            onOpenChange={setOpen}
            open={open}
            trigger={['click']}
            overlay={menu}
            placement='bottomRight'
            arrow>
            {children}
        </Dropdown>
    )
}

const styles = createStyleSheet({
    iconContainer: {
        height: 30,
        width: 30,
        borderRadius: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    box: {
        position: 'relative',
    },
})
