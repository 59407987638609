import { Icon } from '@/core/components'
import { windDirecrionConfig } from '@/modules/weather/configs/wind-direction.config'

interface IProps {
    direction: string
}
export const WindDirAtom = ({ direction }: IProps) => {
    return (
        <Icon
            style={{
                transform: `rotate(${windDirecrionConfig[direction]}deg)`,
            }}
            name='navigation-arrow'
            size={16}
            color='#0F0E0E'
        />
    )
}
