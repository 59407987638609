import { create } from 'zustand'

interface ICity {
    name: string
    placeId: string
    lat?: number
    lng?: number
    shortCode?: any
}

interface SearchCountryState {
    city: ICity
    setCity: (data: ICity) => void
    clearCity: () => void
}

export const useSearchCityStore = create<SearchCountryState>(set => ({
    city: null,
    setCity: data => set({ city: data }),
    clearCity: () => set({ city: null }),
}))
