import { http } from '@/core/services'
import { ISelectSubscribeResp, ISubscribePayload } from './interface'

export const saveSubscribeReq = (data: ISubscribePayload) => {
    return http.post<ISelectSubscribeResp>('subscriptions/select', data)
}

export const cancelSubsriptionReq = () => {
    return http.post('subscriptions/cancel', {})
}

export const getSubscribeHistoryReq = () => {
    return http.get<any>('subscriptions/history')
}
