import illustarion from '@/assets/images/fluora_illustration.svg'
import styles from './styles.module.css'
import { VariantAtom } from './atoms/variant.atom'
import note from '@/assets/images/note.svg'
import pencil from '@/assets/images/pencil.svg'
import { useTranslation } from 'react-i18next'

interface IProps {
    close: () => void
    onChangeStep: () => void
}
export const GreetingStep = ({ close, onChangeStep }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title_container}>
                    <p className={styles.title}>{t('titleTravy')}</p>
                    <p className={styles.sub_title}>{t('chooseVariant')}</p>
                </div>
                <div className={styles.row_variants}>
                    <VariantAtom
                        onPress={onChangeStep}
                        imgPath={note}
                        label={t('personalisedAnswer')}
                    />

                    <VariantAtom
                        onPress={close}
                        imgPath={pencil}
                        label={
                            <p style={{ fontSize: 16 }}>{t('yourQuestions')}</p>
                        }
                    />
                </div>
            </div>
            <img src={illustarion} className={styles.illustration} />
        </div>
    )
}
