import { http } from '@/core/services'
import { IGetRisksParams, IRisksResponse } from './interfaces'

export const getRisksReq = (params: IGetRisksParams) => {
    return http.get<IRisksResponse>('risks', { params })
}

export const getOwerallRisksReq = () => {
    return http.get<IRisksResponse>('risks/overall')
}
