import { createStyleSheet } from '@/core/helpers'
import { Spin } from 'antd'
import { Content } from 'antd/es/layout/layout'

export const Loader = () => {
    return (
        <Content style={styles.container}>
            <Spin size='large' />
        </Content>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
})
