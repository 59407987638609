import { useEffect, useMemo } from 'react'
import './styles.css'
import { Menu } from '../../menu'
import { menuLabels, risksConfig } from '@/config/risks.config'
import { useRisksCategory } from '@/core/hooks'
import { CategoryRisks, Languages, SubcategoryRisks } from '@/core/typing'
import logo from '@/assets/images/logo.svg'
import labelLogo from '@/assets/images/label-travy.svg'
import { Icon } from '../../icons'
import _ from 'lodash'
import { SearchCity, SearchCountry } from '@/core/widgets'
import { useAccount } from '@/modules/user/hooks'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/store'
import { appEvents } from '@/core/events'
import { AboutUsBtn } from '@/modules/root/components'
import {
    PaidElements,
    SubscriptionStatus,
} from '@/modules/subscribe/typing/enums'
import { PrimaryButton } from '../../buttons'

interface IProps {
    isOpen: boolean
    toggleBar: (val: boolean) => void
}
export const LeftSideBar = ({ isOpen, toggleBar }: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { account } = useAccount()
    const { active, getActiveCategory, setCategoryRisks } = useRisksCategory()

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    useEffect(() => {
        getActiveCategory()
    }, [])

    const openPopUpSubscribe = (typeElemment: PaidElements) => {
        appEvents.emit('paidElements', { type: typeElemment })
    }

    const onClickRisk = (val: { category: string; subcategory?: string }) => {
        toggleBar(true)

        if (isTrial && val.category === CategoryRisks.Requirements) {
            openPopUpSubscribe(PaidElements.EntryReq)
            setCategoryRisks({
                category: CategoryRisks.Owerview,
                subcategory: null,
            })
        } else {
            appEvents.emit('paidElements', { type: null })
            setCategoryRisks(
                val as {
                    category: CategoryRisks
                    subcategory: SubcategoryRisks
                },
            )
        }
    }

    const getLabel = () => {
        if (!active) {
            return menuLabels[CategoryRisks.Owerview]
        } else if (active?.category && active?.subcategory) {
            return menuLabels[active?.subcategory]
        } else {
            return menuLabels[active?.category]
        }
    }

    const openContactUs = () => {
        appEvents.emit('contactUs', { isShow: true })
        appEvents.emit('paidElements', { type: null })
    }

    const searchCountry = useMemo(() => {
        if (
            !account ||
            (account && !account?.subscription) ||
            active?.category === CategoryRisks.Requirements ||
            active?.subcategory === SubcategoryRisks.SeaLevelRise ||
            !isOpen
        ) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.Weather) {
            return (
                <div className={'search_country_full'}>
                    <SearchCity />
                </div>
            )
        }
        return (
            <div className={'search_country_full'}>
                <SearchCountry />
            </div>
        )
    }, [active, isOpen, account])

    const fullSubscribeBtn = useMemo(() => {
        if (
            !account ||
            account?.subscription?.status !== SubscriptionStatus.Trial ||
            !isOpen
        )
            return null
        return (
            <PrimaryButton
                onClick={() => {
                    appEvents.emit('subsribe', {
                        isShow: true,
                        method: 'buy',
                        isUpdate: true,
                    })
                    appEvents.emit('paidElements', { type: null })
                }}
                style={{ width: 205, marginLeft: 28 }}
                label='Full subscription'
            />
        )
    }, [account, isOpen])

    return (
        <div className={`left-sidebar ${isOpen ? 'open' : ''}`}>
            <div className='header_container'>
                <Icon
                    className='sidebar_toggle'
                    onClick={() => toggleBar(!isOpen)}
                    name={isOpen ? 'left-arrow' : 'right-arrow'}
                    size={24}
                    color='#717375'
                />
                <p
                    className={`category_label ${isOpen ? 'hidden_category' : ''}`}>
                    {t(`${getLabel()}`)}
                </p>
            </div>
            {searchCountry}
            <div className='sidebar-content'>
                <div>
                    <div
                        className='logo_container'
                        onClick={() =>
                            onClickRisk({
                                category: CategoryRisks.Owerview,
                                subcategory: null,
                            })
                        }>
                        <img src={logo} className='logo_guido' />
                        <img
                            src={labelLogo}
                            className={
                                isOpen ? 'logo_label' : 'hide_logo_label'
                            }
                        />
                    </div>

                    <div>
                        <>
                            {risksConfig.map((it, index) => (
                                <Menu
                                    id={`category-${index}`}
                                    indexMenu={index}
                                    activeCategory={active}
                                    onClickMenu={onClickRisk}
                                    key={it.key}
                                    item={it}
                                    close={_.noop}
                                />
                            ))}
                        </>
                        <>{fullSubscribeBtn}</>
                    </div>
                </div>

                <div className='rights_container'>
                    <div className={'contact_us'} onClick={openContactUs}>
                        <AboutUsBtn isOpen={isOpen} />
                    </div>

                    <p
                        style={{
                            textWrap: lang !== Languages.EN ? 'wrap' : 'nowrap',
                        }}
                        className={!isOpen ? 'collapsed' : 'rights show'}>
                        © 2024 SafeTravy. {t('rightsReserved')}.
                    </p>

                    <p className={isOpen ? 'collapsed' : 'rights show'}>
                        ©SafeTravy
                    </p>
                </div>
            </div>
        </div>
    )
}
