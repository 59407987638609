import styles from './styles.module.css'
import { useMemo } from 'react'
import { GuidoAvatarAtom, UserAvatarAtom } from './atoms'
import { useAccountStore } from '@/store'

interface IProps {
    isUser: boolean
    content: string
    isNewBotMessage?: boolean
    isLast: boolean
}
export const MessageChatAtom = ({ isUser, content }: IProps) => {
    const { account } = useAccountStore()
    const avatar = useMemo(() => {
        if (isUser) {
            return <UserAvatarAtom avatarUrl={account?.avatarUrl} />
        }
        return <GuidoAvatarAtom />
    }, [isUser])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <>{avatar}</>
                <p className={styles.label}>{isUser ? 'You' : 'Travy'}</p>
            </div>
            <p className={styles.content}>{content}</p>
        </div>
    )
}
