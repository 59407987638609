import checkIcon from '@/assets/images/check.svg'
import styles from '../styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    list: string[]
}

export const AdvantesAtom = ({ list }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.advantages}>
            {list.map(it => (
                <div key={it} className={styles.advantages_item}>
                    <div className={styles.advantages_item_indicator}>
                        <img
                            src={checkIcon}
                            style={{ height: 16, width: 16 }}
                        />
                    </div>
                    <p className={styles.advantages_item_label}>{t(it)}</p>
                </div>
            ))}
        </div>
    )
}
