import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import defaultUser from '@/assets/images/default-user.webp'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { IFile } from '@/core/typing'
import editIcon from '@/assets/images/edit.svg'
import styles from './styles.module.css'

interface IProps {
    avatarUrl: string

    onChangeFile: (file: IFile) => void
    clear: () => void
    value: IFile
    disabled?: boolean
    style?: React.CSSProperties
}
interface IObjectFile {
    [index: number]: File
    length: number
}
export const UploadPhoto = ({
    onChangeFile,
    style,
    clear,
    disabled,
    avatarUrl,
    value,
}: IProps) => {
    const [hovered, setHovered] = useState(false)
    const [urlPhoto, setUrlPhoto] = useState('')

    useEffect(() => {
        if (avatarUrl && !value) {
            setUrlPhoto(avatarUrl)
        }
    }, [avatarUrl, value])

    const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG']
    const handleChange = (file: IObjectFile) => {
        const url = file && URL.createObjectURL(file[0])
        onChangeFile(file[0])
        setUrlPhoto(url)
    }

    const handlerCrear = () => {
        setUrlPhoto(null)
        clear()
    }

    const handleMouseEnter = () => {
        setHovered(true)
    }

    const handleMouseLeave = () => {
        setHovered(false)
    }
    return (
        <div
            style={{ position: 'relative', opacity: disabled ? 0.7 : 1 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {hovered && urlPhoto && !disabled ? (
                <Button
                    disabled={disabled}
                    onClick={handlerCrear}
                    type='text'
                    className={styles.cross}
                    icon={
                        <CloseCircleOutlined
                            style={{ color: 'red', fontSize: 15 }}
                        />
                    }
                />
            ) : null}
            <FileUploader
                disabled={disabled}
                multiple={true}
                handleChange={handleChange}
                name='file'
                types={fileTypes}>
                <div
                    style={{
                        ...style,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                    }}>
                    <img
                        className={styles.def_user}
                        src={urlPhoto || defaultUser}
                        alt='user photo'
                    />
                    <div className={styles.edit}>
                        <img src={editIcon} style={{ height: 24, width: 24 }} />
                    </div>
                </div>
            </FileUploader>
        </div>
    )
}
