import { IQuestion } from '@/modules/chat-bot/typing/interface'
import { t } from 'i18next'

interface IQuestionConfigItem extends IQuestion {
    type: 'once' | 'multi'
}
export const questionsConfig = (): IQuestionConfigItem[] => [
    {
        question: t('purpouse'),
        answer: [t('leisure'), t('business'), t('familyAffairs')],
        type: 'once',
        id: 0,
    },
    {
        question: t('transportationKind'),
        answer: [
            t('landTransportation'),
            t('airTransportation'),
            t('seaTransportation'),
        ],
        type: 'once',
        id: 1,
    },
    {
        question: t('accommodation'),
        answer: [t('hotel'), t('apartament'), t('hostel'), t('holidayHome')],
        type: 'multi',
        id: 2,
    },
    {
        question: t('activities'),
        answer: [
            t('historicalSites'),
            t('parks'),
            t('beach'),
            t('shopping'),
            t('nightlife'),
            t('sports'),
            t('culinary'),
        ],
        type: 'multi',
        id: 3,
    },

    {
        question: t('considerations'),
        answer: [
            t('dietary'),
            t('mobilityAssistance'),
            t('medicalConditions'),
            t('sensoryAccommodations'),
            t('no'),
        ],
        type: 'multi',
        id: 4,
    },
    {
        question: t('specificFears'),
        answer: [
            t('heights'),
            t('fearSpaces'),
            t('flying'),
            t('water'),
            t('animals'),
            t('culturalDifferences'),
            t('no'),
        ],
        type: 'multi',
        id: 5,
    },
]
