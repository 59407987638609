import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'

export const EmptyChat = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <p className={styles.label}>{t('canHeplYou')}</p>
        </div>
    )
}
