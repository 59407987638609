import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useAccountStore } from '@/store'
import { SubscriptionStatus } from '../../typing/enums'
import { appEvents } from '@/core/events'

export const BlurSubscription = () => {
    const { account } = useAccountStore()

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const isSubscribe = account?.subscription

    const buySubscribe = () => {
        appEvents.emit('account', { isShow: false })
        appEvents.emit('subsribe', { isShow: true, method: 'buy' })
    }
    if (isSubscribe && !isTrial) {
        return null
    }
    return (
        <div className={styles.container}>
            <button onClick={buySubscribe} className={styles.btn}>
                <Icon name='lock' size={24} color='#212731' />
                <p className={styles.label}>Full subscription is required </p>
            </button>
        </div>
    )
}
