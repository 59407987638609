import { useEffect, useRef, useState } from 'react'
import { IMessage } from '../../hooks'
import { MessageChatAtom } from '../message-chat'
import styles from './styles.module.css'
import { EmptyChat } from '../empty-chat'
import _ from 'lodash'

interface IProps {
    messages: IMessage[]
    isBotMessage: boolean
    isLoading?: boolean
    onChangePage: (val: number) => void
    page: number
}
export const GuidoChat = ({
    messages,
    isBotMessage,
    onChangePage,
    page,
}: IProps) => {
    const messagesEndRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100,
        )
        setScrollPosition(position)
    }

    useEffect(() => {
        if (scrollPosition === 0) {
            onChangePage(page + 1)
        }
    }, [scrollPosition])

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    if (_.isEmpty(messages)) {
        return (
            <div className={styles.expty_box}>
                <EmptyChat />
            </div>
        )
    }
    return (
        <div className={styles.chat_box}>
            <div />
            <div className={styles.container} onScroll={handleScroll}>
                {messages?.map((it, index) => {
                    return (
                        <MessageChatAtom
                            key={it.content + index}
                            isLast={messages.length === index + 1}
                            isUser={it.isMy}
                            content={it.content}
                            isNewBotMessage={isBotMessage}
                        />
                    )
                })}
                <div ref={messagesEndRef} />
            </div>
        </div>
    )
}
