import guidoHat from '@/assets/images/guido-hat.webp'
import { RegisterUserWidget, SignInWidget } from '../widgets'
import { AuthStep } from '../typing'

export const signInConfig: any = {
    [AuthStep.Register]: {
        leftImg: guidoHat,
        component: (props: any) => <RegisterUserWidget {...props} />,
    },
    [AuthStep.Login]: {
        leftImg: guidoHat,
        component: (props: any) => <SignInWidget {...props} />,
    },
}
