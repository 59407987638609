import styles from './styles.module.css'

interface IProps {
    goBack: () => void
    next: (e: any) => void
    labelBack: string
    labelNext: string
    isFirst: boolean
    isLast: boolean
}
export const GroupButtonsAtom = ({
    goBack,
    next,
    labelBack,
    labelNext,
    isFirst,
    isLast,
}: IProps) => {
    return (
        <div className={styles.container}>
            {isFirst ? null : (
                <button
                    className={isLast ? styles.full_back : styles.back}
                    onClick={goBack}>
                    <p className={styles.label}>{labelBack}</p>
                </button>
            )}
            {isLast ? null : (
                <button
                    className={isFirst ? styles.full_next : styles.next}
                    onClick={next}>
                    <p className={styles.label}>{labelNext}</p>
                </button>
            )}
        </div>
    )
}
