import { http } from '@/core/services'
import { IPaganationParam } from '@/core/typing'
import {
    INotificationsListResp,
    INotificationsSettings,
    IReadNotificationPassed,
    IUserNotificationIdPayload,
} from './interface'

export const getNotificationsReq = (params: IPaganationParam) => {
    return http.get<INotificationsListResp>('notifications', { params })
}

export const getAllUnreadNotificationsReq = () => {
    return http.get<number>('notifications/unread-count')
}

export const markAllNotificationsReadReq = () => {
    return http.post('notifications/read-all', {})
}

export const updateNotificationsSettingsReq = (
    data: INotificationsSettings,
) => {
    return http.put<INotificationsSettings>('notifications-settings', data)
}

export const getUserNotificationsSettingsReq = () => {
    return http.get<INotificationsSettings>('notifications-settings')
}

export const readNotificationByPassesIdReq = (
    data: IReadNotificationPassed,
) => {
    return http.post('/notifications/read-by-ids', data)
}

export const sendUserNotificationIdReq = (data: IUserNotificationIdPayload) => {
    return http.post('notifications/device', data)
}
