import {
    PaidElements,
    SubscriptionStatus,
    SubscriptionType,
} from '../../typing/enums'
import { useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { appEvents } from '@/core/events'
import { useAccountStore } from '@/store'
import { PopUpSubscribtion } from '../../components'

export const PopUpSubscribtionWidget = () => {
    const { account } = useAccountStore()
    const isBasic =
        account?.subscription?.type === SubscriptionType.Basic ||
        account?.subscription?.type === SubscriptionType.Basic_3m

    const notTrial = account?.subscription?.status !== SubscriptionStatus.Trial
    const [element, setElement] = useState<PaidElements>(null)

    useEventsListener('paidElements', data => {
        setElement(data?.type)
    })

    const close = () => {
        setElement(null)
    }

    const goTariffs = () => {
        close()
        appEvents.emit('subsribe', {
            isShow: true,
            method: 'buy',
            isUpdate: true,
        })
    }

    const txtContent =
        notTrial && isBasic
            ? 'Buy Extra subscribe'
            : 'Full subscription is required'
    if (!element) return null
    return (
        <PopUpSubscribtion
            hideElement={close}
            txtContent={txtContent}
            goTariffs={goTariffs}
            element={element}
        />
    )
}
