import { countriesConfig } from '@/core/widgets/country-autocomplete/countries.config'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    listCode: string[]
}
export const CountriesFor = ({ listCode }: IProps) => {
    const { t } = useTranslation()
    const getCountriesByContinent = () => {
        if (!listCode) return
        const countries = countriesConfig
            .filter(it => listCode.includes(it.alpha2))
            .map(el => el.name)

        return countries.join(', ')
    }

    const countries = getCountriesByContinent()

    if (!listCode) return null
    return (
        <div className={styles.countries}>
            <p className={styles.txt_country}>
                {t('forCountries')}: <span>{countries}</span>
            </p>
        </div>
    )
}
