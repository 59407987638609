import { FormControlCode, PrimaryButton } from '@/core/components'
import { useEffect, useState } from 'react'
import { AuthStep, IStep } from '../../typing'
import { useCountdownTimer } from '@/core/hooks'
import { checkOTPReq, requestOTPReq } from '../../api'
import { getDataFromApiError } from '@/core/helpers'
import { AxiosError } from 'axios'
import { IError } from '@/core/typing'
import { Button } from 'antd'
import { gcService } from '@/core/tools'
import * as Sentry from '@sentry/react'

import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

const initialCount = 60
interface IProps {
    changeStep: (val: IStep) => void
    step?: IStep
}
export const SendCodeForm = ({ changeStep, step }: IProps) => {
    const { t } = useTranslation()
    const isActiveKey = step.name === AuthStep.SendCode
    const [code, setCode] = useState('')
    const [timer, setTimer] = useState(null)
    const [error, setError] = useState('')
    const email = gcService.get('email')
    const {
        startCountdown,
        getFormattedTimer,
        isTimeOver,
        stopCountdown,
        restartCountDown,
    } = useCountdownTimer(initialCount)

    useEffect(() => {
        startCountdown()
        setTimer(true)
    }, [isActiveKey])

    useEffect(() => {
        if (isTimeOver()) {
            stopCountdown()
            setTimer(null)
        }
    }, [isTimeOver])

    const reSendCode = async () => {
        try {
            await requestOTPReq(email)
            setTimer(true)
            restartCountDown()
            setCode('')
            setError('')
        } catch (error) {
            const errorData = getDataFromApiError(error as AxiosError) as IError
            setError(errorData?.message)
            Sentry.captureException(error)
        }
    }

    const onChange = (val: string) => {
        setCode(val)
        setError('')
    }

    const submit = async () => {
        try {
            await checkOTPReq({ code, email })
            changeStep({
                name: AuthStep.NewPassword,
                params: { code, email },
            })
        } catch (error) {
            setError('Your code incorrect. Try again!')
            Sentry.captureException(error)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.container_labels}>
                <p className={styles.title}>{t('resetPassword')}</p>

                <p className={styles.subTitle}>
                    {t('verificationCodeMessage')}
                </p>
            </div>
            <div>
                <FormControlCode
                    onChangeVal={onChange}
                    value={code}
                    mb={0}
                    error={error}
                />

                <Button
                    id='resend-code-btn'
                    type='text'
                    onClick={reSendCode}
                    size='large'
                    disabled={timer}
                    className={styles.timer}>
                    <p style={{ fontWeight: '600' }}>
                        {t('sendAgain')}{' '}
                        <span style={{ fontWeight: '400' }}>
                            {getFormattedTimer()}
                        </span>
                    </p>
                </Button>
                <PrimaryButton
                    label={t('continue')}
                    onClick={submit}
                    style={{ width: '100%' }}
                />
            </div>
        </div>
    )
}
