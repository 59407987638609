import { CountriesTagsWidget } from '@/core/widgets'
import { LayoutSettings } from '@/modules/notifications/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    isEdit: boolean
    onChange: (val: string) => void
    subscribeCountries: string[]
    error?: string
}
export const ChooseFallowCountries = ({
    isEdit,
    subscribeCountries,
    onChange,
    error,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <LayoutSettings title={`${t('followCountries')} (max. 6)`}>
            <CountriesTagsWidget
                onChangeList={onChange}
                disabled={isEdit}
                values={subscribeCountries}
                error={error}
            />
        </LayoutSettings>
    )
}
