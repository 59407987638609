import { CSSProperties } from 'react'
import arrowTooltip from '@/assets/images/tooltip-arrow.svg'
import styles from './styles.module.css'

interface IProps {
    isShow: boolean
    txtContent: string
    stylePosition?: CSSProperties
}
export const CustomTooltip = ({
    isShow,
    txtContent,
    stylePosition,
}: IProps) => {
    if (!isShow) return null
    return (
        <div style={stylePosition}>
            <div className={styles.container}>
                <p>{txtContent}</p>
                <img src={arrowTooltip} className={styles.arrow} />
            </div>
        </div>
    )
}
