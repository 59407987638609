import { SocialButton } from '@/core/components'
import FacebookLogin from '@greatsumini/react-facebook-login'
import { useTranslation } from 'react-i18next'

interface IProps {
    getSession: (token: string) => void
    classname: string

    keyLabel: string
}
const appId = import.meta.env.VITE_FACEBOOK_APP

export const FacebookSignInWidget = ({
    getSession,
    classname,
    keyLabel,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <FacebookLogin
            scope='public_profile, email'
            appId={appId}
            onSuccess={response => {
                const accessToken = response.accessToken
                getSession(accessToken)
            }}
            loginOptions={{
                return_scopes: true,
            }}
            render={({ onClick }) => (
                <SocialButton
                    className={classname}
                    onClick={onClick}
                    label={t(keyLabel)}
                    socialName='facebook'
                />
            )}
        />
    )
}
