import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { Drawer } from 'antd'
import { useEffect, useState } from 'react'
import { GuidoChat } from '../../components/guido-chat'
import { ChatFormControlAtom, HeaderChat } from '../../components'
import styles from './styles.module.css'
import { useChatMessages } from '../../hooks'
import { BotQuestionnaireWIdget } from '../bot-questionnaire'
import { ModalQuestionsSuccess } from '../bot-questionnaire/components/modal-success'
import { getStatusQuestionareReq, getStatusShowQuestionareReq } from '../../api'
import * as Sentry from '@sentry/react'
import _ from 'lodash'

export const ChatBotWidget = () => {
    const { width: widthWindow } = useResizeWindow()
    const [open, setOpen] = useState(false)
    const [questionaire, setQuestionaire] = useState<{
        isAgain?: boolean
        isFirst?: boolean
    }>()
    const [openModal, setOpenModal] = useState(false)

    const {
        createMessage,
        messages,
        isBotMessage,
        answerLoading,
        onChangePage,
        page,
    } = useChatMessages()

    const getStatusQuestionare = async () => {
        try {
            const { data } = await getStatusQuestionareReq()
            setQuestionaire({ ...questionaire, isFirst: !data })
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const getStatusShowQuestionare = async () => {
        try {
            const { data } = await getStatusShowQuestionareReq()
            setQuestionaire({ ...questionaire, isAgain: !data })
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    useEffect(() => {
        getStatusQuestionare()
        getStatusShowQuestionare()
    }, [open])

    useEventsListener('guidoBot', (data: any) => {
        setOpen(data.isShow)
    })

    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '736px'
        }
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            styles={{
                body: {
                    padding: 0,
                    overflow: 'hidden',
                },
                wrapper: { width: drawerWidth() },
            }}
            onClose={() => setOpen(false)}>
            <div className={styles.chat_container}>
                <HeaderChat closeChat={() => setOpen(false)} />

                {questionaire?.isAgain || questionaire?.isFirst ? (
                    <BotQuestionnaireWIdget
                        openModal={() => setOpenModal(true)}
                        close={() => setQuestionaire(null)}
                    />
                ) : (
                    <>
                        <GuidoChat
                            messages={messages}
                            isBotMessage={isBotMessage}
                            onChangePage={onChangePage}
                            page={page}
                        />

                        <ChatFormControlAtom
                            loading={answerLoading}
                            sendMessage={val =>
                                createMessage({ content: val, isMy: true })
                            }
                        />
                        <ModalQuestionsSuccess
                            isOpen={openModal}
                            close={() => setOpenModal(false)}
                        />
                    </>
                )}
            </div>
        </Drawer>
    )
}
