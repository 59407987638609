import { AccountGroupWidget, SearchCity, SearchCountry } from '@/core/widgets'
import styles from './styles.module.css'
import { AuthGroupAtom } from './atoms'
import { useNavigate } from 'react-router-dom'
import { useResizeWindow, useSession } from '@/core/hooks'
import { AuthStep } from '@/modules/auth/typing'
import { useMemo } from 'react'
import { appEvents } from '@/core/events'
import { useAccountStore, useCategoryRiskStore } from '@/store'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'

export const Header = () => {
    const navigate = useNavigate()
    const { token } = useSession()
    const { account } = useAccountStore()
    const { active } = useCategoryRiskStore()
    const { width: widthWindow } = useResizeWindow()
    const isOpenBar: boolean = JSON.parse(localStorage.getItem('BAR'))
    const subscribe = () => {
        if (!account) {
            appEvents.emit('previewTariffs', {
                isShow: true,
            })
            close()
        } else if (account && !account?.subscription) {
            appEvents.emit('subsribe', {
                isShow: true,
                method: 'buy',
            })
        }
    }

    const headerGroup = useMemo(() => {
        if (token) {
            return <AccountGroupWidget />
        }
        return (
            <AuthGroupAtom
                goTariff={subscribe}
                goSignIn={() =>
                    navigate('auth', { state: { step: AuthStep.Login } })
                }
                goSignUp={() =>
                    navigate('auth', {
                        state: { step: AuthStep.Register },
                    })
                }
            />
        )
    }, [token, open, account])

    const searchCountry = useMemo(() => {
        if (
            !account ||
            (account && !account?.subscription) ||
            active?.category === CategoryRisks.Requirements ||
            active?.subcategory === SubcategoryRisks.SeaLevelRise ||
            (isOpenBar && widthWindow < 1050)
        ) {
            return null
        } else if (active?.subcategory === SubcategoryRisks.Weather) {
            return <SearchCity />
        }
        return <SearchCountry />
    }, [account, active, widthWindow, isOpenBar])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.right_container}>
                    <>{searchCountry}</>
                    <>{headerGroup}</>
                </div>
            </div>
        </>
    )
}
