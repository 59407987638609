import { Link } from 'react-router-dom'
import './styles.css'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
interface IProps {
    style?: CSSProperties
}
export const TermsPolicy = ({ style }: IProps) => {
    const { t } = useTranslation()
    return (
        <div style={style}>
            <div className='container_policy'>
                <p>{t('agreeTerms')}</p>
                &nbsp;{' '}
                <Link className='terms' to={'terms'}>
                    {t('terms')}
                </Link>
                &nbsp;&amp;&nbsp;
                <Link className='terms' to={'policy'}>
                    {t('policy')}
                </Link>
            </div>
        </div>
    )
}
