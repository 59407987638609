import { PrimaryButton } from '@/core/components/buttons'
import styl from './styles.module.css'
import { createStyleSheet } from '@/core/helpers'
import { useTranslation } from 'react-i18next'
import { ChooseLanguageWidget } from '@/core/widgets/chose-language'

interface IProps {
    goTariff: () => void
    goSignIn: () => void
    goSignUp: () => void
    avatar?: string
}

export const AuthGroupAtom = ({ goSignIn, goSignUp, goTariff }: IProps) => {
    const { t } = useTranslation()

    return (
        <div className={styl.auth_group}>
            <ChooseLanguageWidget />

            <PrimaryButton
                label={t('tariffPlans')}
                onClick={goTariff}
                className={styl.tariff_btn}
                labelStyle={styles.labelBtn}
            />

            <PrimaryButton
                label={t('signIn')}
                onClick={goSignIn}
                className={styl.signIn_btn}
                labelStyle={styles.labelBtn}
            />

            <PrimaryButton
                label={t('signUp')}
                onClick={goSignUp}
                className={styl.signUn_btn}
                labelStyle={styles.labelBtn}
            />
        </div>
    )
}

const styles = createStyleSheet({
    labelBtn: {
        fontSize: 14,
        fontWeight: '600',
    },
})
