import { Icon } from '@/core/components'
import { createStyleSheet } from '@/core/helpers'
interface IProps {
    isOpen: boolean
}
export const AboutUsBtn = ({ isOpen }: IProps) => {
    if (!isOpen) {
        return <Icon name='inform' size={24} color='#717375' />
    }
    return (
        <button style={styles.container}>
            About Us <Icon name='arrow-2' size={24} color='#717375' />
        </button>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        alignItems: 'center',
        color: '#717375',
        gap: 8,
        fontSize: 16,
        fontWeight: '500',
        cursor: 'pointer',
        textWrap: 'nowrap',
    },
})
