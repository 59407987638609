import { providerGoogle, auth } from '@/config/firebase'
import { signInWithPopup } from 'firebase/auth'
import { socialAuthReq } from '../api'
import { useSession } from '@/core/hooks'
import * as Sentry from '@sentry/react'

export const useSocialAuth = () => {
    const { saveSession } = useSession()

    const signInByGoogle = async () => {
        try {
            const resp: any = await signInWithPopup(auth, providerGoogle)
            const { data } = await socialAuthReq({
                idToken: resp._tokenResponse.idToken,
                deviceName: 'pc',
            })
            saveSession(data)
        } catch (error) {
            Sentry.captureException(error)
            //
        }
    }

    const getSessionByFacebook = async (accessToken: string) => {
        try {
            const { data } = await socialAuthReq({
                idToken: accessToken,
                deviceName: 'pc',
                socialType: 'facebook',
            })
            saveSession(data)
        } catch (error: any) {
            Sentry.captureException(error)
        }
    }
    return { signInByGoogle, getSessionByFacebook }
}
