import label404 from '@/assets/images/404.png'
import hat from '@/assets/images/hat-45.svg'
import styles from './styles.module.css'
import { PrimaryButton } from '@/core/components'
import { useNavigate } from 'react-router-dom'

export const Page404 = () => {
    const navigate = useNavigate()
    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                <img src={hat} className={styles.hat_icon} />
                <img src={label404} style={{ height: 200, width: 530 }} />
                <p className={styles.description}>
                    Oops! <br />
                    Something went wrong
                </p>

                <PrimaryButton
                    onClick={() => navigate('/')}
                    label='Home page'
                    style={{ width: 150 }}
                    labelStyle={{ fontWeight: '600', fontSize: 14 }}
                />
            </div>
        </div>
    )
}
