import { useEffect } from 'react'
import { appEvents, AppEvents, socketEvents, SocketEvents } from '../events'
export const useEventsListener = <T extends keyof AppEvents>(
    name: T,
    action: (data: AppEvents[T]) => void,
    dependencies: any[] = [],
) => {
    useEffect(() => {
        const fn = (data: AppEvents[T]) => action(data)
        appEvents.on(name, fn)
        return () => appEvents.off(name, fn)
    }, dependencies)
}

export const useSocketListener = <T extends keyof SocketEvents>(
    name: T,
    action: (data: SocketEvents[T]) => void,
    dependencies: any[] = [],
) => {
    useEffect(() => {
        const fn = (data: SocketEvents[T]) => action(data)
        socketEvents.on(name, fn)
        return () => socketEvents.off(name, fn)
    }, dependencies)
}
