import { sendUserNotificationIdReq } from '@/modules/notifications/api'
import { useAccountStore } from '@/store'
import { useEffect, useState } from 'react'
import OneSignal from 'react-onesignal'

const appId = import.meta.env.VITE_ONESIGNAL_APP_ID

export const useOnesignal = () => {
    const { account } = useAccountStore()
    const [isSubscribe, setIsSubscribe] = useState<string>(null)

    const initOnesignal = async () => {
        await OneSignal.init({
            appId,
        })
        await OneSignal.login(account?.id)
    }

    const handleChange = (val: any) => {
        setIsSubscribe(val.current.id)
    }
    const sendId = async () => {
        await sendUserNotificationIdReq({
            notificationUserId: 'pc',
            deviceUuid: 'pc',
        })
    }

    useEffect(() => {
        OneSignal.User.PushSubscription.addEventListener('change', handleChange)
        return () =>
            OneSignal.User.PushSubscription.removeEventListener(
                'change',
                handleChange,
            )
    }, [])

    useEffect(() => {
        if (isSubscribe) {
            setTimeout(() => sendId(), 3000)
        }
    }, [isSubscribe])

    return { initOnesignal }
}
