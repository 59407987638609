import { useEffect, useState } from 'react'
import { SelectCountry } from '../country-autocomplete'
import { IShortLocation } from '@/modules/auth/typing'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import _ from 'lodash'
import { countriesConfig } from '../country-autocomplete/countries.config'
import { useTranslation } from 'react-i18next'

interface IProps {
    onChangeList?: (val: string) => void
    disabled?: boolean
    values: string[]
    error?: string
}
export const CountriesTagsWidget = ({
    onChangeList,
    disabled,
    values,
    error,
}: IProps) => {
    const { t } = useTranslation()
    const [countries, setCountries] = useState<IShortLocation[]>([])
    const [reset, setReset] = useState(false)

    const handleOnChange = (val: IShortLocation) => {
        if (!val) return
        if (countries.some(it => it.countryCode === val.countryCode)) {
            setCountries(prev =>
                prev.filter(it => it.countryCode !== val.countryCode),
            )
        } else {
            setCountries([...countries, val])
        }
        setReset(true)
        onChangeList(val.countryCode)
    }

    useEffect(() => {
        if (!_.isEmpty(values)) {
            const saveTags: IShortLocation[] = countriesConfig
                .filter(it => values.includes(it.alpha2))
                .map(el => ({ countryCode: el.alpha2, locationName: el.name }))

            setCountries(saveTags)
        }
    }, [values])

    useEffect(() => {
        if (reset) {
            setReset(false)
        }
    }, [reset])

    return (
        <div>
            <SelectCountry
                chooseCountries={values}
                disabled={disabled || values?.length > 5}
                valueHidden={reset}
                onSelectCountry={handleOnChange}
                label={t('findCountries')}
                error={error}
                id='notification-settings-select-country'
            />

            <div className={styles.tags_container}>
                {countries.map(it => {
                    return (
                        <div className={styles.tag}>
                            <p>{it.locationName}</p>
                            {disabled ? null : (
                                <Icon
                                    onClick={() => handleOnChange(it)}
                                    name='close-circle'
                                    size={20}
                                    color='#0F0E0E'
                                />
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
