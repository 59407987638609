export enum WindDirection {
    North = 'N',
    NorthNortheast = 'NNE',
    Northeast = 'NE',
    EastNortheast = 'ENE',
    East = 'E',
    EastSoutheast = 'ESE',
    Southeast = 'SE',
    SouthSoutheast = 'SSE',
    South = 'S',
    SouthSouthwest = 'SSW',
    Southwest = 'SW',
    WestSouthwest = 'WSW',
    West = 'W',
    WestNorthwest = 'WNW',
    Northwest = 'NW',
    NorthNorthwest = 'NNW',
}
