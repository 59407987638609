import _ from 'lodash'
import styles from './style.module.css'

interface IProps {
    isActive: boolean
    color?: string
    disabled?: boolean
}
export const RadioButton = ({ isActive, color, disabled }: IProps) => {
    return (
        <div
            className={styles.container}
            style={{
                borderColor: disabled
                    ? '#B1BECA'
                    : _.defaultTo(color, '#0F0E0E'),
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}>
            <div
                style={{
                    backgroundColor: disabled
                        ? '#B1BECA'
                        : _.defaultTo(color, '#0F0E0E'),
                }}
                className={
                    isActive ? `${styles.show} ${styles.radio}` : styles.radio
                }
            />
        </div>
    )
}
