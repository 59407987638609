import { presenceCost, validate } from '@/core/tools'
const constraints = {
    password: {
        presence: presenceCost,
        length: { minimum: 6, message: 'min 6 characters' },
    },
    confirmPassword: {
        presence: presenceCost,
        equality: {
            attribute: 'password',
            message: '^Passwords don’t match. Try again!',
        },
        length: { minimum: 6, message: '^Confirm password min 6 characters' },
    },
}

export const validateResetPassword = (data: any) => {
    return validate<any>(data, constraints)
}
