import { useEffect, useMemo, useState } from 'react'
import { getGroupForecastReq } from '../../api'
import { Loader } from '@/core/components'
import {
    IAstronomy,
    ICurrentDay,
    IWeatherForecastDayResponse,
} from '../../api/interfaces'
import { useLocation } from 'react-router-dom'
import {
    CurrentWeather,
    Forecast,
    HeaderWeather,
    MobileHeaderWeather,
    UVIndexProgress,
    WeatherAlerts,
    WeatherTable,
} from '../../components'
import { FullNotiicationsWidget } from '@/modules/notifications/widgets'
import styles from './styles.module.css'
import moment from 'moment'
import { IWeatherAlert } from '../../typing/interface'
import _ from 'lodash'
import { getDataForRows } from '../../utils'
import * as Sentry from '@sentry/react'
import { AccountPage } from '@/modules/user/pages'
import { useLanguageStore, useSearchCityStore } from '@/store'
import { useGetRows } from '../../hooks'

export const DetailedWeather = () => {
    const { state } = useLocation()
    const { lang } = useLanguageStore()

    const [weather, setWeather] = useState(null)
    const [forecast, setForecast] =
        useState<IWeatherForecastDayResponse[]>(null)
    const [currentWeather, setCurrentWeather] = useState<ICurrentDay>(null)
    const [astronomy, setAstronomy] = useState<IAstronomy>(null)
    const [isloading, setIsLoading] = useState(false)
    const [alerts, setAlerts] = useState<IWeatherAlert[]>([])
    const [uvIndex, setUvIndex] = useState(0)
    const { clearCity } = useSearchCityStore()

    const getWeather = async () => {
        setIsLoading(true)
        try {
            const { data } = await getGroupForecastReq(
                `${state?.location.coordinate.lat},${state?.location.coordinate.lng}`,
            )

            setForecast(
                data.forecast.forecastday.filter(it =>
                    moment(it.date).isAfter(moment().format('YYYY-MM-DD')),
                ),
            )

            setCurrentWeather(data.current)
            setAstronomy(data.forecast.forecastday[0].astro)

            const forecastHours = data.forecast.forecastday[0].hour
            const weatherZones = getDataForRows(forecastHours)

            setWeather(weatherZones)

            setAlerts(
                data.alerts.alert.map(it => ({
                    category: it?.category,
                    description: it?.desc,
                    date: `Expires ${moment(it?.expires).format('MMMM DD')}, ${moment(it?.expires).format('hh:mm')}`,
                    title: it?.headline,
                })),
            )
            setUvIndex(data.current.uv <= 12 ? data.current.uv : 12)
        } catch (error) {
            Sentry.captureException(error)
        }
        setIsLoading(false)
    }

    const rows = useGetRows(weather)

    useEffect(() => {
        getWeather()
    }, [state?.location?.city])

    const currentWeatherBlock = useMemo(() => {
        if (!currentWeather) return null
        return (
            <CurrentWeather
                icon={currentWeather?.condition?.icon}
                last_updated={currentWeather?.last_updated}
                temp_c={currentWeather?.temp_c}
                sunrise={astronomy?.sunrise}
                sunset={astronomy?.sunset}
                realFeel={currentWeather?.feelslike_c}
                wind={{
                    dir: currentWeather?.wind_dir,
                    kph: currentWeather?.wind_kph,
                }}
                pressure={currentWeather?.pressure_mb}
                humidity={currentWeather?.humidity}
            />
        )
    }, [currentWeather, state?.location, lang])

    const forecastBlock = useMemo(() => {
        if (!forecast) return
        return <Forecast forecast={forecast} />
    }, [forecast, state?.location])

    if (isloading) {
        return <Loader />
    }

    return (
        <div className={styles.layout}>
            <HeaderWeather
                clearSearchCity={clearCity}
                weatherLocation={{
                    region: _.defaultTo(state?.location?.region, ''),
                    city: _.defaultTo(state?.location?.city, ''),
                    country: state?.location?.country,
                }}
            />

            <MobileHeaderWeather
                clearSearchCity={clearCity}
                weatherLocation={{
                    region: _.defaultTo(state?.location?.region, ''),
                    city: _.defaultTo(state?.location?.city, ''),
                    country: state?.location?.country,
                }}
            />
            <div className={styles.content}>
                <div className={styles.row}>
                    <>{currentWeatherBlock}</>

                    <>{forecastBlock}</>
                </div>

                <div className={styles.row_grid}>
                    <WeatherTable data={rows} />
                    <div className={styles.options_weather_container}>
                        <WeatherAlerts alerts={alerts} />

                        <UVIndexProgress uvIndex={uvIndex} />
                    </div>
                </div>
            </div>

            <FullNotiicationsWidget />
            <AccountPage />
        </div>
    )
}
