import { useState } from 'react'
import { TabBarAtom } from './atoms'
import './styles.css'
import { AccountTabKey } from '../../typing/enums'
import { AccountWidget } from '../../widgets'
import { SettingsNotiificationsWidget } from '@/modules/notifications/widgets'
import { UserSubscribeWidget } from '@/modules/subscribe/widgets'
import { ChatBotSettingsWidget } from '@/modules/chat-bot/widgets'
import { BlurSubscription } from '@/modules/subscribe/components'
import { useAccountStore } from '@/store'
import { SubscriptionStatus } from '@/modules/subscribe/typing/enums'

export const MobileAccountTabs = () => {
    const [tab, setTab] = useState<AccountTabKey>(AccountTabKey.Profile)
    const { account } = useAccountStore()
    const onChangeTab = (val: AccountTabKey) => {
        setTab(val)
    }
    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    const isSubscribe = account?.subscription

    const isStopScroll = isSubscribe && isTrial

    return (
        <div className={'container_tabs'}>
            <TabBarAtom onClickTab={onChangeTab} activeTab={tab} />
            {tab === AccountTabKey.Profile ? (
                <div className={'content_tab'}>
                    <AccountWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.Notifications ? (
                <div
                    className={'content_tab'}
                    style={{ overflow: isStopScroll ? 'hidden' : 'auto' }}>
                    <BlurSubscription />
                    <SettingsNotiificationsWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.Subscribe ? (
                <div className={'content_tab'}>
                    <UserSubscribeWidget />
                </div>
            ) : null}

            {tab === AccountTabKey.ChatBot ? (
                <div className={'content_tab'} style={{}}>
                    <BlurSubscription />
                    <ChatBotSettingsWidget />
                </div>
            ) : null}
        </div>
    )
}
