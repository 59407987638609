import { http } from '@/core/services'
import {
    IChatBotMessageAnswer,
    IChatQuestionPayload,
    IChatSettings,
    IHistoryChatBotParams,
    IHistoryChatBotResponse,
    IQuestionToChatBot,
} from './interface'

export const sendChatHistoryReq = (data: IChatSettings) => {
    return http.put('chats/settings', data)
}

export const getChatSettingsReq = () => {
    return http.get<IChatSettings>('chats/settings')
}

export const sendMessageChatBotReq = (data: IQuestionToChatBot) => {
    return http.post<IChatBotMessageAnswer>('chats/ask-bot', data)
}

export const getHistoryChatBotReq = (params: IHistoryChatBotParams) => {
    return http.get<IHistoryChatBotResponse>('chats/messages', { params })
}

export const sendChatQuestionsReq = (data: IChatQuestionPayload[]) => {
    return http.put('chats/questionnaire', { data })
}

export const getStatusQuestionareReq = () => {
    return http.get<boolean>('chats/questionnaire/is-completed')
}

export const getStatusShowQuestionareReq = () => {
    return http.get<boolean>('chats/questionnaire/is-shown')
}
