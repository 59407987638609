import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import styles from './styles.module.css'
import { subscribeLabel } from '@/modules/subscribe/configs/advantes.config'
import { useTranslation } from 'react-i18next'

interface IProps {
    subscribe: SubscriptionType
    activePeriod: string
}
export const CurrentSubscribeAtom = ({ subscribe, activePeriod }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.items_row}>
                <div className={styles.item}>
                    <p className={styles.label}>{t('yourSubscribtion')}</p>
                    <p>
                        {subscribe
                            ? subscribeLabel[subscribe]
                            : t('subscribtionNotSelected')}
                    </p>
                </div>
                <div className={styles.item}>
                    <p
                        className={
                            subscribe ? styles.label_up : styles.label_down
                        }>
                        {t('validUntil')}
                    </p>
                    <p>{activePeriod}</p>
                </div>
            </div>
        </div>
    )
}
