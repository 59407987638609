import { usePaginationList } from '@/core/hooks'
import { getNotificationsReq } from '../api'
import { INotification } from '../typing'

export const useNotifications = () => {
    const list = usePaginationList<INotification>({
        fetchItems: getNotificationsReq,
        needInit: false,
        clearWhenReload: false,
        limit: 15,
        loadParams: {
            sort: 'DESC',
            sortField: 'createdAt',
        },
    })

    return {
        notifications: list.items,
        loading: list.isLoading,
        loadNext: list.loadMoreItemsList,
        setParams: list.setLoadParams,
        setItems: list.setItems,
        resetList: list.resetFlatList,
        loadPage: list.loadPage,
    }
}
