import { PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { CSSProperties } from 'react'

interface IProps {
    cardPicture: string
    labelCard: string
    labelBtn: string
    onClickCard: () => void
    style?: CSSProperties
}

export const CardFaq = ({
    cardPicture,
    labelCard,
    labelBtn,
    onClickCard,
    style,
}: IProps) => {
    return (
        <div className={styles.card} style={style}>
            <img className={styles.card_picture} src={cardPicture} />

            <p className={styles.label}>{labelCard}</p>

            <PrimaryButton
                onClick={onClickCard}
                className={styles.card_btn}
                labelStyle={{
                    color: '#242424',
                    fontSize: 14,
                    fontWeight: '500',
                    lineHeight: 19.6,
                }}
                label={labelBtn}
            />
        </div>
    )
}
