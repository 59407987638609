import { Taranslates } from '../interfaces'

export const translationEN: Taranslates = {
    // Profile
    cancel: 'Cancel',
    profile: 'Profile',
    edit: 'Edit',
    fullname: 'Full name',
    countryResidence: 'Country of residence',
    gender: 'Gender',
    man: 'Male',
    woman: 'Female',
    email: 'Email',
    citіzenship: 'Citіzenship',
    birthday: 'Date of birth',
    citizenship: 'Citizenship',
    infoUseEmail: 'Please enter your email to get important information',
    errorExistEmail: 'Email is already registered',

    notifications: 'Notifications',
    chooseNotifications: 'Choose type notifications you want to get',
    geoConflicts: 'Geopolitical conflicts',
    internalConflicts: 'Internal social tensions',
    potentialInsecurity: 'Potential insecurity',
    climate: 'Climate',
    medical: 'Health',
    methodNotifications: 'Choose method how you want to get notifications',
    onEmail: 'On email',
    pushNotifications: 'Push-notification',

    women: 'Women',
    LGBTQ: 'LGBTQ+',
    bordersEntry: 'Borders&Entry Updates',
    transportUpdates: 'Transport Updates',

    followCountries: 'Follow for countries',
    chatBotSettings: 'Chatbot settings',
    chatHistoryToEmail: 'Send chatbot history to your email',

    allow: 'Allow',
    deny: 'Deny',
    //Subscribtion settings
    subscription: 'Subscription',
    cancelSubscribtionBtn: 'Cancel subscription',
    updateSubscriptionBtn: 'Update subscription',
    yourSubscribtion: 'Your subscription',
    validUntil: 'Valid until',
    historySubscription: 'History of subscriptions',
    privacyPolicy: 'Privacy policy',
    termsService: 'Terms of service',
    rightsReserved: 'All rights reserved',

    accountOverview: 'Account overview',
    changePassword: 'Change password',
    save: 'Save',

    sureLogout: 'Are you sure, you want',
    toLogout: 'to log out',
    logout: 'Log out',
    back: 'Back',

    editInAccount: 'You can edit these settings in your personal account',
    setupNotifications: 'Setup your notifications',
    errorSelectCountry: 'You must select at least 1 country',
    sureSubscribtion: 'Are you sure, you want',
    cancelSubscribtion: 'to cancel your subscription',
    unsubscribe: 'Unsubscribe',
    canSubscribe: 'You can subscribe again at any time',
    successCancelSubscribtion:
        'Request received. Your will have access until the end of current subscription.',
    successUpdateAccount: 'Account update successfully',

    selectGender: 'Select gender',
    disabledEmail: 'Email cannot be edited',

    findCountries: 'Find countries',
    subscribtionNotSelected: 'Not selected',

    // Overview
    overview: 'Overview',
    conflicts: 'Conflicts',
    dangerPotential: 'Potential danger',
    social: 'Social issues',
    standardLiving: 'Standard of living',
    foodDrinking: 'Food and drinking water supplies',
    humanCapitalDev: 'Human capital development',
    medicalSituation: 'Medical situation',
    pandemics: 'Pandemics and epidemics',
    weather: 'Weather',
    seaLevel: 'Sea level rise',
    requirements: 'Entry requirements',
    fromCountry: 'From country',
    toCountry: 'To country',
    hightRisk: 'High',
    middleRisk: 'Middle',
    lowRisk: 'Low',
    notMonitored: 'Not monitored',

    searchCountries: 'Search countries',
    searchCity: 'Search city',
    viewAll: 'View all',
    allNotifications: 'All notifications',
    forCountries: 'For countries',
    showMore: 'Show more',

    // Instruction
    conflictsInstruction:
        'Before visiting a new country, there are several issues you could be interested in.Safety first, so the potential armed conflicts would be a major blow to your plans. Pick the Conflicts section and find all places where there’s a danger of armed conflicts.',
    socialIstruction:
        'Information on social issues that may affect your safety.The procedure is the same: pick one of the topics, the colour of the country will tell you the level of risk, and the click on the country would open up relevant information.',
    healthInstruction:
        'The same procedure applies to the Medical section. Find out about potential medical factors and their impact on your security.',
    climateInstruction:
        'Check the weather and the possibility of extreme natural events in the country.',
    requirementsInstruction:
        'Want to know how to get to the country? Go to the Entry requirements section. Choose the country of departure and arrival from the list, click Search, and receive all necessary data starting from visa requirements and ending with the period of stay in the country.',
    legendInstruction:
        'The red colour of the country on the map signals extremely high danger. In our case, of an armed conflict. Yellow stands for a considerable but not imminent risk. Green is for safe zone. Grey means there’s so much to know in this world!',
    legend: 'Legend',
    chatBotInstruction:
        'Want more specific help? Buy Extra and ask Travy, your AI-assisted mate! For the first acquaintance, pass a short survey to get a more personalized approach from Travy. Then, feel free to ask Travy your questions!',
    chatBot: 'Chat-bot',
    map: 'Map',
    mapInstructions:
        'In this section, pick one of the following topics: ongoing geopolitical conflicts internal social dangers potential danger of armed conflict. The colour would show the level of risk for each of these topics. Then click on a country and read up-to-date information on the conflict’s details',
    notificationsInstruction:
        'Receive real-time alerts about hazards in selected countries',
    personalAccount: 'Personal Account',
    accountInstruction:
        'Want to stay informed? Subscribe to notifications on your profile. ',
    //notes
    artificialIntelligenc:
        'The article is generated by artificial intelligence. Check important data.',
    personalizedData: 'Up-to-date personalized data',
    search: 'Search',

    consideration: "The chatbot won't take your answers into consideration.",
    quit: 'Quit',
    // Tariffs
    basic: 'Basic',
    conflictsWorldwide: 'Conflicts Worldwide',
    socialIssues: 'Social issues',
    healthLabel: 'Health',
    climateLabel: 'Climate',
    monthlyDataUpdates: 'Daily&Monthly Data Updates',
    visaEntry: 'Customized Visa/Entry Requirements',
    emergencyPush: 'Emergency Push-Notifications ',
    freeTrial: 'Free trial – 3 days',
    womenLgbt: 'Personalized information for Women and LGBTQ+',
    fullSubsctiption: 'Buy full subscription',

    extra: 'Extra',
    tailoredAI: 'Tailored AI Assistant',
    sightsAI: 'Sights AI Consultant',
    hotelTicketsAI: 'Hotel & Tickets AI Advisor',
    personalizedVisa: 'Customized Visa / Entry Requirements',
    noTrial: 'No free trial, paid upfront',

    basic3: 'Basic 3 months',
    extra3: 'Extra 3 months',
    popular: 'Popular',
    getStarted: 'Get started',
    titleSubscribes: 'Explore the world with SafeTravy',
    benefits: 'Benefits',
    month: 'month',
    buy: 'Buy',
    currentTariff: 'Current tariff plan',
    notHistory: 'Not yet history subscribtions',
    newSubscribtionMessage:
        'Request received. New subscription will be activated within a few minutes.',
    upgradeSubscribtionMessage:
        'Request received. New subscription will be activated within a few minutes.',
    downgradeSubscribtionMessage:
        'Request received. New subscription will be activated after the current one expires.',
    tariffPlans: 'Tariff plans',

    // Questionare
    titleTravy: 'Hello! I’m Travy',
    chooseVariant: 'Choose what do you want',
    personalisedAnswer: 'Take the survey for more personalised answers',
    yourQuestions: 'Ask your question',
    purpouse: 'What is the purpose of your trip?',
    leisure: 'Leisure',
    business: 'Business',
    familyAffairs: 'Family affairs/personal matters',
    next: 'Next',

    transportationKind:
        'What kind of transportation do you prefer to get to your destination?',
    landTransportation: 'Land transportation',
    airTransportation: 'Air transportation',
    seaTransportation: 'Sea transportation',

    accommodation: 'What type of accommodation do you prefer?',
    hotel: 'Hotel',
    apartament: 'Appartment',
    hostel: 'Hostel',
    holidayHome: 'Holiday Home',

    activities: 'What types of activities are you interested in?',
    historicalSites: 'Visiting historical sites',
    parks: 'Natural parks and reserves',
    beach: 'Beach vacation',
    shopping: 'Shopping',
    nightlife: 'Nightlife',
    sports: 'Sports activities',
    culinary: 'Culinary tours',

    considerations:
        'Do you have any special requirements or considerations we should be aware of?',
    dietary: 'Dietary needs',
    mobilityAssistance: 'Mobility assistance',
    medicalConditions: 'Medical conditions or health considerations',
    sensoryAccommodations: 'Sensory accommodations',
    no: 'No',

    specificFears:
        'Do you have any specific fears or concerns we should be aware of?',
    heights: 'Fear of heights',
    fearSpaces: 'Fear of enclosed or crowded spaces',
    flying: 'Fear of flying',
    water: 'Fear of water or sea travel',
    animals: 'Fear of insects or animals',
    culturalDifferences: 'Concerns about cultural differences',

    thank: 'Thank you!',
    thanYouMessage:
        'Your answers will be taken into account when the chatbot answers your questions.',

    enterMessage: 'Enter your message',
    canHeplYou: 'How can I help you?',
    notesChatBot:
        'Information is generated by artificial intelligence. Check important data.',

    // Auth
    titleSignIn: 'Welcome Back to SafeTravy',
    password: 'Password',
    continue: 'Continue',
    forgotpassword: 'Forgot password',
    signInGoogle: 'Sign in with Google',
    signInFacebook: 'Sign in with Facebook',
    haventAccount: 'Don’t have an account? ',
    signUp: 'Sign up',
    errorEmailPassword: 'Password or email incorrect. Try again!',

    titleSignUp: 'Welcome to SafeTravy',
    confirmPassword: 'Confirm password',
    agreeTerms: 'By signing up, you agree to the',
    terms: 'Terms and Conditions',
    policy: 'Privacy Policy',
    signUpGoogle: 'sign up with Google',
    signUnFacebook: 'sign up with Facebook',
    existAccount: 'Already have an account?',

    signIn: 'Sign in',
    forgotPasswordTitle: 'Forgot password',
    messageSendCode:
        "Enter your email to reset your password. We'll send you a code",
    rememberPassword: 'Do you remember your password?',

    resetPassword: 'Reset password',
    verificationCodeMessage: 'Enter the verification code sent to your email',
    sendAgain: 'Send again',

    newPasswordTitle: 'Enter new password',
    newPasswordMessage: 'Enter & confirm new password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    passwordChangedSuccessfully: 'Password has been changed successfully',
    sunrise: 'Sunrise',
    sunset: 'Sunset',
    realFeel: 'Real feel',
    wind: 'Wind',
    pressure: 'Pressure',
    humidity: 'Humidity',
    detailedForecast: 'Detailed forecast',
    night: 'Night',
    morning: 'Morning',
    day: 'Day',
    evening: 'Evening',
    temperature: 'Temperature',
    windMetric: 'Wind',
    feelsLike: 'Feels like',
    viceMetric: 'Vice, mm',
    humidityTable: 'Humidity, %',
    precipitationProbability: 'Precipitation probability',
    dangerWarning: 'Danger warning',
    UVIndex: 'UV Index',
    humidityForecast: 'Humidity:',
    emptyAlert: 'It seems that everything is calm',
    emptyAlertCurrentCity: 'in the current city',

    // subscribe
    modalTitle: 'Request recieved',
    subtitleModal:
        'New subscription will be activated within a few minutes. No actions required.',
    contactUsTitle: 'Contact us',
}
