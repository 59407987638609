import { useSessionStore } from './use-session-store.hook'
import { realTimeService, storageService } from '../services'
import { Session } from '../typing'

export const useSession = () => {
    const { sessionToken, setSession, clear } = useSessionStore()

    const saveSession = (payload: Session) => {
        storageService.set('accessToken', payload.accessToken)
        storageService.set('refreshToken', payload.refreshToken)
        setSession(payload.accessToken)
    }

    const getSession = () => {
        const getAccessToken = storageService.get('accessToken')

        setSession(getAccessToken)
    }

    const clearSession = () => {
        realTimeService._disconnect()
        storageService.set('accessToken', '')
        storageService.set('refreshToken', '')
        storageService.remove('category')
        clear()
    }

    return {
        token: sessionToken,
        saveSession,
        getSession,
        clearSession,
    }
}
