import { Modal } from 'antd'
import styles from './styles.module.css'
import { Icon } from '../icons'
import logo from '@/assets/images/logo.svg'
import { PrimaryButton } from '../buttons'
import { useTranslation } from 'react-i18next'
import { IMobileStep } from './step.config'

interface IProps {
    isShow: boolean
    close: () => void
    nextStep: () => void
    prevStep: () => void
    step: IMobileStep
    currentStepIndex: number
    isLast: boolean
    isFirst: boolean
}
export const IntroStepMobile = ({
    isFirst,
    isLast,
    isShow,
    close,
    nextStep,
    prevStep,
    currentStepIndex,
    step,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <Modal
            style={{ top: 20 }}
            styles={{ body: { width: '100%', overflow: 'hidden' } }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isShow}>
            <div className={styles.content_modal}>
                <div className={styles.header}>
                    <img src={logo} className={styles.logo} />
                    <p>{currentStepIndex}/9</p>

                    <Icon
                        name='x'
                        className={styles.icon_close}
                        size={24}
                        onClick={close}
                    />
                </div>

                <div className={styles.description_container}>
                    <p className={styles.title}>{t(`${step?.title}`)}</p>
                    <p className={styles.content}>{t(`${step?.content}`)}</p>
                </div>

                <img
                    src={step?.illustarionPath}
                    className={styles.illustration}
                />
                <div className={styles.action_group}>
                    <PrimaryButton
                        className={`${styles.btn_cancel} ${!isFirst ? styles.show : styles.hide}`}
                        label={t('back')}
                        onClick={prevStep}
                    />

                    <PrimaryButton
                        className={`${styles.btn_save}`}
                        label={isLast ? 'Done' : 'Next'}
                        onClick={isLast ? close : nextStep}
                    />
                </div>
            </div>
        </Modal>
    )
}
