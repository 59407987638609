import { ISubscribeHistoryItem } from '@/modules/subscribe/typing/interface'
import styles from './styles.module.css'
import { HistorySubscribeItemAtom } from '../history-subscribe-item'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

interface IProps {
    history: ISubscribeHistoryItem[]
}

export const SubscribesHistoryAtom = ({ history }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <p className={styles.title}>{t('historySubscription')}</p>
            <div className={styles.list}>
                {_.isEmpty(history) ? (
                    <>
                        <p>{t('notHistory')}</p>
                    </>
                ) : (
                    history.map(it => {
                        return (
                            <HistorySubscribeItemAtom
                                key={it.date}
                                price={it.price}
                                type={it.type}
                                date={it.date}
                            />
                        )
                    })
                )}
            </div>
        </div>
    )
}
