import defUser from '@/assets/images/default-user.webp'
import bell from '@/assets/images/bell.svg'
import styles from './styles.module.css'
import { NotificationsShortList } from '@/modules/notifications/components'
import { appEvents } from '@/core/events'
import { useNotifications } from '@/modules/notifications/hooks'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useAccountStore, useCountUnreadNotificationsStore } from '@/store'
import { INotification } from '@/modules/notifications/typing'
import { useState } from 'react'
import {
    PaidElements,
    SubscriptionStatus,
} from '@/modules/subscribe/typing/enums'

export const AccountGroupWidget = () => {
    const { count, clearUnread } = useCountUnreadNotificationsStore()
    const { account } = useAccountStore()
    const [open, setOpen] = useState(null)
    const { notifications, setParams } = useNotifications()
    const { width: widthWindow } = useResizeWindow()

    const isTrial = account?.subscription?.status === SubscriptionStatus.Trial

    useEventsListener(
        'resetNotification',
        () => {
            clearUnread()
        },
        [],
    )

    const openNotificationsFull = (item?: INotification) => {
        appEvents.emit('notifications', {
            isShow: true,
            notification: item,
        })
    }

    const handleClickNotifications = (e: any) => {
        e.preventDefault()
        setParams({ sortField: 'createdAt', sort: 'DESC', limit: 10, page: 1 })
    }

    const openNotifications = (e: any) => {
        if (isTrial) {
            appEvents.emit('paidElements', { type: PaidElements.Notification })
            setOpen(false)
        } else if (widthWindow < 850) {
            setOpen(false)
            appEvents.emit('notifications', {
                isShow: true,
            })
        }
        handleClickNotifications(e)
    }

    const goAccount = () => {
        appEvents.emit('paidElements', { type: null })
        appEvents.emit('account', { isShow: true })
    }

    const handleOpenNotifications = (val: boolean) => {
        if (isTrial) {
            appEvents.emit('paidElements', { type: PaidElements.Notification })
            setOpen(false)
        } else {
            setOpen(val)
        }
    }

    return (
        <div className={styles.account_group}>
            <NotificationsShortList
                open={open}
                setOpen={handleOpenNotifications}
                notifications={notifications}
                onClickItem={openNotificationsFull}>
                <div
                    id='notifications'
                    className={styles.notification_container}
                    onClick={e => openNotifications(e)}>
                    <img src={bell} style={{ height: 24, width: 24 }} />
                    {count ? (
                        <div className={styles.budge}>
                            <p className={styles.label_budget}>
                                {count > 99 ? '99+' : count}
                            </p>
                        </div>
                    ) : null}
                </div>
            </NotificationsShortList>

            <img
                onClick={() => goAccount()}
                src={account?.avatarUrl || defUser}
                className={styles.user_avatar}
                data-testid='account'
            />
        </div>
    )
}
