import { getArticleReq } from '../../api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Drawer } from 'antd'
import styles from './styles.module.css'
import { Icon, PrimaryButton } from '@/core/components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { risksColor } from '@/modules/map/config'
import { SubcategoryRisks } from '@/core/typing'
import { ICategoryGroup, useAccountStore, useLanguageStore } from '@/store'
import { SubscriptionType } from '@/modules/subscribe/typing/enums'
import { appEvents } from '@/core/events'
import * as Sentry from '@sentry/react'
import { useResizeWindow } from '@/core/hooks'
import { useTranslation } from 'react-i18next'

interface IProps {
    countryCode: { code: string; longName: string }
    open: boolean
    onChangeDrawer: (val: boolean) => void
    categoryGroup: ICategoryGroup
}
export const ArticleWidget = ({
    countryCode,
    open,
    onChangeDrawer,
    categoryGroup,
}: IProps) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()
    const { account } = useAccountStore()
    const { width: widthWindow } = useResizeWindow()

    const [article, setArticle] = useState('')
    const [historyRisks, setHistory] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const isBasic =
        account?.subscription?.type === SubscriptionType.Basic ||
        account?.subscription?.type === SubscriptionType.Basic_3m

    const goTariff = () => {
        onChangeDrawer(false)
        appEvents.emit('subsribe', { isShow: true, method: 'buy' })
    }

    const getArticle = async () => {
        setIsLoading(true)
        try {
            const { data } = await getArticleReq(countryCode.code, {
                ...categoryGroup,
                lang,
            })

            setArticle(data.article.content)
            setHistory(
                data.risksLevels.sort((a: any, b: any) => b.year - a.year),
            )
        } catch (error: any) {
            Sentry.captureException(error)
            //
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getArticle()
    }, [countryCode, open, article])

    const shortArticle = useCallback(() => {
        if (
            account?.subscription?.type === SubscriptionType.Basic ||
            account?.subscription?.type === SubscriptionType.Basic_3m
        ) {
            return styles.short_atricle
        }
        return ''
    }, [account?.subscription?.type])

    const showMore = () => {
        if (
            account?.subscription?.type === SubscriptionType.Basic ||
            account?.subscription?.type === SubscriptionType.Basic_3m
        ) {
            return (
                <div className={styles.blur_overlay}>
                    <div className={styles.show_more}>
                        <PrimaryButton
                            label={t('showMore')}
                            onClick={goTariff}
                            style={{
                                width: 168,
                                boxShadow: ' 0 4px 8px rgba(0, 0, 0, 0.2)',
                            }}
                            labelStyle={{ fontSize: 14, fontWeight: '600' }}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    const risksHistory = useMemo(() => {
        if (categoryGroup?.subcategory !== SubcategoryRisks.Geopolitical)
            return null
        return (
            <div className={styles.history_container}>
                {historyRisks?.map((it: any) => {
                    return (
                        <div className={styles.risk_year}>
                            <div
                                style={{
                                    backgroundColor: risksColor[it?.riskLevel],
                                    height: 24,
                                    width: 24,
                                    borderRadius: 110,
                                }}
                            />
                            <p>{it?.year}</p>
                        </div>
                    )
                })}
            </div>
        )
    }, [historyRisks, categoryGroup?.subcategory, open])

    const drawerWidth = () => {
        switch (true) {
            case widthWindow < 1024:
                return '100dvw'

            default:
                return '736px'
        }
    }

    return (
        <Drawer
            size='large'
            destroyOnClose
            placement='right'
            closable={false}
            open={open}
            styles={{
                body: { padding: 0, paddingBottom: 113 },
                wrapper: { width: drawerWidth() },
            }}
            onClose={() => onChangeDrawer(false)}>
            <div className={styles.header}>
                <p className={styles.label}>{countryCode?.longName}</p>{' '}
                <Icon
                    name='x'
                    size={24}
                    color='#717375'
                    onClick={() => onChangeDrawer(false)}
                />
            </div>
            {isLoading ? (
                <Skeleton count={13} className={styles.sceletons} />
            ) : (
                <div
                    className={
                        isBasic ? styles.stop_scroll : styles.drawer_container
                    }>
                    <div
                        className={`${shortArticle()} ${styles.content_atricle}`}
                        dangerouslySetInnerHTML={{
                            __html: article.split('<p><p/>')[0],
                        }}
                    />

                    {/* <>{showMore()}</> */}
                    {showMore()}
                </div>
            )}

            <div className={styles.sticky_footer}>
                <div className={styles.article_sticky_footer}>
                    <p className={styles.article_check}>
                        * {t('artificialIntelligenc')}
                    </p>
                </div>
                <>{risksHistory}</>
            </div>
        </Drawer>
    )
}
