import { PrimaryButton } from '@/core/components'
import { createStyleSheet } from '@/core/helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
    next: () => void
    exit: () => void
    disabledNext: boolean
}
export const ActionFooter = ({ next, exit, disabledNext }: IProps) => {
    const { t } = useTranslation()
    return (
        <div style={styles.container}>
            <PrimaryButton
                disabled={disabledNext}
                onClick={next}
                label={t('next')}
                labelStyle={styles.btnLabel}
            />
            <PrimaryButton
                onClick={exit}
                label={t('quit')}
                style={styles.txtBtn}
                labelStyle={styles.btnLabel}
            />
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    txtBtn: {
        backgroundColor: '#F0F8FF',
    },
    btnLabel: {
        fontSize: 14,
    },
})
