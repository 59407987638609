import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { useEffect, useState } from 'react'
import '@/assets/styles/index.css'
import { Loader } from './core/components/index.ts'
import * as Sentry from '@sentry/react'

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

Sentry.init({
    dsn: 'https://f5a5be722b33263b7b4f4d62e02ce5cf@o4507509740797952.ingest.de.sentry.io/4507509750169680',
    defaultIntegrations: false,
    integrations: [
        Sentry.dedupeIntegration(),
        Sentry.browserApiErrorsIntegration(),
    ],
    debug: false,
    environment: 'production',
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        /^http:\/\/64\.227\.121\.236:6500\//,
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
        'Request failed with status code 401',
        'Request failed with status code 400',
    ],
})
// eslint-disable-next-line react-refresh/only-export-components
const LoadGoogleMaps = ({ children }: PropsWithChildren) => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,places&v=weekly&language=en`
        script.async = true
        script.onload = () => {
            setLoaded(true)
        }
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [apiKey])

    return loaded ? <>{children}</> : <Loader />
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <LoadGoogleMaps>
            <App />
        </LoadGoogleMaps>
    </React.StrictMode>,
)
