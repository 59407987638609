import { IUser } from '@/modules/user/typing/interfaces'
import { create } from 'zustand'

interface StoreAccountState {
    account: IUser
    setAccount: (account: IUser) => void
    clearAccount: () => void
}

export const useAccountStore = create<StoreAccountState>(set => ({
    account: null,
    setAccount: data => set({ account: data }),
    clearAccount: () => set({ account: null }),
}))
