export const getDataForRows = (hours: any) => {
    const night = getWeatherByTime(hours, false, '02:00', '05:00')

    const morning = getWeatherByTime(hours, true, '08:00', '11:00')

    const day = getWeatherByTime(hours, true, '14:00', '17:00')

    const evening = getWeatherByTime(hours, true, '20:00', '23:00')
    const zonesWeather = [...night, ...morning, ...day, ...evening].reduce(
        (acc, item) => {
            const time = getTime(item.time)
            if (time === '02:00' || time === '05:00') {
                if (!acc['night']) acc['night'] = []
                acc['night'].push(item)
            } else if (time === '08:00' || time === '11:00') {
                if (!acc['morning']) acc['morning'] = []
                acc['morning'].push(item)
            } else if (time === '14:00' || time === '17:00') {
                if (!acc['day']) acc['day'] = []
                acc['day'].push(item)
            } else if (time === '20:00' || time === '23:00') {
                if (!acc['evening']) acc['evening'] = []
                acc['evening'].push(item)
            }
            return acc
        },
        {},
    )
    return zonesWeather
}

export const getWeatherByTime = (
    hours: any[],
    is_day: boolean,
    time1: string,
    time2: string,
) => {
    return hours.filter(
        (it: any) =>
            it.is_day === is_day ||
            getTime(it.time) === time1 ||
            getTime(it.time) === time2,
    )
}

export const getTime = (date: string) => {
    const dateSplit = date.split(' ')
    return dateSplit[1]
}

export const getPercentage = (val: number) => {
    return Math.floor((val / 12) * 100)
}
