import { useEffect, useRef, useState } from 'react'
import './style.css'
import { usePlacesService } from '@/modules/map/hooks'
import { mapStyleConfig } from '@/modules/map/config'
import _ from 'lodash'
import { useResizeWindow, useRisksCategory } from '@/core/hooks'
import { useSearchCountryStore } from '@/store'
import { RiskLevel } from '@/core/typing'

const WORLDS_BOUNDS = {
    north: 85.6,
    south: -60.0,
    east: 180.0,
    west: -185.0,
}

const CENTER_OF_EUROPE = { lat: 50, lng: 25.0 }

interface IProps {
    setCountryCode: (val: any) => void
    countriesList: { [key: string]: RiskLevel }
}
export const GoogleMap = ({ setCountryCode, countriesList }: IProps) => {
    const { height: windowHeight } = useResizeWindow()
    const { country } = useSearchCountryStore()
    const [placeId, setPlaceId] = useState('')
    const featureLayerRef = useRef(null)
    const placesServiceRef = useRef(null)
    const infoWindowRef = useRef(new google.maps.InfoWindow({ maxWidth: 250 }))
    const mapRef = useRef(null)

    const { active } = useRisksCategory()
    const { getCountryInfo } = usePlacesService(mapRef)

    const initialZoom = () => {
        switch (true) {
            case windowHeight > 1091:
                return 3

            default:
                return 2.4
        }
    }

    const getSelectCountryStyle = (id: string) => {
        if (id === placeId) {
            return {
                strokeWeight: 4,
                strokeColor: '#FFFFFF',
                strokeOpacity: 1,
            }
        } else {
            return {
                strokeWeight: 1.0,
                strokeOpacity: 0.5,
            }
        }
    }
    const zoomInButton = document.createElement('div')
    const zoomOutButton = document.createElement('div')

    zoomInButton.className = 'custom-zoom-button'
    zoomOutButton.className = 'custom-zoom-button'
    const updateIconsColor = (zoom: number) => {
        const zoomOutButtonSVG = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 12H22" stroke="${zoom === 2.4 ? '#ccc' : '#0F0E0E'}" stroke-width="2" stroke-linecap="round"/>
                </svg>`
        const zoomInButtonSVG = `
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2V22" stroke="${zoom === 7 ? '#ccc' : '#0F0E0E'}" stroke-width="2" stroke-linecap="round"/>
                    <path d="M2 12H22" stroke="${zoom === 7 ? '#ccc' : '#0F0E0E'}" stroke-width="2" stroke-linecap="round"/>
                </svg>`
        zoomInButton.innerHTML = zoomInButtonSVG
        zoomOutButton.innerHTML = zoomOutButtonSVG
    }

    const initMap = async () => {
        const { Map } = (await google.maps.importLibrary(
            'maps',
        )) as google.maps.MapsLibrary
        //init map
        const mapInstance = new Map(document.getElementById('map'), {
            center: CENTER_OF_EUROPE,
            zoom: initialZoom(),
            mapId: 'a3e90d188d83a2',
            mapTypeControl: false,
            minZoom: initialZoom(),
            maxZoom: 7,
            gestureHandling: 'greedy',
            streetViewControl: false,
            fullscreenControl: false,
            restriction: {
                latLngBounds: WORLDS_BOUNDS,
                strictBounds: false,
            },
            zoomControl: false,
            styles: [
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })
        //set ref
        infoWindowRef.current = null
        featureLayerRef.current = mapInstance.getFeatureLayer(
            google.maps.FeatureType.COUNTRY,
        )
        placesServiceRef.current = new google.maps.places.PlacesService(
            mapInstance,
        )
        mapRef.current = mapInstance

        //create btns control zoom

        mapInstance.addListener('zoom_changed', updateIconsColor)
        const zoom = mapInstance.getZoom()
        updateIconsColor(zoom)

        zoomOutButton.addEventListener('click', () => {
            if (mapInstance.getZoom() > 2.4) {
                mapInstance.setZoom(mapInstance.getZoom() - 1)
            }
        })
        zoomInButton.addEventListener('click', () => {
            if (mapInstance.getZoom() < 7) {
                mapInstance.setZoom(mapInstance.getZoom() + 1)
            }
        })

        mapInstance.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
            zoomOutButton,
        )
        mapInstance.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
            zoomInButton,
        )
    }

    useEffect(() => {
        initMap()
    }, [])

    useEffect(() => {
        if (!mapRef?.current) return
        if (country) {
            setPlaceId(country.placeId)
            zoomAndCenterMap(country.placeId)
        } else {
            mapRef.current.setZoom(2.4)
            setPlaceId('')
        }
    }, [country, mapRef.current])

    useEffect(() => {
        if (!countriesList) return
        mapRef.current.setZoom(2.4)

        if (mapRef.current && featureLayerRef.current) {
            featureLayerRef.current.style = (options: {
                feature: { placeId: string }
            }) => {
                const placeFeature = options.feature as google.maps.PlaceFeature

                const levelRisk = countriesList[placeFeature.placeId]

                if (levelRisk) {
                    return {
                        ...mapStyleConfig[levelRisk],
                        ...getSelectCountryStyle(placeFeature.placeId),
                    }
                } else
                    return {
                        ...mapStyleConfig['default'],
                        ...getSelectCountryStyle(placeFeature.placeId),
                    }
            }
            featureLayerRef.current.addListener('click', handleCountryClick)
        }
    }, [featureLayerRef.current, countriesList, placeId, active?.subcategory])

    const handleCountryClick = async (e: any) => {
        const { shortName, longName } = await getCountryInfo(
            e.features[0].placeId,
        )
        setCountryCode({ code: shortName, longName })

        setPlaceId(e.features[0].placeId)
        await zoomAndCenterMap(e.features[0].placeId)
    }

    const zoomAndCenterMap = async (placeId: string) => {
        if (!mapRef.current || !placesServiceRef.current) return

        placesServiceRef.current.getDetails(
            { placeId },
            (
                place: {
                    geometry: {
                        viewport:
                            | google.maps.LatLngBounds
                            | google.maps.LatLngBoundsLiteral
                        location: google.maps.LatLng | google.maps.LatLngLiteral
                    }
                },
                status: google.maps.places.PlacesServiceStatus,
            ) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place.geometry
                ) {
                    const bounds = new google.maps.LatLngBounds()

                    place.geometry.viewport
                        ? bounds.union(place.geometry.viewport)
                        : bounds.extend(place.geometry.location)
                    mapRef.current.fitBounds(bounds)
                    const center = place.geometry.location
                    mapRef.current.setCenter(center)
                }
            },
        )
    }

    return <div id='map' style={{ width: '100%', height: '100dvh' }}></div>
}
