import { ICountryConfig } from './typing/interfaces'

export const countriesConfig: ICountryConfig[] = [
    {
        name: 'Afghanistan',
        alpha2: 'AF',
        alpha3: 'AFG',
        countryCode: '004',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
    },
    {
        name: 'Åland Islands',
        alpha2: 'AX',
        alpha3: 'ALA',
        countryCode: '248',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Albania',
        alpha2: 'AL',
        alpha3: 'ALB',
        countryCode: '008',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Algeria',
        alpha2: 'DZ',
        alpha3: 'DZA',
        countryCode: '012',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'American Samoa',
        alpha2: 'AS',
        alpha3: 'ASM',
        countryCode: '016',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Andorra',
        alpha2: 'AD',
        alpha3: 'AND',
        countryCode: '020',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Angola',
        alpha2: 'AO',
        alpha3: 'AGO',
        countryCode: '024',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Anguilla',
        alpha2: 'AI',
        alpha3: 'AIA',
        countryCode: '660',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Antarctica',
        alpha2: 'AQ',
        alpha3: 'ATA',
        countryCode: '010',
        region: '',
        subRegion: '',
        regionCode: '',
        subRegionCode: '',
        accessSea: true,
    },
    {
        name: 'Antigua and Barbuda',
        alpha2: 'AG',
        alpha3: 'ATG',
        countryCode: '028',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Argentina',
        alpha2: 'AR',
        alpha3: 'ARG',
        countryCode: '032',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Armenia',
        alpha2: 'AM',
        alpha3: 'ARM',
        countryCode: '051',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
    },
    {
        name: 'Aruba',
        alpha2: 'AW',
        alpha3: 'ABW',
        countryCode: '533',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Australia',
        alpha2: 'AU',
        alpha3: 'AUS',
        countryCode: '036',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'Austria',
        alpha2: 'AT',
        alpha3: 'AUT',
        countryCode: '040',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
    },
    {
        name: 'Azerbaijan',
        alpha2: 'AZ',
        alpha3: 'AZE',
        countryCode: '031',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Bahamas',
        alpha2: 'BS',
        alpha3: 'BHS',
        countryCode: '044',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Bahrain',
        alpha2: 'BH',
        alpha3: 'BHR',
        countryCode: '048',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Bangladesh',
        alpha2: 'BD',
        alpha3: 'BGD',
        countryCode: '050',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Barbados',
        alpha2: 'BB',
        alpha3: 'BRB',
        countryCode: '052',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Belarus',
        alpha2: 'BY',
        alpha3: 'BLR',
        countryCode: '112',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
    },
    {
        name: 'Belgium',
        alpha2: 'BE',
        alpha3: 'BEL',
        countryCode: '056',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
        accessSea: true,
    },
    {
        name: 'Belize',
        alpha2: 'BZ',
        alpha3: 'BLZ',
        countryCode: '084',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Benin',
        alpha2: 'BJ',
        alpha3: 'BEN',
        countryCode: '204',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Bermuda',
        alpha2: 'BM',
        alpha3: 'BMU',
        countryCode: '060',
        region: 'North America',
        subRegion: 'Northern America',
        regionCode: '019',
        subRegionCode: '021',
        accessSea: true,
    },
    {
        name: 'Bhutan',
        alpha2: 'BT',
        alpha3: 'BTN',
        countryCode: '064',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
    },
    {
        name: 'Bolivia (Plurinational State of)',
        alpha2: 'BO',
        alpha3: 'BOL',
        countryCode: '068',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        alpha2: 'BQ-SA',
        alpha3: 'BES',
        countryCode: '535',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Bosnia and Herzegovina',
        alpha2: 'BA',
        alpha3: 'BIH',
        countryCode: '070',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Botswana',
        alpha2: 'BW',
        alpha3: 'BWA',
        countryCode: '072',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Bouvet Island',
        alpha2: 'BV',
        alpha3: 'BVT',
        countryCode: '074',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Brazil',
        alpha2: 'BR',
        alpha3: 'BRA',
        countryCode: '076',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'British Indian Ocean Territory',
        alpha2: 'IO',
        alpha3: 'IOT',
        countryCode: '086',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Brunei Darussalam',
        alpha2: 'BN',
        alpha3: 'BRN',
        countryCode: '096',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Bulgaria',
        alpha2: 'BG',
        alpha3: 'BGR',
        countryCode: '100',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
        accessSea: true,
    },
    {
        name: 'Burkina Faso',
        alpha2: 'BF',
        alpha3: 'BFA',
        countryCode: '854',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Burundi',
        alpha2: 'BI',
        alpha3: 'BDI',
        countryCode: '108',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Cabo Verde',
        alpha2: 'CV',
        alpha3: 'CPV',
        countryCode: '132',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Cambodia',
        alpha2: 'KH',
        alpha3: 'KHM',
        countryCode: '116',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Cameroon',
        alpha2: 'CM',
        alpha3: 'CMR',
        countryCode: '120',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Canada',
        alpha2: 'CA',
        alpha3: 'CAN',
        countryCode: '124',
        region: 'North America',
        subRegion: 'Northern America',
        regionCode: '019',
        subRegionCode: '021',
        accessSea: true,
    },
    {
        name: 'Cayman Islands',
        alpha2: 'KY',
        alpha3: 'CYM',
        countryCode: '136',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Central African Republic',
        alpha2: 'CF',
        alpha3: 'CAF',
        countryCode: '140',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Chad',
        alpha2: 'TD',
        alpha3: 'TCD',
        countryCode: '148',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Chile',
        alpha2: 'CL',
        alpha3: 'CHL',
        countryCode: '152',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'China',
        alpha2: 'CN',
        alpha3: 'CHN',
        countryCode: '156',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Christmas Island',
        alpha2: 'CX',
        alpha3: 'CXR',
        countryCode: '162',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'Cocos (Keeling) Islands',
        alpha2: 'CC',
        alpha3: 'CCK',
        countryCode: '166',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'Colombia',
        alpha2: 'CO',
        alpha3: 'COL',
        countryCode: '170',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Comoros',
        alpha2: 'KM',
        alpha3: 'COM',
        countryCode: '174',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Congo',
        alpha2: 'CG',
        alpha3: 'COG',
        countryCode: '178',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Congo, Democratic Republic',
        alpha2: 'CD',
        alpha3: 'COD',
        countryCode: '180',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Cook Islands',
        alpha2: 'CK',
        alpha3: 'COK',
        countryCode: '184',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Costa Rica',
        alpha2: 'CR',
        alpha3: 'CRI',
        countryCode: '188',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: "Côte d'Ivoire",
        alpha2: 'CI',
        alpha3: 'CIV',
        countryCode: '384',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Croatia',
        alpha2: 'HR',
        alpha3: 'HRV',
        countryCode: '191',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Cuba',
        alpha2: 'CU',
        alpha3: 'CUB',
        countryCode: '192',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Curaçao',
        alpha2: 'CW',
        alpha3: 'CUW',
        countryCode: '531',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Cyprus',
        alpha2: 'CY',
        alpha3: 'CYP',
        countryCode: '196',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Czech Republic',
        alpha2: 'CZ',
        alpha3: 'CZE',
        countryCode: '203',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
    },
    {
        name: 'Denmark',
        alpha2: 'DK',
        alpha3: 'DNK',
        countryCode: '208',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Djibouti',
        alpha2: 'DJ',
        alpha3: 'DJI',
        countryCode: '262',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Dominica',
        alpha2: 'DM',
        alpha3: 'DMA',
        countryCode: '212',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Dominican Republic',
        alpha2: 'DO',
        alpha3: 'DOM',
        countryCode: '214',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Ecuador',
        alpha2: 'EC',
        alpha3: 'ECU',
        countryCode: '218',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Egypt',
        alpha2: 'EG',
        alpha3: 'EGY',
        countryCode: '818',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'El Salvador',
        alpha2: 'SV',
        alpha3: 'SLV',
        countryCode: '222',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Equatorial Guinea',
        alpha2: 'GQ',
        alpha3: 'GNQ',
        countryCode: '226',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Eritrea',
        alpha2: 'ER',
        alpha3: 'ERI',
        countryCode: '232',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Estonia',
        alpha2: 'EE',
        alpha3: 'EST',
        countryCode: '233',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Eswatini',
        alpha2: 'SZ',
        alpha3: 'SWZ',
        countryCode: '748',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Ethiopia',
        alpha2: 'ET',
        alpha3: 'ETH',
        countryCode: '231',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        alpha2: 'FK',
        alpha3: 'FLK',
        countryCode: '238',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Faroe Islands',
        alpha2: 'FO',
        alpha3: 'FRO',
        countryCode: '234',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Fiji',
        alpha2: 'FJ',
        alpha3: 'FJI',
        countryCode: '242',
        region: 'Oceania',
        subRegion: 'Melanesia',
        regionCode: '009',
        subRegionCode: '054',
        accessSea: true,
    },
    {
        name: 'Finland',
        alpha2: 'FI',
        alpha3: 'FIN',
        countryCode: '246',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'France',
        alpha2: 'FR',
        alpha3: 'FRA',
        countryCode: '250',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
        accessSea: true,
    },
    {
        name: 'French Guiana',
        alpha2: 'GF',
        alpha3: 'GUF',
        countryCode: '254',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'French Polynesia',
        alpha2: 'PF',
        alpha3: 'PYF',
        countryCode: '258',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'French Southern Territories',
        alpha2: 'TF',
        alpha3: 'ATF',
        countryCode: '260',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Gabon',
        alpha2: 'GA',
        alpha3: 'GAB',
        countryCode: '266',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Gambia',
        alpha2: 'GM',
        alpha3: 'GMB',
        countryCode: '270',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Georgia',
        alpha2: 'GE',
        alpha3: 'GEO',
        countryCode: '268',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Germany',
        alpha2: 'DE',
        alpha3: 'DEU',
        countryCode: '276',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
        accessSea: true,
    },
    {
        name: 'Ghana',
        alpha2: 'GH',
        alpha3: 'GHA',
        countryCode: '288',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Gibraltar',
        alpha2: 'GI',
        alpha3: 'GIB',
        countryCode: '292',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Greece',
        alpha2: 'GR',
        alpha3: 'GRC',
        countryCode: '300',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Greenland',
        alpha2: 'GL',
        alpha3: 'GRL',
        countryCode: '304',
        region: 'North America',
        subRegion: 'Northern America',
        regionCode: '019',
        subRegionCode: '021',
        accessSea: true,
    },
    {
        name: 'Grenada',
        alpha2: 'GD',
        alpha3: 'GRD',
        countryCode: '308',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Guadeloupe',
        alpha2: 'GP',
        alpha3: 'GLP',
        countryCode: '312',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Guam',
        alpha2: 'GU',
        alpha3: 'GUM',
        countryCode: '316',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Guatemala',
        alpha2: 'GT',
        alpha3: 'GTM',
        countryCode: '320',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Guernsey',
        alpha2: 'GG',
        alpha3: 'GGY',
        countryCode: '831',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Guinea',
        alpha2: 'GN',
        alpha3: 'GIN',
        countryCode: '324',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Guinea-Bissau',
        alpha2: 'GW',
        alpha3: 'GNB',
        countryCode: '624',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Guyana',
        alpha2: 'GY',
        alpha3: 'GUY',
        countryCode: '328',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Haiti',
        alpha2: 'HT',
        alpha3: 'HTI',
        countryCode: '332',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Heard Island and McDonald Islands',
        alpha2: 'HM',
        alpha3: 'HMD',
        countryCode: '334',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'Holy See',
        alpha2: 'VA',
        alpha3: 'VAT',
        countryCode: '336',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Honduras',
        alpha2: 'HN',
        alpha3: 'HND',
        countryCode: '340',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Hong Kong',
        alpha2: 'HK',
        alpha3: 'HKG',
        countryCode: '344',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Hungary',
        alpha2: 'HU',
        alpha3: 'HUN',
        countryCode: '348',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
    },
    {
        name: 'Iceland',
        alpha2: 'IS',
        alpha3: 'ISL',
        countryCode: '352',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'India',
        alpha2: 'IN',
        alpha3: 'IND',
        countryCode: '356',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Indonesia',
        alpha2: 'ID',
        alpha3: 'IDN',
        countryCode: '360',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Iran (Islamic Republic of)',
        alpha2: 'IR',
        alpha3: 'IRN',
        countryCode: '364',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Iraq',
        alpha2: 'IQ',
        alpha3: 'IRQ',
        countryCode: '368',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Ireland',
        alpha2: 'IE',
        alpha3: 'IRL',
        countryCode: '372',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Isle of Man',
        alpha2: 'IM',
        alpha3: 'IMN',
        countryCode: '833',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Israel',
        alpha2: 'IL',
        alpha3: 'ISR',
        countryCode: '376',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Italy',
        alpha2: 'IT',
        alpha3: 'ITA',
        countryCode: '380',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Jamaica',
        alpha2: 'JM',
        alpha3: 'JAM',
        countryCode: '388',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Japan',
        alpha2: 'JP',
        alpha3: 'JPN',
        countryCode: '392',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Jersey',
        alpha2: 'JE',
        alpha3: 'JEY',
        countryCode: '832',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Jordan',
        alpha2: 'JO',
        alpha3: 'JOR',
        countryCode: '400',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
    },
    {
        name: 'Kazakhstan',
        alpha2: 'KZ',
        alpha3: 'KAZ',
        countryCode: '398',
        region: 'Asia',
        subRegion: 'Central Asia',
        regionCode: '142',
        subRegionCode: '143',
    },
    {
        name: 'Kenya',
        alpha2: 'KE',
        alpha3: 'KEN',
        countryCode: '404',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Kiribati',
        alpha2: 'KI',
        alpha3: 'KIR',
        countryCode: '296',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: "Korea (Democratic People's Republic of)",
        alpha2: 'KP',
        alpha3: 'PRK',
        countryCode: '408',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Korea, Republic of',
        alpha2: 'KR',
        alpha3: 'KOR',
        countryCode: '410',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Kuwait',
        alpha2: 'KW',
        alpha3: 'KWT',
        countryCode: '414',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Kyrgyzstan',
        alpha2: 'KG',
        alpha3: 'KGZ',
        countryCode: '417',
        region: 'Asia',
        subRegion: 'Central Asia',
        regionCode: '142',
        subRegionCode: '143',
    },
    {
        name: "Lao People's Democratic Republic",
        alpha2: 'LA',
        alpha3: 'LAO',
        countryCode: '418',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
    },
    {
        name: 'Latvia',
        alpha2: 'LV',
        alpha3: 'LVA',
        countryCode: '428',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Lebanon',
        alpha2: 'LB',
        alpha3: 'LBN',
        countryCode: '422',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Lesotho',
        alpha2: 'LS',
        alpha3: 'LSO',
        countryCode: '426',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Liberia',
        alpha2: 'LR',
        alpha3: 'LBR',
        countryCode: '430',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Libya',
        alpha2: 'LY',
        alpha3: 'LBY',
        countryCode: '434',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'Liechtenstein',
        alpha2: 'LI',
        alpha3: 'LIE',
        countryCode: '438',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
    },
    {
        name: 'Lithuania',
        alpha2: 'LT',
        alpha3: 'LTU',
        countryCode: '440',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Luxembourg',
        alpha2: 'LU',
        alpha3: 'LUX',
        countryCode: '442',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
    },
    {
        name: 'Macao',
        alpha2: 'MO',
        alpha3: 'MAC',
        countryCode: '446',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Madagascar',
        alpha2: 'MG',
        alpha3: 'MDG',
        countryCode: '450',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Malawi',
        alpha2: 'MW',
        alpha3: 'MWI',
        countryCode: '454',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Malaysia',
        alpha2: 'MY',
        alpha3: 'MYS',
        countryCode: '458',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Maldives',
        alpha2: 'MV',
        alpha3: 'MDV',
        countryCode: '462',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Mali',
        alpha2: 'ML',
        alpha3: 'MLI',
        countryCode: '466',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Malta',
        alpha2: 'MT',
        alpha3: 'MLT',
        countryCode: '470',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Marshall Islands',
        alpha2: 'MH',
        alpha3: 'MHL',
        countryCode: '584',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Martinique',
        alpha2: 'MQ',
        alpha3: 'MTQ',
        countryCode: '474',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Mauritania',
        alpha2: 'MR',
        alpha3: 'MRT',
        countryCode: '478',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Mauritius',
        alpha2: 'MU',
        alpha3: 'MUS',
        countryCode: '480',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Mayotte',
        alpha2: 'YT',
        alpha3: 'MYT',
        countryCode: '175',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Mexico',
        alpha2: 'MX',
        alpha3: 'MEX',
        countryCode: '484',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Micronesia (Federated States of)',
        alpha2: 'FM',
        alpha3: 'FSM',
        countryCode: '583',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Moldova, Republic of',
        alpha2: 'MD',
        alpha3: 'MDA',
        countryCode: '498',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
    },
    {
        name: 'Monaco',
        alpha2: 'MC',
        alpha3: 'MCO',
        countryCode: '492',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
        accessSea: true,
    },
    {
        name: 'Mongolia',
        alpha2: 'MN',
        alpha3: 'MNG',
        countryCode: '496',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
    },
    {
        name: 'Montenegro',
        alpha2: 'ME',
        alpha3: 'MNE',
        countryCode: '499',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Montserrat',
        alpha2: 'MS',
        alpha3: 'MSR',
        countryCode: '500',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Morocco',
        alpha2: 'MA',
        alpha3: 'MAR',
        countryCode: '504',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'Mozambique',
        alpha2: 'MZ',
        alpha3: 'MOZ',
        countryCode: '508',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Myanmar',
        alpha2: 'MM',
        alpha3: 'MMR',
        countryCode: '104',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Namibia',
        alpha2: 'NA',
        alpha3: 'NAM',
        countryCode: '516',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Nauru',
        alpha2: 'NR',
        alpha3: 'NRU',
        countryCode: '520',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Nepal',
        alpha2: 'NP',
        alpha3: 'NPL',
        countryCode: '524',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
    },
    {
        name: 'Netherlands',
        alpha2: 'NL',
        alpha3: 'NLD',
        countryCode: '528',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
        accessSea: true,
    },
    {
        name: 'New Caledonia',
        alpha2: 'NC',
        alpha3: 'NCL',
        countryCode: '540',
        region: 'Oceania',
        subRegion: 'Melanesia',
        regionCode: '009',
        subRegionCode: '054',
        accessSea: true,
    },
    {
        name: 'New Zealand',
        alpha2: 'NZ',
        alpha3: 'NZL',
        countryCode: '554',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'Nicaragua',
        alpha2: 'NI',
        alpha3: 'NIC',
        countryCode: '558',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Niger',
        alpha2: 'NE',
        alpha3: 'NER',
        countryCode: '562',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Nigeria',
        alpha2: 'NG',
        alpha3: 'NGA',
        countryCode: '566',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Niue',
        alpha2: 'NU',
        alpha3: 'NIU',
        countryCode: '570',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Norfolk Island',
        alpha2: 'NF',
        alpha3: 'NFK',
        countryCode: '574',
        region: 'Oceania',
        subRegion: 'Australia and New Zealand',
        regionCode: '009',
        subRegionCode: '053',
        accessSea: true,
    },
    {
        name: 'North Macedonia',
        alpha2: 'MK',
        alpha3: 'MKD',
        countryCode: '807',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Northern Mariana Islands',
        alpha2: 'MP',
        alpha3: 'MNP',
        countryCode: '580',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Norway',
        alpha2: 'NO',
        alpha3: 'NOR',
        countryCode: '578',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Oman',
        alpha2: 'OM',
        alpha3: 'OMN',
        countryCode: '512',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Pakistan',
        alpha2: 'PK',
        alpha3: 'PAK',
        countryCode: '586',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Palau',
        alpha2: 'PW',
        alpha3: 'PLW',
        countryCode: '585',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Palestine, State of',
        alpha2: 'PS',
        alpha3: 'PSE',
        countryCode: '275',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
    },
    {
        name: 'Panama',
        alpha2: 'PA',
        alpha3: 'PAN',
        countryCode: '591',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Papua New Guinea',
        alpha2: 'PG',
        alpha3: 'PNG',
        countryCode: '598',
        region: 'Oceania',
        subRegion: 'Melanesia',
        regionCode: '009',
        subRegionCode: '054',
        accessSea: true,
    },
    {
        name: 'Paraguay',
        alpha2: 'PY',
        alpha3: 'PRY',
        countryCode: '600',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
    },
    {
        name: 'Peru',
        alpha2: 'PE',
        alpha3: 'PER',
        countryCode: '604',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Philippines',
        alpha2: 'PH',
        alpha3: 'PHL',
        countryCode: '608',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Pitcairn',
        alpha2: 'PN',
        alpha3: 'PCN',
        countryCode: '612',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Poland',
        alpha2: 'PL',
        alpha3: 'POL',
        countryCode: '616',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
        accessSea: true,
    },
    {
        name: 'Portugal',
        alpha2: 'PT',
        alpha3: 'PRT',
        countryCode: '620',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Puerto Rico',
        alpha2: 'PR',
        alpha3: 'PRI',
        countryCode: '630',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Qatar',
        alpha2: 'QA',
        alpha3: 'QAT',
        countryCode: '634',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Réunion',
        alpha2: 'RE',
        alpha3: 'REU',
        countryCode: '638',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Romania',
        alpha2: 'RO',
        alpha3: 'ROU',
        countryCode: '642',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
        accessSea: true,
    },
    {
        name: 'Russian Federation',
        alpha2: 'RU',
        alpha3: 'RUS',
        countryCode: '643',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
        accessSea: true,
    },
    {
        name: 'Rwanda',
        alpha2: 'RW',
        alpha3: 'RWA',
        countryCode: '646',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Saint Barthélemy',
        alpha2: 'BL',
        alpha3: 'BLM',
        countryCode: '652',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        alpha2: 'SH',
        alpha3: 'SHN',
        countryCode: '654',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Saint Kitts and Nevis',
        alpha2: 'KN',
        alpha3: 'KNA',
        countryCode: '659',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Saint Lucia',
        alpha2: 'LC',
        alpha3: 'LCA',
        countryCode: '662',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Saint Martin (French part)',
        alpha2: 'MF',
        alpha3: 'MAF',
        countryCode: '663',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Saint Pierre and Miquelon',
        alpha2: 'PM',
        alpha3: 'SPM',
        countryCode: '666',
        region: 'North America',
        subRegion: 'Northern America',
        regionCode: '019',
        subRegionCode: '021',
        accessSea: true,
    },
    {
        name: 'Saint Vincent and the Grenadines',
        alpha2: 'VC',
        alpha3: 'VCT',
        countryCode: '670',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Samoa',
        alpha2: 'WS',
        alpha3: 'WSM',
        countryCode: '882',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'San Marino',
        alpha2: 'SM',
        alpha3: 'SMR',
        countryCode: '674',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Sao Tome and Principe',
        alpha2: 'ST',
        alpha3: 'STP',
        countryCode: '678',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Saudi Arabia',
        alpha2: 'SA',
        alpha3: 'SAU',
        countryCode: '682',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Senegal',
        alpha2: 'SN',
        alpha3: 'SEN',
        countryCode: '686',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Serbia',
        alpha2: 'RS',
        alpha3: 'SRB',
        countryCode: '688',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Seychelles',
        alpha2: 'SC',
        alpha3: 'SYC',
        countryCode: '690',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Sierra Leone',
        alpha2: 'SL',
        alpha3: 'SLE',
        countryCode: '694',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Singapore',
        alpha2: 'SG',
        alpha3: 'SGP',
        countryCode: '702',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Sint Maarten (Dutch part)',
        alpha2: 'SX',
        alpha3: 'SXM',
        countryCode: '534',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Slovakia',
        alpha2: 'SK',
        alpha3: 'SVK',
        countryCode: '703',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
    },
    {
        name: 'Slovenia',
        alpha2: 'SI',
        alpha3: 'SVN',
        countryCode: '705',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
    },
    {
        name: 'Solomon Islands',
        alpha2: 'SB',
        alpha3: 'SLB',
        countryCode: '090',
        region: 'Oceania',
        subRegion: 'Melanesia',
        regionCode: '009',
        subRegionCode: '054',
        accessSea: true,
    },
    {
        name: 'Somalia',
        alpha2: 'SO',
        alpha3: 'SOM',
        countryCode: '706',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'South Africa',
        alpha2: 'ZA',
        alpha3: 'ZAF',
        countryCode: '710',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },

    {
        name: 'South Georgia and the South Sandwich Islands',
        alpha2: 'GS',
        alpha3: 'SGS',
        countryCode: '239',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'South Sudan',
        alpha2: 'SS',
        alpha3: 'SSD',
        countryCode: '728',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Spain',
        alpha2: 'ES',
        alpha3: 'ESP',
        countryCode: '724',
        region: 'Europe',
        subRegion: 'Southern Europe',
        regionCode: '150',
        subRegionCode: '039',
        accessSea: true,
    },
    {
        name: 'Sri Lanka',
        alpha2: 'LK',
        alpha3: 'LKA',
        countryCode: '144',
        region: 'Asia',
        subRegion: 'Southern Asia',
        regionCode: '142',
        subRegionCode: '034',
        accessSea: true,
    },
    {
        name: 'Sudan',
        alpha2: 'SD',
        alpha3: 'SDN',
        countryCode: '729',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'Suriname',
        alpha2: 'SR',
        alpha3: 'SUR',
        countryCode: '740',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Svalbard and Jan Mayen',
        alpha2: 'SJ',
        alpha3: 'SJM',
        countryCode: '744',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Sweden',
        alpha2: 'SE',
        alpha3: 'SWE',
        countryCode: '752',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'Switzerland',
        alpha2: 'CH',
        alpha3: 'CHE',
        countryCode: '756',
        region: 'Europe',
        subRegion: 'Western Europe',
        regionCode: '150',
        subRegionCode: '155',
    },
    {
        name: 'Syrian Arab Republic',
        alpha2: 'SY',
        alpha3: 'SYR',
        countryCode: '760',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Taiwan, Province of China',
        alpha2: 'TW',
        alpha3: 'TWN',
        countryCode: '158',
        region: 'Asia',
        subRegion: 'Eastern Asia',
        regionCode: '142',
        subRegionCode: '030',
        accessSea: true,
    },
    {
        name: 'Tajikistan',
        alpha2: 'TJ',
        alpha3: 'TJK',
        countryCode: '762',
        region: 'Asia',
        subRegion: 'Central Asia',
        regionCode: '142',
        subRegionCode: '143',
    },
    {
        name: 'Tanzania',
        alpha2: 'TZ',
        alpha3: 'TZA',
        countryCode: '834',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Thailand',
        alpha2: 'TH',
        alpha3: 'THA',
        countryCode: '764',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Timor-Leste',
        alpha2: 'TL',
        alpha3: 'TLS',
        countryCode: '626',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Togo',
        alpha2: 'TG',
        alpha3: 'TGO',
        countryCode: '768',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
        accessSea: true,
    },
    {
        name: 'Tokelau',
        alpha2: 'TK',
        alpha3: 'TKL',
        countryCode: '772',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Tonga',
        alpha2: 'TO',
        alpha3: 'TON',
        countryCode: '776',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Trinidad and Tobago',
        alpha2: 'TT',
        alpha3: 'TTO',
        countryCode: '780',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Tunisia',
        alpha2: 'TN',
        alpha3: 'TUN',
        countryCode: '788',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'Turkey',
        alpha2: 'TR',
        alpha3: 'TUR',
        countryCode: '792',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Turkmenistan',
        alpha2: 'TM',
        alpha3: 'TKM',
        countryCode: '795',
        region: 'Asia',
        subRegion: 'Central Asia',
        regionCode: '142',
        subRegionCode: '143',
        accessSea: true,
    },
    {
        name: 'Turks and Caicos Islands',
        alpha2: 'TC',
        alpha3: 'TCA',
        countryCode: '796',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Tuvalu',
        alpha2: 'TV',
        alpha3: 'TUV',
        countryCode: '798',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Uganda',
        alpha2: 'UG',
        alpha3: 'UGA',
        countryCode: '800',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Ukraine',
        alpha2: 'UA',
        alpha3: 'UKR',
        countryCode: '804',
        region: 'Europe',
        subRegion: 'Eastern Europe',
        regionCode: '150',
        subRegionCode: '151',
        accessSea: true,
    },
    {
        name: 'United Arab Emirates',
        alpha2: 'AE',
        alpha3: 'ARE',
        countryCode: '784',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'United Kingdom (UK)',
        alpha2: 'GB',
        alpha3: 'GBR',
        countryCode: '826',
        region: 'Europe',
        subRegion: 'Northern Europe',
        regionCode: '150',
        subRegionCode: '154',
        accessSea: true,
    },
    {
        name: 'USA',
        alpha2: 'US',
        alpha3: 'USA',
        countryCode: '840',
        region: 'North America',
        subRegion: 'Northern America',
        regionCode: '019',
        subRegionCode: '021',
        accessSea: true,
    },
    {
        name: 'Minor Outlying Islands',
        alpha2: 'UM',
        alpha3: 'UMI',
        countryCode: '581',
        region: 'Oceania',
        subRegion: 'Micronesia',
        regionCode: '009',
        subRegionCode: '057',
        accessSea: true,
    },
    {
        name: 'Uruguay',
        alpha2: 'UY',
        alpha3: 'URY',
        countryCode: '858',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Uzbekistan',
        alpha2: 'UZ',
        alpha3: 'UZB',
        countryCode: '860',
        region: 'Asia',
        subRegion: 'Central Asia',
        regionCode: '142',
        subRegionCode: '143',
    },
    {
        name: 'Vanuatu',
        alpha2: 'VU',
        alpha3: 'VUT',
        countryCode: '548',
        region: 'Oceania',
        subRegion: 'Melanesia',
        regionCode: '009',
        subRegionCode: '054',
        accessSea: true,
    },
    {
        name: 'Venezuela',
        alpha2: 'VE',
        alpha3: 'VEN',
        countryCode: '862',
        region: 'South America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Viet Nam',
        alpha2: 'VN',
        alpha3: 'VNM',
        countryCode: '704',
        region: 'Asia',
        subRegion: 'South-eastern Asia',
        regionCode: '142',
        subRegionCode: '035',
        accessSea: true,
    },
    {
        name: 'Virgin Islands (British)',
        alpha2: 'VG',
        alpha3: 'VGB',
        countryCode: '092',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Virgin Islands (U.S.)',
        alpha2: 'VI',
        alpha3: 'VIR',
        countryCode: '850',
        region: 'North America',
        subRegion: 'Latin America and the Caribbean',
        regionCode: '019',
        subRegionCode: '419',
        accessSea: true,
    },
    {
        name: 'Wallis and Futuna',
        alpha2: 'WF',
        alpha3: 'WLF',
        countryCode: '876',
        region: 'Oceania',
        subRegion: 'Polynesia',
        regionCode: '009',
        subRegionCode: '061',
        accessSea: true,
    },
    {
        name: 'Western Sahara',
        alpha2: 'EH',
        alpha3: 'ESH',
        countryCode: '732',
        region: 'Africa',
        subRegion: 'Northern Africa',
        regionCode: '002',
        subRegionCode: '015',
        accessSea: true,
    },
    {
        name: 'Yemen',
        alpha2: 'YE',
        alpha3: 'YEM',
        countryCode: '887',
        region: 'Asia',
        subRegion: 'Western Asia',
        regionCode: '142',
        subRegionCode: '145',
        accessSea: true,
    },
    {
        name: 'Zambia',
        alpha2: 'ZM',
        alpha3: 'ZMB',
        countryCode: '894',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
    {
        name: 'Zimbabwe',
        alpha2: 'ZW',
        alpha3: 'ZWE',
        countryCode: '716',
        region: 'Africa',
        subRegion: 'Sub-Saharan Africa',
        regionCode: '002',
        subRegionCode: '202',
    },
]
