import { ICurrentWeather } from '../../typing/interface'
import styles from './styles.module.css'
import { useMemo } from 'react'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'

import { useLanguageStore } from '@/store'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const CurrentWeather = ({
    icon,
    temp_c,
    sunrise,
    sunset,
    last_updated,
    realFeel,
    wind,
    pressure,
    humidity,
}: ICurrentWeather) => {
    const { t } = useTranslation()
    const { lang } = useLanguageStore()

    const datesUpdate = useMemo(() => last_updated?.split(' '), [last_updated])
    const times = ''
    const period = !times[0] ? '' : +times[0] >= 12 ? 'PM' : 'AM'
    dayjs.locale(lang)

    const formatDate = (date: string) => {
        const formattedDate = dayjs(date).format('dddd DD.MM')
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    }
    return (
        <div className={styles.container}>
            <div className={styles.row_current_value}>
                <div className={styles.temprature_container}>
                    <p className={styles.day_label}>
                        {datesUpdate ? formatDate(datesUpdate[0]) : ''}
                    </p>
                    <p className={styles.temperature}>{temp_c.toFixed(0)}°</p>
                </div>
                <div className={styles.weathe_type_container}>
                    <p className={styles.day_label}>
                        {datesUpdate ? datesUpdate[1] : ''} {period}
                    </p>
                    <img src={icon} style={{ height: 48, width: 48 }} />
                </div>
            </div>

            <div className={styles.current_options}>
                <div className={styles.row}>
                    <p className={styles.label_options}>
                        &bull; {t('sunrise')}:
                    </p>
                    <p>{sunrise}</p>
                </div>

                <div className={styles.row}>
                    <p className={styles.label_options}>
                        &bull; {t('sunset')}:
                    </p>
                    <p>{sunset}</p>
                </div>

                <div className={styles.row}>
                    <p className={styles.label_options}>
                        &bull; {t('realFeel')}:
                    </p>
                    <p>{realFeel.toFixed(0)}°</p>
                </div>

                <div className={styles.row}>
                    <p className={styles.label_options}>&bull; {t('wind')}:</p>
                    <p>
                        {wind.dir.split('').join('-')} / {wind.kph}km/h
                    </p>
                </div>

                <div className={styles.row}>
                    <p className={styles.label_options}>
                        &bull; {t('pressure')}:
                    </p>
                    <p>{pressure}MB</p>
                </div>

                <div className={styles.row}>
                    <p className={styles.label_options}>
                        &bull; {t('humidity')}:
                    </p>
                    <p>{humidity}%</p>
                </div>
            </div>
        </div>
    )
}
