import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import earth from '@/assets/images/earth.svg'

export const EmptyAlertsAtom = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.empty_alerts_container}>
            <img src={earth} />
            <p className={styles.empty_alerts_text}>
                {t('emptyAlert')} <br /> {t('emptyAlertCurrentCity')}
            </p>
        </div>
    )
}
