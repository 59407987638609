import { Gender, IOption } from '@/core/typing'

export const genderOptions: IOption[] = [
    {
        label: 'man',
        value: Gender.Male,
    },
    {
        label: 'woman',
        value: Gender.Female,
    },
    {
        label: 'Other',
        value: Gender.Other,
    },
]
