import { LatLngExpression } from 'leaflet'
import _ from 'lodash'
import { Popup } from 'react-leaflet'
import './styles.css'
import { PrimaryButton } from '@/core/components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
    position: LatLngExpression
    location: any
    temperature: number
    condition: string
    icon: string
}
export const WeatherPopupAtom = ({
    position,
    location,
    temperature,
    icon,
}: IProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handelClick = () => {
        navigate('weather', {
            state: { location },
        })
    }
    return (
        <Popup
            position={position}
            maxWidth={200}
            minWidth={200}
            autoPan
            autoPanPaddingBottomRight={[50, 50]}>
            <div className={'container-popup'}>
                <p className='label_place'>
                    {location?.country + ','}{' '}
                    <span>
                        {_.isEmpty(location?.region)
                            ? ''
                            : location?.region + ','}
                    </span>{' '}
                    <span>{location?.city}</span>
                </p>

                <div className={'condition'}>
                    <div className='icon_box'>
                        <img
                            src={icon}
                            alt='weather icon'
                            className={'icon-weather'}
                        />
                    </div>
                    <p className='temperature'>{temperature}°C</p>
                </div>

                <PrimaryButton
                    label={t('showMore')}
                    onClick={handelClick}
                    style={{ width: '100%' }}
                />
            </div>
        </Popup>
    )
}
