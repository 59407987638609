import { PropsWithChildren } from 'react'
import styles from './styles.module.css'

interface IProps {
    showLegend: boolean
    toggleLegend: () => void
}
export const TabletLegendRisk = ({
    showLegend,
    children,
}: PropsWithChildren<IProps>) => {
    return (
        <div
            id='tablet-legend'
            className={`${showLegend ? styles.tabled_legend : styles.hide}
            `}>
            <p className={styles.tablet_label}>Legend</p>
            {children}
        </div>
    )
}
