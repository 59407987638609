import { useAccountStore, useLanguageStore } from '@/store'
import { accountSettingsReq, getAccountReq } from '../api'
import _ from 'lodash'
import { useSession } from '@/core/hooks'
import { storageService } from '@/core/services'
import { logOutReq } from '@/modules/auth/api'
import * as Sentry from '@sentry/react'
import { appEvents } from '@/core/events'

export const useAccount = () => {
    const location = window.location?.href

    const { setAccount, account, clearAccount } = useAccountStore()
    const { lang } = useLanguageStore()
    const { clearSession } = useSession()

    const isSucceeded = (val: string) => val.includes('succeeded')

    const getAccount = async () => {
        try {
            const { data } = await getAccountReq()
            setAccount(data)
            await accountSettingsReq({ key: 'l', value: lang })

            if (_.isNil(data?.subscription) && isSucceeded(location)) {
                return
            } else if (_.isNil(data?.subscription)) {
                appEvents.emit('subsribe', { isShow: true, method: 'buy' })
            }
        } catch (error) {
            storageClearSession()
            Sentry.captureException(error)
        }
    }

    const storageClearSession = () => {
        storageService.remove('category')
        clearSession()
        clearAccount()
    }

    const logOut = async () => {
        try {
            const refreshToken = storageService.get('refreshToken')
            await logOutReq({ refreshToken })
            storageClearSession()
        } catch (error) {
            storageClearSession()
            Sentry.captureException(error)
        }
    }
    return { getAccount, account, clearAccount, logOut, storageClearSession }
}
