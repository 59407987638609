import { AuthStep } from '../typing'
import {
    NewPasswordForm,
    SendCodeForm,
    ForgotPasswordForm,
} from '../components'

export const managePasswordConfig: any = {
    [AuthStep.ForgotPassword]: {
        imgKey: 1,
        component: (props: any) => <ForgotPasswordForm {...props} />,
    },
    [AuthStep.SendCode]: {
        imgKey: 2,
        component: (props: any) => <SendCodeForm {...props} />,
    },
    [AuthStep.NewPassword]: {
        imgKey: 3,
        component: (props: any) => <NewPasswordForm {...props} />,
    },
}
