import { AxiosError } from 'axios'
export const isApiError = (e: any): boolean => {
    try {
        return Boolean(e.response.data.key)
    } catch (e) {
        return false
    }
}

export const isErrorStatus = (res: AxiosError, status: number) => {
    try {
        return res.response.status === status
    } catch (e) {
        return false
    }
}

export const getDataFromApiError = (e: AxiosError) => {
    try {
        return e.response.data
    } catch (e) {
        return null
    }
}

export const isReqError = (e: any) => {
    try {
        return e && e.response && e.response.status
    } catch (e) {
        return false
    }
}
