import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { PaidElements } from '../../typing/enums'
import { useResizeWindow } from '@/core/hooks'

const positionConfigFull = {
    [PaidElements.EntryReq]: { top: '320px', left: '220px', display: 'flex' },
    [PaidElements.Notification]: {
        top: '85px',
        right: '95px',
        display: 'flex',
    },
    [PaidElements.Maskot]: {
        bottom: '110px',
        right: '120px',
        display: 'flex',
    },
}

const positionConfigMob = {
    [PaidElements.EntryReq]: {
        bottom: '15px',
        left: '4%',
        display: 'flex',
        width: '92%',
    },
    [PaidElements.Notification]: {
        top: '70px',
        left: '4%',
        display: 'flex',
        width: '92%',
    },
    [PaidElements.Maskot]: {
        bottom: '75px',
        left: '4%',
        display: 'flex',
        width: '92%',
    },
}

interface IProps {
    element: PaidElements
    txtContent: string
    goTariffs: () => void
    hideElement: () => void
    hideClose?: boolean
}
export const PopUpSubscribtion = ({
    element,
    txtContent,
    goTariffs,
    hideElement,
    hideClose = false,
}: IProps) => {
    const { width: windowWidth } = useResizeWindow()
    return (
        <div
            className={styles.container}
            style={
                windowWidth > 850
                    ? positionConfigFull[element]
                    : positionConfigMob[element]
            }>
            <div className={styles.row}>
                <Icon name='inform-full' size={24} color='#1C6AE6' />
                <p className={styles.txt}>{txtContent}</p>
            </div>

            <div className={styles.row}>
                <button onClick={goTariffs} className={styles.buy_btn}>
                    Buy now
                </button>
                {hideClose ? null : (
                    <Icon
                        name='x'
                        size={14}
                        color='rgba(0, 0, 0, 0.85)'
                        onClick={hideElement}
                    />
                )}
            </div>
        </div>
    )
}
