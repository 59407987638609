import { Placement } from 'react-joyride'

export const stepsIntro = [
    {
        target: '#category-0',
        content: 'conflictsInstruction',
        disableBeacon: true,
        title: 'conflicts',
        placement: 'right-start' as Placement,
    },

    {
        target: '#legend',
        content:
            'The red colour of the country on the map signals extremely high danger. In our case, of an armed conflict. Yellow stands for a considerable but not imminent risk. Green is for safe zone. Grey means there’s so much to know in this world! ',
        title: 'Risk graduation bar:',
        placement: 'top' as Placement,
    },

    {
        target: '#mapborder',
        content: 'mapInstructions',
        title: 'map',
    },

    {
        target: '#category-1',
        content: 'socialIstruction',
        title: 'social',
        disableBeacon: true,
        placement: 'right-start' as Placement,
    },
    {
        target: '#category-2',
        content: 'healthInstruction',
        title: 'medical',
        disableBeacon: true,
        placement: 'right-start' as Placement,
    },
    {
        target: '#category-3',
        content: 'climateInstruction',
        title: 'climate',
        disableBeacon: true,
        placement: 'right-start' as Placement,
    },

    {
        target: '#category-4',
        content: 'requirementsInstruction',
        title: 'requirements',
        disableBeacon: true,
        placement: 'right-start' as Placement,
    },
    {
        target: '#profile',
        content: 'accountInstruction',
        title: 'personalAccount',
        disableBeacon: true,
        placement: 'bottom' as Placement,
    },
    {
        target: '#travy',
        content: 'chatBotInstruction',
        title: 'chatBot',
        placement: 'top' as Placement,
    },
    {
        target: '#category-5',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla',

        disableBeacon: true,
        title: 'LGBT',
        placement: 'right-start' as Placement,
    },
    {
        target: '#category-6',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla',

        disableBeacon: true,
        title: 'women',
        placement: 'right-start' as Placement,
    },
]
