import { CSSProperties, InputHTMLAttributes, useState } from 'react'
import styles from './styles.module.css'
import { FC } from 'react'

interface FormControllProps extends InputHTMLAttributes<HTMLInputElement> {
    value: string
    onChangeVal: (val: string) => void
    label?: string
    error?: string
    styleContainer?: CSSProperties
    inputStyle?: CSSProperties
    isErrorFocused?: boolean
    readOnly?: boolean
    onClickForm?: (e: any) => void
    setFocus?: (val: boolean) => void
}
export const FormControll: FC<FormControllProps> = ({
    value,
    onChangeVal,
    error,
    label,
    styleContainer,
    inputStyle,
    isErrorFocused,
    readOnly,
    onClickForm,
    setFocus,
    ...props
}) => {
    const [focused, setFocused] = useState(false)
    const handleFocus = () => {
        setFocused(true)
        setFocus(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }

    const getClasseLabel = () => {
        let classNames = styles.label
        switch (true) {
            case focused && !value:
                classNames += ` ${styles.focused} ${styles.label_up}`
                break
            case focused && !!value:
                classNames += ` ${styles.focused} ${styles.label_up}`
                break
            case !focused && !!value:
                classNames += ` ${styles.label_up}`
                break
            case !!value:
                classNames += ` ${styles.label_up}`
                break
            case !focused && !value:
                classNames += ` ${styles.label_down}`
        }
        return classNames
    }

    const labelField = () => {
        if (!label) {
            return null
        }
        return <p className={getClasseLabel()}>{label}</p>
    }

    const getClassNames = () => {
        let classNames = styles.input_layout
        switch (true) {
            case (focused && !!error) || isErrorFocused:
                classNames += ` ${styles.error_focused}`
                break
            case !!error && !focused:
                classNames += ` ${styles.error_field}`
                break
            case focused:
                classNames += ` ${styles.focused}`
                break
        }
        return classNames
    }

    return (
        <div
            onClick={onClickForm}
            style={{
                ...styleContainer,
                cursor: props.disabled || readOnly ? 'not-allowed' : 'auto',
            }}
            className={getClassNames()}>
            <>{labelField()}</>
            <input
                readOnly={readOnly}
                style={inputStyle}
                value={value}
                onChange={e => onChangeVal(e.target.value)}
                className={styles.text_field}
                onBlur={handleBlur}
                onFocus={handleFocus}
                {...props}
            />
            {error ? <p className={styles.error}>{error}</p> : null}
        </div>
    )
}
