import styles from './styles.module.css'

interface IProps {
    label: string
    bgColor: string
}
export const LegendItem = ({ label, bgColor }: IProps) => {
    return (
        <div className={styles.legend_item}>
            <div className={styles.lining}>
                <div
                    className={styles.indicator}
                    style={{ backgroundColor: bgColor }}
                />
            </div>

            <p style={{ textWrap: 'nowrap' }}>{label}</p>
        </div>
    )
}
