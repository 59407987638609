import { PrimaryButton } from '../primary-button'
import googleLogo from '@/assets/images/google.svg'
import facebookLogo from '@/assets/images/facebook.svg'
import { createStyleSheet } from '@/core/helpers'

const socialLogo = {
    google: googleLogo,
    facebook: facebookLogo,
}

interface IProps {
    onClick: () => void
    label: string
    socialName: 'google' | 'facebook'
    disabled?: boolean
    className?: string
}

export const SocialButton = ({
    onClick,
    label,
    socialName,
    disabled,
    className,
}: IProps) => {
    return (
        <div
            style={{
                ...styles.container,
                opacity: disabled ? 0.8 : 1,
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}>
            <PrimaryButton
                className={className}
                disabled={disabled}
                leftIcon={<img src={socialLogo[socialName]} />}
                label={label}
                onClick={onClick}
                // style={styles.btn}
                labelStyle={styles.label}
            />
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    label: {
        fontWeight: '500',
        fontSize: 14,
        color: '#0F0E0E',
    },
})
