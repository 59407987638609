import sideLine from '@/assets/images/side-line.svg'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'
import { IMenuItem } from '../typing'
import { RadioButton } from '../../buttons'

interface IProps {
    index: number
    item: IMenuItem
    onClick: () => void
    isActive: boolean
}
export const MenuItem = ({ index, item, onClick, isActive }: IProps) => {
    const { t } = useTranslation()
    return (
        <div
            onClick={onClick}
            className={styles.menu_item}
            style={{
                overflow: index == 0 && 'hidden',
            }}>
            <img src={sideLine} className={styles.side_line} />
            <div className={styles.radio_row}>
                <p
                    className={
                        isActive
                            ? `${styles.label_item} ${styles.active}`
                            : styles.label_item
                    }>
                    {t(`${item.label}`)}
                </p>
                <RadioButton isActive={isActive} />
            </div>
        </div>
    )
}
