import {
    Header,
    LeftSideBar,
    MobileFilter,
    MobileHeader,
} from '@/core/components/layouts'
import styles from './styles.module.css'
import { ICategoryGroup } from '@/store'
import { CategoryRisks, SubcategoryRisks } from '@/core/typing'
import { SearchRequrementsWidget } from '@/modules/entry-requirements'
import { SeaLevelBtn } from '@/modules/map/components'
import { ContuctUsMobile } from '@/modules/root/components/contact-us-mobile'
import { RisklineNotes } from '@/modules/root/components'
import { PopUpSubscribtionWidget } from '@/modules/subscribe/widgets'

interface IProps {
    isOpenBar: boolean
    openSideBar: (val: boolean) => void
    active: ICategoryGroup
    legendRisk: JSX.Element
    map: { google: JSX.Element; weather: JSX.Element; seaLevel: JSX.Element }
    chatBot: JSX.Element
    borderMap: boolean
}
export const LayoutPageAtom = ({
    isOpenBar,
    openSideBar,
    legendRisk,
    active,
    map,
    chatBot,
    borderMap,
}: IProps) => {
    return (
        <div className={styles.layout}>
            <PopUpSubscribtionWidget />

            <MobileHeader />
            <div className={styles.side_block}>
                {active?.category === CategoryRisks.Requirements ? (
                    <SearchRequrementsWidget />
                ) : null}
                {borderMap ? (
                    <div
                        className={`${styles.map_border} `}
                        id='mapborder'></div>
                ) : null}
                <LeftSideBar toggleBar={openSideBar} isOpen={isOpenBar} />
                <Header />
                {legendRisk}
                <MobileFilter />
                <SeaLevelBtn />
                <ContuctUsMobile />
            </div>
            {active?.category === CategoryRisks.Requirements ? (
                <SearchRequrementsWidget
                    clasNameContainer={styles.position_requirements}
                />
            ) : null}
            {active?.subcategory === SubcategoryRisks.Weather && (
                <div className={styles.weater_map_container}>{map.weather}</div>
            )}

            <div
                className={
                    active?.subcategory === SubcategoryRisks.SeaLevelRise
                        ? styles.show_map
                        : styles.hide_map
                }>
                {map.seaLevel}
            </div>
            {map.google}

            <div
                className={
                    active?.subcategory === SubcategoryRisks.Weather
                        ? styles.position_weather
                        : styles.position_google
                }>
                <>{chatBot}</>
            </div>
            <RisklineNotes />
        </div>
    )
}
