import { Icon } from '@/core/components'
import styles from './styles.module.css'
import _ from 'lodash'

interface IProps {
    current: number
    length?: number
    next: () => void
    prev: () => void
    disabledNext?: boolean
}
export const QuestionsPagination = ({
    current,
    next,
    prev,
    length,
    disabledNext,
}: IProps) => {
    const prevDisabled = current === 0
    const nextDisabled = length === current + 1 || disabledNext
    return (
        <div className={styles.container}>
            <Icon
                style={{ cursor: prevDisabled ? 'not-allowed' : 'pointer' }}
                name='left-arrow'
                size={24}
                onClick={prevDisabled ? _.noop : prev}
                color={prevDisabled ? '#B1BECA' : ''}
            />

            <div className={styles.current_value}>
                <p className={styles.txt_content}>
                    {current + 1}/{length}
                </p>
            </div>

            <Icon
                style={{
                    cursor: nextDisabled ? 'not-allowed' : 'pointer',
                }}
                name='right-arrow'
                size={24}
                onClick={nextDisabled ? _.noop : next}
                color={nextDisabled ? '#B1BECA' : ''}
            />
        </div>
    )
}
