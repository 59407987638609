import { CSSProperties } from 'react'

interface IHeaderContent {
    txt: string
    labelStyle?: CSSProperties
    styleContainer?: CSSProperties
}

export const HeaderCellAtom = ({
    txt,
    labelStyle,
    styleContainer,
}: IHeaderContent) => (
    <div style={styleContainer}>
        <p style={labelStyle}>{txt}</p>
    </div>
)
