import { SubscriptionType } from '../typing/enums'

export const premium3Month = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
    'womenLgbt',
]

export const basic3Month = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
    'womenLgbt',
]

export const premium = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'emergencyPush',
    'tailoredAI',
    'sightsAI',
    'hotelTicketsAI',
    'personalizedVisa',
    'womenLgbt',
]

export const base = [
    'conflictsWorldwide',
    'socialIssues',
    'healthLabel',
    'climateLabel',
    'monthlyDataUpdates',
    'visaEntry',
    'emergencyPush',
    'womenLgbt',
]

export const subscribeLabel = {
    [SubscriptionType.Basic]: 'Basic',
    [SubscriptionType.Premium]: 'Extra',
    [SubscriptionType.Basic_3m]: 'Basic 3 month',
    [SubscriptionType.Premium_3m]: 'Extra 3 month',
}
