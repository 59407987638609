import { useEffect, useMemo, useState } from 'react'
import { useMap, useMapEvents } from 'react-leaflet'
import { WeatherPopupAtom } from '../../atoms'
import { ICategoryGroup } from '@/store'
import { geoCodePlaceReq, getWeatherReq } from '@/modules/map/api'
import * as Sentry from '@sentry/react'
import { useResizeWindow } from '@/core/hooks'

interface IProps {
    category: ICategoryGroup
    cityCoordinates: { lat: number; lng: number }
}

export const WeatherPupupWidget = ({ category, cityCoordinates }: IProps) => {
    const { width: windowWidth } = useResizeWindow()
    const [weather, setWeather] = useState(null)
    const [markerPosition, setMarkerPosition] = useState(null)
    const [location, setLocation] = useState<any>(null)
    const map = useMap()
    useEffect(() => {
        setLocation(null)
        setMarkerPosition(null)
        setWeather(null)
        map.closePopup()
        map.setZoom(2.4)
    }, [category])

    useEffect(() => {
        if (!cityCoordinates) return
        getWeather(cityCoordinates)
    }, [cityCoordinates])

    const getWeather = async (coordinate: { lat: number; lng: number }) => {
        try {
            const response = await getWeatherReq(coordinate)
            const { data: place } = await geoCodePlaceReq(coordinate)
            setLocation({
                country: place?.address.country,
                city: place?.address?.city
                    ? place?.address?.city
                    : place?.address.municipality
                      ? place?.address.municipality
                      : place?.address.county,
                region: place?.address.region
                    ? place?.address.region
                    : place?.address.state,
                coordinate,
            })
            const weatherData = response.data
            setWeather({
                temp: weatherData.current.temp_c,
                condition: weatherData.current.condition.text,
                icon: weatherData.current.condition.icon,
            })
            setMarkerPosition([coordinate.lat, coordinate.lng])
            const coordinateLat =
                windowWidth < 688 ? coordinate.lat + 100 : coordinate.lat
            map.setView([coordinateLat, coordinate.lng], 7.5)
            map.getCenter()
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const handleMapClick = async (event: any) => {
        const { lat, lng } = event.latlng
        await getWeather({ lat, lng })
    }

    useMapEvents({
        click: handleMapClick,
    })

    const customPopup = useMemo(() => {
        if (!markerPosition || !weather || !location) return null
        return (
            <WeatherPopupAtom
                position={markerPosition}
                location={location}
                temperature={weather?.temp}
                condition={weather?.condition}
                icon={weather?.icon}
            />
        )
    }, [location, weather, markerPosition])
    return customPopup
}
