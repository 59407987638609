import { GetPresignedUrlReqResponse, http } from '@/core/services'
import { IAccountForm, IUser } from '../typing/interfaces'
import { AccountSettingsPayload } from './interfaces'

export const getUploadAvatarUrl = (params: any) => {
    return http.get<GetPresignedUrlReqResponse>('account/photo-upload-link', {
        params,
    })
}
export const finishUploadAvatar = (uploadId: string) => {
    return http.put('account/photo-finish-upload', { uploadId })
}

export const getAccountReq = () => {
    return http.get<IUser>('account')
}

export const updateAccountReq = (data: IAccountForm) => {
    return http.patch('account', data)
}

export const accountSettingsReq = (data: AccountSettingsPayload) => {
    return http.put('account/settings', data)
}
