import { appEvents } from '@/core/events'
import { PrimaryButton } from '../buttons'
// import { Check } from '../form-control'
// import { Icon } from '../icons'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import { useEventsListener } from '@/core/hooks'
import { Modal } from 'antd'

export const GreetingTravy = () => {
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        if (isShow) {
            localStorage.setItem('LEGEND', JSON.stringify(true))
        }
    }, [isShow])

    useEventsListener('greeting', data => {
        setIsShow(data.isShow)
    })

    const startIntro = () => {
        appEvents.emit('greeting', { isShow: false })
        appEvents.emit('intro', { isShow: true })
    }

    const close = () => {
        appEvents.emit('greeting', { isShow: false })
        appEvents.emit('settingsNotifications', { isShow: true })
    }

    return (
        <Modal
            styles={{ body: { width: '100%' } }}
            style={{ width: 300 }}
            closeIcon={null}
            footer={null}
            classNames={{ content: styles.container }}
            open={isShow}>
            {/* <Icon
                name='x'
                className={styles.icon_close}
                size={24}
                onClick={close}
            /> */}
            <div className={styles.content_modal}>
                <p className={styles.title}>Welcome to Safe Travy!</p>
                <p className={styles.content}>
                    We help you stay safe anywhere in the world. You can use our
                    interactive tips to learn more about the platform's
                    features, or skip them and start using the service right
                    away.
                </p>

                <div className={styles.action_group}>
                    <div className={styles.btn_group}>
                        <PrimaryButton
                            className={styles.outline_btn}
                            labelStyle={{ fontSize: 14 }}
                            onClick={close}
                            label='Quit'
                        />
                        <PrimaryButton
                            style={{ width: '100%' }}
                            labelStyle={{ fontSize: 14 }}
                            onClick={startIntro}
                            label='Show Tips'
                        />
                    </div>

                    {/* <Check
                        className={styles.dont_show}
                        label='Don’t show again'
                    /> */}
                </div>
            </div>
        </Modal>
    )
}
