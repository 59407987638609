import { useEffect, useState } from 'react'
import {
    ILanguagesOption,
    languageConfig,
    languagesOptions,
} from './languages.config'
import styles from './styles.module.css'
import { Icon } from '@/core/components'
import { useLanguageStore } from '@/store'
import { Languages } from '@/core/typing'
import { storageService } from '@/core/services'

interface IProps {
    hideFlag?: boolean
}
export const ChooseLanguageWidget = ({ hideFlag = false }: IProps) => {
    const { lang, setLang } = useLanguageStore()
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguagesOption>(
        languagesOptions[0],
    )

    useEffect(() => {
        setSelectedLanguage({
            value: languageConfig[lang]?.value,
            label: languageConfig[lang]?.label,
            flagPath: languageConfig[lang]?.flagPath,
        })
    }, [lang])

    const [isOpen, setIsOpen] = useState(false)

    const toggleDropdown = () => setIsOpen(!isOpen)

    const handleLanguageSelect = (language: ILanguagesOption) => {
        setLang(language.value as Languages)
        storageService.set('lang', language.value)
        setSelectedLanguage(language)
        setIsOpen(false)
    }
    return (
        <div className={styles.dropdown_container}>
            <button className={styles.dropdown_button} onClick={toggleDropdown}>
                {hideFlag ? null : <img src={selectedLanguage.flagPath} />}
                <p className={styles.select_label}>
                    {selectedLanguage.value.toUpperCase()}
                    <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} size={16} />
                </p>
            </button>
            {isOpen && (
                <div className={styles.dropdown_menu}>
                    {languagesOptions.map(language => (
                        <div
                            key={language.value}
                            className={`${styles.dropdown_item} ${language.value === selectedLanguage.value ? styles.active : ''}`}
                            onClick={() => handleLanguageSelect(language)}>
                            <img src={language.flagPath} />
                            <p> {language.label}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
