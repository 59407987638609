import { Modal } from 'antd'
import styles from './styles.module.css'
import { PropsWithChildren } from 'react'
import { Icon } from '@/core/components'
import _ from 'lodash'

interface IProps {
    showLegend: boolean
    toggleLegend: () => void
}
export const MobileLegendRisk = ({
    children,
    toggleLegend,
    showLegend,
}: PropsWithChildren<IProps>) => {
    return (
        <Modal
            transitionName=''
            maskTransitionName=''
            closeIcon={null}
            footer={null}
            classNames={{
                content: styles.legen_modal,
            }}
            open={showLegend}>
            <div className={styles.legen_modal_content}>
                <p className={styles.title_modal}>Legend</p>
                {children}
            </div>
            <div className={styles.close_modal} onClick={toggleLegend}>
                <Icon
                    className={styles.icon_legend}
                    name='x'
                    size={24}
                    onClick={_.noop}
                />
            </div>
        </Modal>
    )
}
