import styles from './styles.module.css'

interface IProps {
    label: any
    imgPath: string
    onPress: () => void
}
export const VariantAtom = ({ label, imgPath, onPress }: IProps) => {
    return (
        <div className={styles.container} onClick={onPress}>
            <img src={imgPath} className={styles.img_illustration} />
            <p className={styles.label}>{label}</p>
        </div>
    )
}
