import { createStyleSheet } from '@/core/helpers'

interface IProps {
    value: 'q' | 'm'
    onChange: (val: 'q' | 'm') => void
}
export const SwitchTarrifs = ({ onChange, value }: IProps) => {
    return (
        <div style={styles.container}>
            <div onClick={() => onChange('q')} style={styles.switchItem}>
                <p
                    style={{
                        ...styles.switchLabel,
                        color: value == 'q' ? '#FFFFFF' : '#0F0E0E',
                    }}>
                    Quarterly
                </p>
            </div>

            <div onClick={() => onChange('m')} style={styles.switchItem}>
                <p
                    style={{
                        ...styles.switchLabel,
                        color: value == 'm' ? '#FFFFFF' : '#0F0E0E',
                    }}>
                    Monthly
                </p>
            </div>
            <div
                style={{
                    ...styles.actionItem,
                    transform:
                        value === 'q' ? 'translateX(2%)' : 'translateX(98%)',
                }}
            />
        </div>
    )
}

const styles = createStyleSheet({
    container: {
        display: 'flex',
        borderRadius: '100px',
        position: 'relative',
        width: '221px',
        height: '44px',
        border: '1px solid #97999A',
    },
    switchItem: {
        width: '50%',
        padding: '10px 13.5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    switchLabel: {
        zIndex: 999,
        fontWeight: '600',
        fontSize: 14,
    },
    actionItem: {
        position: 'absolute',
        top: 2,
        left: 0,
        width: '50%',
        height: 36,
        backgroundColor: '#2975B7',
        borderRadius: '90px',
        transition: 'transform 0.3s ease',
    },
})
