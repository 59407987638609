import { Icon } from '@/core/components'
import { INotification } from '../../typing'
import { NotificationItem } from '../notification-item'
import { createStyleSheet } from '@/core/helpers'
import { colorRiskSubCategory, iconSategoryConfig } from '../../configs'
import styl from './styles.module.css'
import { CountriesFor } from '../contries-for'
import { useResizeWindow } from '@/core/hooks'

interface IProps {
    items: INotification[]
    isOne: boolean
    handleScroll?: (e: any) => void
}
export const NotificationsFullList = ({
    items,
    isOne,
    handleScroll,
}: IProps) => {
    const { width: windowWidth } = useResizeWindow()
    const renderItem = (item: INotification, index: number) => {
        if (isOne) {
            return (
                <div className={styl.one_notification}>
                    <CountriesFor listCode={item?.countries} />
                    <p
                        className={styl.content}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                </div>
            )
        } else
            return (
                <>
                    <div className={styl.notification_item} key={index}>
                        <Icon
                            style={{
                                ...styles.iconContainer,
                                height: 30,
                                width: windowWidth > 880 ? 32 : 33,
                                backgroundColor:
                                    colorRiskSubCategory[item.type],
                            }}
                            name={iconSategoryConfig[item.type]}
                            size={18}
                            color='#FFFFFF'
                        />

                        <NotificationItem
                            item={item}
                            key={item?.id}
                            isFull={true}
                            isLast={index + 1 === items.length}
                        />
                    </div>
                </>
            )
    }

    return (
        <div
            onScroll={handleScroll}
            className={styl.notifications_list}
            id='list_notification'>
            {items.map((it, index) => renderItem(it, index))}
        </div>
    )
}

const styles = createStyleSheet({
    iconContainer: {
        borderRadius: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
