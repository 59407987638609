import { Check } from '@/core/components'

interface IProps {
    answer: string
    onChange: (val: string) => void
    isChecked: boolean
}
export const CheckAnswerAtom = ({ answer, onChange, isChecked }: IProps) => {
    return (
        <Check
            label={answer}
            value={answer}
            checked={isChecked}
            onChange={val => onChange(val.target.value)}
        />
    )
}
