import { CSSProperties } from 'react'
import { Icon } from '../../icons'
import styles from './styles.module.css'
import _ from 'lodash'

interface IProps {
    onClick: () => void
    style?: CSSProperties
}
export const GoBack = ({ onClick, style }: IProps) => {
    return (
        <div className={styles.container} onClick={onClick} style={style}>
            <Icon
                className={styles.icon_arrow}
                name='simple-arrow'
                onClick={_.noop}
                size={24}
                color='#000000'
            />
        </div>
    )
}
