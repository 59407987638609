import { IntroStepMobile } from '@/core/components'
import { mobileStepsConfig } from '@/core/components/intro-step-mobile/step.config'
import { appEvents } from '@/core/events'
import { useEventsListener, useResizeWindow } from '@/core/hooks'
import { useState } from 'react'

export const IntroStepMobileWidget = () => {
    const [run, setRun] = useState(false)
    const [isOnlyIntro, setOnlyIntro] = useState(false)

    const [stepIndex, setStepIndex] = useState(0)
    const { width: widthWindow } = useResizeWindow()

    useEventsListener('intro', data => {
        setOnlyIntro(data?.onlyIntro)

        if (widthWindow < 768) {
            setRun(false)
        }
        setRun(data.isShow)
    })
    const close = () => {
        setRun(false)
        appEvents.emit('intro', { isShow: false })
        isOnlyIntro
            ? null
            : appEvents.emit('settingsNotifications', { isShow: true })
    }

    const nextStep = () => {
        if (stepIndex === mobileStepsConfig.length) {
            close()
        } else {
            setStepIndex(stepIndex + 1)
        }
    }
    const prevStep = () => setStepIndex(stepIndex - 1)

    const step = mobileStepsConfig[stepIndex]
    if (widthWindow > 768) {
        return null
    }
    return (
        <IntroStepMobile
            close={close}
            isShow={run}
            nextStep={nextStep}
            isFirst={stepIndex === 0}
            currentStepIndex={stepIndex + 1}
            step={step}
            prevStep={prevStep}
            isLast={stepIndex + 1 === mobileStepsConfig.length}
        />
    )
}
