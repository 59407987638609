export const legendRiskConfig = [
    {
        label: 'hightRisk',
        color: '#EA6851',
    },
    {
        label: 'middleRisk',
        color: '#F8B405',
    },

    {
        label: 'lowRisk',
        color: '#33A452',
    },
    {
        label: 'notMonitored',
        color: '#BCBEC1',
    },
]
