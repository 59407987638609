import { useEffect, useState } from 'react'
import { AuthStep } from '../typing'
import { signInConfig } from '../config/signIn.config'
import { SignInLayout } from '../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loader } from '@/core/components'

export const AuthPage = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [signInstep, setStep] = useState<AuthStep>(null)

    useEffect(() => {
        if (state?.step) {
            setStep(state?.step)
        }
    }, [state])

    const step = signInConfig[signInstep]
    const StepComponent = step?.component

    const changeStep = (val: AuthStep) => setStep(val)

    const goBack = () => {
        switch (true) {
            case state?.step !== AuthStep.Register &&
                signInstep === AuthStep.Register:
                return setStep(AuthStep.Login)
            case signInstep === AuthStep.Login:
                return navigate('/')
            case state?.step === AuthStep.Register:
                return navigate(-1)
        }
    }
    if (!signInstep) {
        return <Loader />
    }
    return (
        <div
            style={{
                backgroundColor: '#f0f8ff',
                height: '100vh',
                overflow: 'auto',
            }}>
            <SignInLayout
                goBack={goBack}
                component={<StepComponent changeStep={changeStep} />}
            />
        </div>
    )
}
