import { AccountForm } from '../../components/account-form'
import _ from 'lodash'
import { useAccount } from '../../hooks'

export const AccountWidget = () => {
    const { getAccount, account } = useAccount()
    const missingEmail = account && !account?.email

    return (
        <AccountForm
            reloadAccount={getAccount}
            avatarUrl={account?.avatarUrl}
            data={account}
            editEmail={missingEmail}
        />
    )
}
