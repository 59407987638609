import { http } from '@/core/services'
import {
    ICheckOTPPayload,
    ILogOutPayload,
    IRegisterPayload,
    ISignInPayload,
    ISociapPayload,
    RestorePasswordPayload,
} from './interface'
import { Session } from '@/core/typing'

export const gregisterUserReq = (data: IRegisterPayload) => {
    return http.post<Session>('auth/sign-up', data)
}

export const signInReq = (data: ISignInPayload) => {
    return http.post<Session>('auth/sign-in', data)
}

export const requestOTPReq = (email: string) => {
    return http.post('auth/restore-password/request-otp', { email })
}

export const checkOTPReq = (data: ICheckOTPPayload) => {
    return http.post('auth/restore-password/check-otp', data)
}

export const restorePasswordReq = (data: RestorePasswordPayload) => {
    return http.post<Session>('auth/restore-password/finish', data)
}

export const socialAuthReq = (data: ISociapPayload) => {
    return http.post<Session>('auth/sign-in-by-social', data)
}

export const logOutReq = (data: ILogOutPayload) => {
    return http.post('auth/logout', data)
}
