import { presenceCost, validate, validateEmailRule } from '@/core/tools'
import moment from 'moment'
const constraints = {
    name: { presence: presenceCost },
    email: { presence: presenceCost, email: validateEmailRule },
    password: { presence: presenceCost, length: { minimum: 6 } },
    birthday: {
        presence: presenceCost,
        datetime: {
            dateOnly: true,
            latest: moment.utc().subtract(18, 'years').toDate(),
            message: '^You need to be at least 18 years old',
        },
    },
    confirmPassword: {
        presence: presenceCost,
        equality: {
            attribute: 'password',
            message: '^Passwords don’t match. Try again!',
        },
        length: { minimum: 6, message: '^Repeat min 6 characters' },
    },
}

export const validateRegister = (data: any) => {
    return validate<any>(data, constraints)
}
