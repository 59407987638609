import { CategoryRisks, SubcategoryRisks } from '@/core/typing'
import { create } from 'zustand'

export interface ICategoryGroup {
    category: CategoryRisks
    subcategory: SubcategoryRisks
}

interface CategoryGroupState {
    active: ICategoryGroup
    setGroupCategory: (category: ICategoryGroup) => void
    clearGroup: () => void
}

export const useCategoryRiskStore = create<CategoryGroupState>(set => ({
    active: null,
    setGroupCategory: data => set({ active: data }),
    clearGroup: () => set(null),
}))
