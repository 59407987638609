import { Error } from '@/core/components'
import { AutoComplete } from 'antd'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { ICountryConfig } from './typing/interfaces'
import { countriesConfig } from './countries.config'
import './styles.css'
import { IShortLocation } from '@/modules/auth/typing'
interface IProps {
    onSelectCountry: (val: IShortLocation) => void
    mb?: number
    label?: string
    error?: string
    data?: any
    disabled?: boolean
    valueHidden?: boolean
    chooseCountries?: string[]
    id?: string
    setFocus?: (val: boolean) => void
}

export const SelectCountry = ({
    onSelectCountry,
    label,
    error,
    disabled,
    data,
    valueHidden,
    chooseCountries,
    id,
    setFocus,
}: IProps) => {
    const [focused, setFocused] = useState(false)
    const storeCountries = countriesConfig
    const [options, setOptions] = useState<ICountryConfig[]>(storeCountries)
    const [value, setValue] = useState<any>()
    const [openDrop, serOpenDrop] = useState(false)
    const inputRef = useRef(null)

    const handleSearch = (value: string) => {
        const tampl = options.filter(it =>
            it?.name.toLowerCase().includes(value?.toLowerCase()),
        )
        setOptions(_.isEmpty(tampl) ? storeCountries : tampl)

        setValue(value)
    }

    const onSelect = (value: ICountryConfig) => {
        onSelectCountry({
            countryCode: value?.alpha2,
            locationName: value?.name,
        })

        setValue(value)
    }

    const transformList = options.map(it => ({
        value: it.name,
        disabled: chooseCountries?.includes(it.alpha2),
        ...it,
    }))

    useEffect(() => {
        if (valueHidden) {
            setOptions([])
            serOpenDrop(false)
            setValue('')
            setFocused(false)

            inputRef?.current?.blur()
        }
        return
    }, [valueHidden, disabled, chooseCountries])

    useEffect(() => {
        if (data) {
            handleSearch(data?.locationName)
        }
    }, [data, value])

    useEffect(() => {
        if (!value) {
            setOptions([])
            onSelectCountry(null)
        }
    }, [value])

    const getClasseLabel = () => {
        let classNames = 'label'
        switch (true) {
            case focused && !value:
                classNames += ` ${'focused'} ${'label_up'}`
                break
            case focused && !!value:
                classNames += ` ${'focused'} ${'label_up'}`
                break
            case !focused && !!value:
                classNames += ` ${'label_up'}`
                break

            case !focused && !value:
                classNames += ` ${'label_down'}`
                break
        }
        return classNames
    }

    const labelField = () => {
        if (!label) {
            return null
        }
        return (
            <p
                className={getClasseLabel()}
                style={{ opacity: disabled ? 0.6 : 1 }}>
                {label}
            </p>
        )
    }

    const getClassNames = () => {
        let classNames = 'search_layout'
        switch (true) {
            case focused:
                classNames += ` ${'focused'}`
                break

            default:
                break
        }
        return classNames
    }

    const handleBlur = () => {
        setFocused(false)
    }
    const handleFocus = () => {
        setOptions(storeCountries)
        setFocused(true)
        setFocus(true)
    }
    return (
        <div className={getClassNames()}>
            {labelField()}
            <AutoComplete
                ref={inputRef}
                className={'autocomplete_country'}
                id={id}
                onFocus={handleFocus}
                style={{
                    opacity: disabled ? 0.9 : 1,
                    width: '100%',
                    color: '#0F0E0E',
                }}
                disabled={disabled}
                options={transformList}
                onChange={val => {
                    setValue(val)
                }}
                onBlur={handleBlur}
                allowClear
                value={value}
                onSelect={(_, opt) => onSelect(opt)}
                onSearch={handleSearch}
                onDropdownVisibleChange={serOpenDrop}
                open={openDrop}></AutoComplete>
            <Error style={{ position: 'absolute', bottom: -15 }}>{error}</Error>
        </div>
    )
}
