import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'

export const ZoomControlAtom = () => {
    const map = useMap()

    useEffect(() => {
        const zoomControl = L.control.zoom({
            position: 'bottomright',
        })
        map.addControl(zoomControl)

        return () => {
            map.removeControl(zoomControl)
        }
    }, [map])

    return (
        <div>
            <button onClick={() => map.zoomIn()}>+</button>
            <button onClick={() => map.zoomOut()}>-</button>
        </div>
    )
}
