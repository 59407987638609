import { Link } from 'react-router-dom'
import styles from './styles.module.css'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
    style?: CSSProperties
}
export const FooterPrivacy = ({ style }: IProps) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container} style={style}>
            <div className={styles.links}>
                <Link className={styles.terms} to={'/policy'}>
                    {t('privacyPolicy')}
                </Link>
                <Link className={styles.terms} to={'/terms'}>
                    {t('termsService')}
                </Link>
            </div>
            <p className={styles.info}>
                © 2024 SafeTravy. {t('rightsReserved')}
            </p>
        </div>
    )
}
