import { Check } from '@/core/components'
import styles from './styles.module.css'
import { TypeNotification } from '@/modules/notifications/typing'
import { LayoutSettings } from '@/modules/notifications/components'
import { useTranslation } from 'react-i18next'

interface IProps {
    onChange?: (val: TypeNotification) => void
    disabled: boolean
    methods: TypeNotification[]
}
export const TypeSendNotification = ({
    onChange,
    methods,
    disabled,
}: IProps) => {
    const { t } = useTranslation()
    return (
        <LayoutSettings title={t('methodNotifications')}>
            <div className={styles.check_box_list}>
                <Check
                    disabled={disabled}
                    value={TypeNotification.Email}
                    onChange={val => onChange(val.target.value)}
                    label={t('onEmail')}
                    checked={methods.includes(TypeNotification.Email)}
                />

                <Check
                    disabled={disabled}
                    value={TypeNotification.Push}
                    onChange={val => onChange(val.target.value)}
                    label={t('pushNotifications')}
                    checked={methods.includes(TypeNotification.Push)}
                />
            </div>
        </LayoutSettings>
    )
}
