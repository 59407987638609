import { CSSProperties, PropsWithChildren } from 'react'
import './styles.css'
interface IProps {
    style?: CSSProperties
}
export const Error = ({ children, style }: PropsWithChildren<IProps>) => {
    if (!children) return null
    return (
        <p style={style} className='error-text'>
            {children}
        </p>
    )
}
