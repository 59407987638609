import moment from 'moment'
import { useEffect, useRef, useState } from 'react'

const SECOND = 1000

export const useCountdownTimer = (seconds: number) => {
    const [timer, setTimer] = useState(seconds)
    const interval = useRef(null)

    useEffect(() => {
        return () => {
            stopCountdown()
        }
    }, [])

    useEffect(() => {
        if (isTimeOver()) {
            stopCountdown()
        }
    }, [timer])

    const startCountdown = () => {
        interval.current = setInterval(() => {
            setTimer(prev => {
                return prev - 1
            })
        }, SECOND)
    }

    const stopCountdown = () => {
        clearInterval(interval.current)
    }

    const restartCountDown = () => {
        stopCountdown()
        setTimer(seconds)
        startCountdown()
    }

    const isTimeOver = () => timer <= 0

    const getFormattedTimer = (format = 'mm:ss') => {
        return moment().startOf('day').seconds(timer).format(format)
    }

    return {
        timer,
        restartCountDown,
        startCountdown,
        stopCountdown,
        getFormattedTimer,
        isTimeOver,
    }
}
