import { Button } from 'antd'
import styles from './styles.module.css'
import fullLogo from '@/assets/images/full-logo.svg'
import { Icon } from '@/core/components'
import { AuthStep } from '../../typing'
import { useTranslation } from 'react-i18next'

interface IProps {
    imgKey: number

    component: JSX.Element
    goBackName: string
    onGoBack: () => void
}

export const ManagePaswordLayout = ({
    component,
    goBackName,
    onGoBack,
    imgKey,
}: IProps) => {
    const { t } = useTranslation()
    const goBackButton = () => {
        return (
            <Button className={styles.go_back} type='text' onClick={onGoBack}>
                {goBackName === AuthStep.NewPassword ? null : (
                    <Icon name='left-arrow' size={20} color='#000000' />
                )}
                <p>
                    {goBackName === AuthStep.NewPassword
                        ? t('cancel')
                        : t('back')}
                </p>
            </Button>
        )
    }

    const illustrationConfig: any = {
        [1]: styles.illustration_1,
        [2]: styles.illustration_2,
        [3]: styles.illustration_3,
    }

    const tabletIllustrationConfig: any = {
        [1]: styles.tablet_illustration_1,
        [2]: styles.tablet_illustration_2,
        [3]: styles.tablet_illustration_3,
    }
    return (
        <div className={styles.container}>
            <div
                className={`${styles.img_illustration} ${illustrationConfig[imgKey]}`}>
                <div className={styles.leftContainer}></div>
                <div className={styles.content}>
                    <>{goBackButton()}</>

                    <div className={styles.header}>
                        <i
                            onClick={onGoBack}
                            className={`icon-${imgKey === 3 ? 'x' : 'left-arrow'} ${styles.left_arrow}`}
                        />
                        <img src={fullLogo} className={styles.fullLogo} />
                    </div>
                    <div className={styles.component}>{component}</div>
                </div>
                <div
                    className={`${styles.bottom_illustration} ${tabletIllustrationConfig[imgKey]}`}></div>
            </div>
        </div>
    )
}
