import { message } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { clearUrl } from '../helpers'

export const useSubscribeMessage = () => {
    const { t } = useTranslation()
    const location = window.location?.href

    const isUpgrade = (val: string) => val.includes('upgrade')

    const isDowngrade = (val: string) => val.includes('downgrade')

    const changeSubscrbeMessage = () => {
        if (isUpgrade(location)) {
            clearUrl(location)
            return message.success(t('upgradeSubscribtionMessage'), 7)
        } else if (isDowngrade(location)) {
            clearUrl(location)
            return message.success(t('downgradeSubscribtionMessage'), 7)
        } else null
    }

    useEffect(() => {
        changeSubscrbeMessage()
    }, [])
    return {}
}
