import { useMemo } from 'react'
import { AlertItemAtom, EmptyAlertsAtom } from './atoms'
import styles from './styles.module.css'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

interface IWeaterAlert {
    category: string
    title: string
    description: string
    date: string
}
interface IProps {
    alerts: IWeaterAlert[]
}

export const WeatherAlerts = ({ alerts }: IProps) => {
    const { t } = useTranslation()
    const content = useMemo(() => {
        if (!alerts || _.isEmpty(alerts)) {
            return <EmptyAlertsAtom />
        }

        return (
            <div className={styles.list_alerts}>
                {alerts.map((it, index) => (
                    <>
                        {index !== 0 ? (
                            <div className={styles.devider} />
                        ) : null}

                        <AlertItemAtom
                            category={it.category}
                            title={it.title}
                            description={it.description}
                            date={it.date}
                        />
                    </>
                ))}
            </div>
        )
    }, [alerts])

    return (
        <div className={styles.container}>
            <p className={styles.title}>{t('dangerWarning')}</p>
            <>{content}</>
        </div>
    )
}
