import { create } from 'zustand'

interface StoreUnreadNotificationsState {
    count: number
    setCount: (val: number) => void
    clearUnread: () => void
}

export const useCountUnreadNotificationsStore =
    create<StoreUnreadNotificationsState>(set => ({
        count: null,
        setCount: data => set({ count: data }),
        clearUnread: () => set({ count: null }),
    }))
