import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage, ManagePasswordPage, TermsConditionsPage } from './pages'
import { MainPage } from '../root/pages/main'
import { Page404 } from '../root/pages/404'
import { PrivacyPolicyPage } from './pages/privacy-policy'

export const AuthModule = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/auth' element={<AuthPage />} />
                <Route path='/auth/terms' element={<TermsConditionsPage />} />
                <Route path='/auth/policy' element={<PrivacyPolicyPage />} />

                <Route path='/auth/password' element={<ManagePasswordPage />} />
                <Route path='/404' element={<Page404 />} />
                <Route path='*' element={<Navigate to={'/'} />} />
            </Routes>
        </BrowserRouter>
    )
}
