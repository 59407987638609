import { useForm, useSession } from '@/core/hooks'
import { validateResetPassword } from '../../validators'
import { FormControlPassword, PrimaryButton } from '@/core/components'
import { restorePasswordReq } from '../../api'
import { IStep } from '../../typing'
import styles from './styles.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

interface FormState {
    password: string
    confirmPassword: string
}

interface IProps {
    step?: IStep
    changeStep: (val: IStep) => void
}

export const NewPasswordForm = ({ step }: IProps) => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const navigate = useNavigate()
    const starSymbol = '\u002A'
    const { saveSession } = useSession()
    const { values, setField, onSubmit, errors } = useForm<FormState>(
        {},
        validateResetPassword,
    )
    const onChangeForm = (key: keyof FormState, val: string) => {
        setField(key, val)
    }

    const submit = async () => {
        try {
            const { data } = await restorePasswordReq({
                password: values.password,
                deviceName: 'pc',
                ...step.params,
            })
            if (state?.isChange) {
                navigate('/')
                saveSession(data)
                message.success(t('passwordChangedSuccessfully'))
            } else {
                saveSession(data)
            }
        } catch (error) {
            Sentry.captureException(error)
            //
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.container_labels}>
                <p className={styles.title}>{t('newPasswordTitle')}</p>
                <p className={styles.subTitle}>{t('newPasswordMessage')}</p>
            </div>

            <div className={styles.fields}>
                <FormControlPassword
                    id='password-input'
                    value={values?.password}
                    onChangeVal={val => onChangeForm('password', val)}
                    label={`${t('newPassword')} ${starSymbol}`}
                    styleContainer={{ width: 350 }}
                    inputStyle={{
                        border:
                            errors.confirmPassword ===
                                'Passwords don’t match. Try again!' &&
                            values?.password.length >= 6
                                ? ' 1px solid red'
                                : 'none',
                    }}
                    error={errors.password}
                />

                <FormControlPassword
                    styleContainer={{ width: 350 }}
                    id='repeat-password-input'
                    value={values?.confirmPassword}
                    onChangeVal={val => onChangeForm('confirmPassword', val)}
                    label={`${t('confirmNewPassword')} ${starSymbol}`}
                    error={errors.confirmPassword}
                />

                <PrimaryButton
                    disabled={!!errors.confirmPassword}
                    label={state?.isChange ? t('changePassword') : t('signIn')}
                    onClick={() => onSubmit(submit)}
                    className={styles.btn}
                />
            </div>
        </div>
    )
}
