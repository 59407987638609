import { CSSProperties, FC } from 'react'
import '@/assets/font-icons/css/fontello.css'
interface IProps {
    name: string
    size: number
    color?: string
    onClick?: () => any
    testId?: string
    style?: CSSProperties
    className?: string
}
export const Icon: FC<IProps> = ({
    name,
    size,
    color,
    onClick,
    testId,
    style,
    className,
}) => {
    return (
        <span
            className={className}
            style={{
                color: color,
                cursor: onClick ? 'pointer' : 'default',
                ...style,
            }}
            onClick={onClick}>
            <i
                data-testid={testId}
                className={`icon-${name}`}
                style={{ fontSize: `${size}px`, margin: '-.2em' }}
            />
        </span>
    )
}
