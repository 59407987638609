import { PrimaryButton } from '@/core/components'
import styles from './styles.module.css'
import { useResizeWindow } from '@/core/hooks'

interface IProps {
    onClick: () => void
    isLoading: boolean
}

export const ReadAllBtnAtom = ({ onClick, isLoading }: IProps) => {
    const { width } = useResizeWindow()
    return (
        <PrimaryButton
            isLoading={isLoading}
            className={styles.container}
            labelStyle={{ fontWeight: '600', fontSize: width > 880 ? 14 : 12 }}
            label='Mark all as read'
            onClick={onClick}></PrimaryButton>
    )
}
