import defUser from '@/assets/images/default-user.webp'
import _ from 'lodash'

interface IProps {
    avatarUrl?: string
}
export const UserAvatarAtom = ({ avatarUrl }: IProps) => {
    return (
        <img
            src={_.defaultTo(avatarUrl, defUser)}
            style={{
                height: 32,
                width: 32,
                objectFit: 'cover',
                borderRadius: 110,
            }}
        />
    )
}
