import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Languages } from '@/core/typing'
import { translationEN } from './en'
import { translationFR } from './fr'
import { translationDE } from './de'
import { translationES } from './esp'
import { storageService } from '@/core/services'

const languageConfig = {
    [Languages.EN]: translationEN,
    [Languages.FR]: translationFR,
    [Languages.DE]: translationDE,
    [Languages.ES]: translationES,
}
const language = storageService.get('lang')

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: languageConfig['en'],
        },
        fr: { translation: languageConfig['fr'] },
        de: { translation: languageConfig['de'] },
        es: { translation: languageConfig['es'] },
    },
    lng: language,

    interpolation: {
        escapeValue: false,
    },
})

export default i18n
