import { create } from 'zustand'

interface ICountry {
    name: string
    placeId: string
    lat?: number
    lng?: number
    shortCode?: any
}

interface SearchCountryState {
    country: ICountry
    setCountry: (data: ICountry) => void
    clearCountry: () => void
}

export const useSearchCountryStore = create<SearchCountryState>(set => ({
    country: null,
    setCountry: data => set({ country: data }),
    clearCountry: () => set(null),
}))
