import logoGuido from '@/assets/images/logo.svg'
import styles from './styles.module.css'

export const GuidoAvatarAtom = () => {
    return (
        <div className={styles.container_logo}>
            <img src={logoGuido} className={styles.logo_guido} />
        </div>
    )
}
