import _validate from 'validate.js'
import _ from 'lodash'
import moment from 'moment'
const presenceCost = {
    allowEmpty: false,
    message: '^Field is required',
    meessageStatic: 'Field is required',
}

_validate.extend(_validate.validators.datetime, {
    parse: function (value: any) {
        return +moment.utc(value)
    },

    format: function (value: any, options: any) {
        const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
        return moment.utc(value).format(format)
    },
})

const prepareValidatorResult = <T extends Record<string, any>>(
    result: T,
): Record<keyof T, string> => {
    if (_.isEmpty(result)) return null
    _.each(result, (it, key, arr: any) => {
        arr[key] = it[0]
    })
    return result
}

const validateEmailRule = {
    message: '^Incorrect email',
}
const validate = <T extends Record<string, any>>(
    values: T,
    constraints: any,
) => {
    const result = _validate(values, constraints)
    return prepareValidatorResult<T>(result)
}

_validate.validators.characters = (
    value: string,
    options: {
        message: string
    },
) => {
    if (String(value).search(/[^a-zA-Zа-яА-я0-9а-яієїйьЇІ"'.)(, -]+/) !== -1) {
        return options.message ? options.message : '^Not valid characters'
    }
    return null
}

export { validate, presenceCost, validateEmailRule }
