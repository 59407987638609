import { useCategoryRiskStore } from '@/store'
import { CategoryRisks, SubcategoryRisks } from '../typing'
import { storageService } from '../services'

export const useRisksCategory = () => {
    const { setGroupCategory, active } = useCategoryRiskStore()

    const setCategoryRisks = (val: {
        category: CategoryRisks
        subcategory: SubcategoryRisks
    }) => {
        setGroupCategory(val)
        storageService.set('category', JSON.stringify(val))
    }

    const getActiveCategory = () => {
        const data = storageService.get('category')
        if (!data) {
            setCategoryRisks({
                category: CategoryRisks.Owerview,
                subcategory: null,
            })
        } else {
            setGroupCategory(JSON.parse(data))
        }
    }

    return {
        setCategoryRisks,
        getActiveCategory,
        active,
    }
}
