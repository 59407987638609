export enum CategoryRisks {
    Climate = 'clim',
    Conflicts = 'conf',
    Social = 'soc',
    Medical = 'med',
    Requirements = 'req',
    Owerview = 'ow',
    LGBTQ = 'lgbtq',
    Women = 'fem',
}

export enum SubcategoryRisks {
    SeaLevelRise = 'sealev',
    Geopolitical = 'geop',
    InternalSocialTensions = 'inttens',
    PotentialDanger = 'potdang',
    StandardOfLiving = 'standliv',
    WaterSupplies = 'watsup',
    HumanCapitalDevelopment = 'humcapdev',
    MedicalSituation = 'medsit',
    PandemicsAndEpidemics = 'epid',
    Weather = 'wea',
}
