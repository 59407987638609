import { PropsWithChildren } from 'react'
import styles from './styles.module.css'

interface IProps {
    title: string
    classNameChildren?: string
}
export const LayoutSettings = ({
    children,
    title,
    classNameChildren,
}: PropsWithChildren<IProps>) => {
    return (
        <div className={styles.container}>
            <p className={styles.title}>{title}</p>
            <div className={classNameChildren}>{children}</div>
        </div>
    )
}
