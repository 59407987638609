import styles from '../styles.module.css'

interface IProps {
    label: string
    type?: string
}
export const HeaderTeariffAtom = ({ label, type }: IProps) => {
    const typeTariff = () => {
        if (!type) return null
        return (
            <div className={styles.type_tariff}>
                <p className={styles.type_label}>{type}</p>
            </div>
        )
    }
    return (
        <div className={styles.header}>
            <p className={styles.label}>{label}</p>
            <>{typeTariff()}</>
        </div>
    )
}
