import { create } from 'zustand'

interface SessionState {
    sessionToken: string
    setSession: (data: string) => void
    clear: () => void
}

export const useSessionStore = create<SessionState>(set => ({
    sessionToken: null,
    setSession: data => set({ sessionToken: data }),
    clear: () => set({ sessionToken: null }),
}))
