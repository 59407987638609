/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { isErrorStatus } from '@/core/helpers'
import { config } from '@/config'
import { storageService } from './storage.service'
import { realTimeService } from './real-time.service'

const axiosInstance = axios.create({
    baseURL: config.API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
        'Access-Control-Allow-Headers':
            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    },
    timeout: 180000,
})

axiosInstance.interceptors.request.use(async config => {
    try {
        const token = storageService.get('accessToken')
        if (token) {
            config.headers.Authorization = 'Bearer ' + token
        }
    } catch (e) {
        /* empty */
    }
    return config
})

const requestAccessToken = async () => {
    try {
        const refreshToken = storageService.get('refreshToken')
        const { data } = await axiosInstance.post('/auth/refresh-token', {
            refreshToken,
        })
        storageService.set('accessToken', data.accessToken)
        storageService.set('refreshToken', data.refreshToken)
    } catch (e: any) {
        if (e?.response?.data?.error === 'Bad Request') {
            realTimeService._disconnect()
            storageService.set('accessToken', '')
            storageService.set('refreshToken', '')
            storageService.remove('category')
        }
        console.log('error req token', e?.response.data.error)
    }
}

const request = async <T>(func: any): Promise<AxiosResponse<T>> => {
    try {
        const response = await func()
        return response as any as AxiosResponse
    } catch (e) {
        if (isErrorStatus(e as AxiosError, 401)) {
            await requestAccessToken()
            return (await func()) as any as AxiosResponse
        }
        throw e
    }
}

export const http = {
    get: <T>(url: string, params?: AxiosRequestConfig) =>
        request<T>(() => axiosInstance.get<T>(url, params)),

    post: <T>(url: string, data: any, params?: AxiosRequestConfig) =>
        request<T>(() => axiosInstance.post<T>(url, data, params)),

    put: <T>(url: string, data: any, params?: AxiosRequestConfig) =>
        request<T>(() => axiosInstance.put<T>(url, data, params)),

    patch: <T>(url: string, data: any, params?: AxiosRequestConfig) =>
        request<T>(() => axiosInstance.patch<T>(url, data, params)),

    delete: <T>(url: string, params?: AxiosRequestConfig) =>
        request<T>(() => axiosInstance.delete<T>(url, params)),
}
