import { useTranslation } from 'react-i18next'

import styles from './styles.module.css'

interface IProps {
    icon: string
    temp_c: number
    day: string
    date: string
    humidity: number
}
export const ForecastItemAtom = ({
    date,
    day,
    icon,
    humidity,
    temp_c,
}: IProps) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div>
                <p className={styles.day}>{day}</p>
                <p className={styles.day}>{date}</p>
            </div>

            <div className={styles.temperature_container}>
                <img className={styles.icon} src={icon} />
                <p className={styles.temp_label}>{temp_c}°</p>
            </div>

            <div>
                <p style={{ color: '#717375' }}>{t('humidityForecast')}</p>
                <p className={styles.feel_label}>{humidity}%</p>
            </div>
        </div>
    )
}
