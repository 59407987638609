import { Taranslates } from '../interfaces'

export const translationFR: Taranslates = {
    // Profile
    cancel: 'Annuler',
    profile: 'Profil',
    edit: 'Modifier',
    fullname: 'Nom complet',
    countryResidence: 'Pays de résidence',
    gender: 'Genre',
    man: 'Homme',
    woman: 'Femme',
    email: 'Email',
    citіzenship: 'Nationalité',
    birthday: 'Date de naissance',
    citizenship: 'Citoyenneté',
    infoUseEmail:
        'Veuillez saisir votre email pour obtenir des informations importantes',
    errorExistEmail: "L'email est déjà enregistré",
    notifications: 'Notifications',
    chooseNotifications:
        'Choisissez le type de notifications que vous souhaitez recevoir',
    geoConflicts: 'Conflits géopolitiques',
    internalConflicts: 'Tensions sociales internes',
    potentialInsecurity: 'Insécurité potentielle',
    climate: 'Risques climatiques',
    medical: 'Risques médicaux',
    methodNotifications:
        'Choisissez la méthode par laquelle vous souhaitez être informé.',
    onEmail: 'Par email',
    pushNotifications: 'Push-notificatione',

    followCountries: 'Suivre pour les pay',
    chatBotSettings: 'Paramètres du chatbot',
    chatHistoryToEmail: "Envoi de l'historique du chatbot à votre messagerie",

    women: 'Femmes',
    bordersEntry: 'Mises à jour sur les entrées aux frontières',
    LGBTQ: 'LGBTQ+',
    transportUpdates: 'Mises à jour sur les transports',

    allow: 'Autoriser',
    deny: 'Refuser',

    sureLogout: 'Etes-vous sûr de vouloir',
    toLogout: 'vous déconnecter',
    logout: 'Se déconnecter',
    back: 'Retour',

    //Subscribtion settings
    subscription: 'Abonnement',
    cancelSubscribtionBtn: "Annuler l'abonnement",
    updateSubscriptionBtn: "Mettre à jour l'abonnement",
    yourSubscribtion: 'Votre abonnement',
    validUntil: "Valable jusqu'au",
    historySubscription: 'Historique des abonnements',
    privacyPolicy: 'Politique de confidentialité',
    termsService: "Conditions d'utilisation",
    rightsReserved: 'Tous droits réservés',
    accountOverview: 'Aperçu du compte',
    changePassword: 'Modifier mot de passe',
    save: 'Sauver',

    // Overview
    overview: "Vue d'ensemble",
    conflicts: 'Conflits',
    dangerPotential: 'Danger potentiel',
    social: 'Questions sociales',
    standardLiving: 'Niveau de vie',
    foodDrinking: 'Approvisionnement en nourriture et en eau potable',
    humanCapitalDev: 'Développement du capital humain',
    medicalSituation: 'Situation médicale',
    pandemics: 'Pandémies et épidémies',
    weather: 'Météo',
    seaLevel: "L'élévation du niveau de la mer",
    requirements: "Conditions d'entrée",
    fromCountry: 'Du pays',
    toCountry: 'Vers le pays',
    hightRisk: 'Haut',
    middleRisk: 'Moyen',
    lowRisk: 'Faible',
    notMonitored: 'Non contrôlé',

    searchCountries: 'Rechercher des pays',
    searchCity: 'Rechercher une ville',
    viewAll: 'Afficher tout',
    allNotifications: 'Toutes les notifications',
    forCountries: 'Pour les pays',
    showMore: 'Afficher plus',

    editInAccount:
        'Vous pouvez modifier ces paramètres dans votre compte personnel',
    setupNotifications: 'Configurer vos notifications',
    errorSelectCountry: 'Vous devez sélectionner au moins 1 pays',
    sureSubscribtion: 'Êtes-vous sûr de vouloir',
    cancelSubscribtion: 'annuler votre abonnement',
    unsubscribe: 'Se désabonner',
    canSubscribe: 'Vous pouvez vous réabonner à tout moment',
    successCancelSubscribtion:
        "Demande reçue. Vous aurez accès jusqu'à la fin de l'abonnement en cours.",
    successUpdateAccount: 'Mise à jour du compte réussie',

    selectGender: 'Sélectionner le sexe',
    disabledEmail: "L'e-mail ne peut pas être modifié",

    findCountries: 'Rechercher des pays',
    subscribtionNotSelected: 'Non sélectionné',

    // Instruction
    conflictsInstruction:
        "Avant de vous rendre dans un nouveau pays, vous avez peut-être des questions au niveau de la sécurité. Car les potentiels conflits armés peuvent affecter vos projets. En choisissant la catégorie 'Conflit', vous trouverez toutes les informations sur les endroits où des conflits armés sont en cours.",
    socialIstruction:
        "Besoin d'informations sur les questions sociales. La procédure est la même : choisissez l'un des sujets, la couleur du pays vous indiquera le niveau de risque, et un clic sur le pays vous fournira des informations pertinentes",
    healthInstruction:
        "La même procédure s'applique à la section médicale. Renseignez-vous sur les facteurs médicaux potentiels et leur impact sur votre sécurité.",
    climateInstruction:
        "Vérifier les conditions météorologiques et la possibilité d'événements naturels extrêmes dans le pays.",

    requirementsInstruction:
        "Vous voulez savoir comment vous rendre dans le pays ? Allez à la section Conditions d'entrée. Choisissez le pays de départ et d'arrivée dans la liste, cliquez sur Rechercher et vous obtiendrez toutes les données nécessaires, depuis les conditions d'obtention du visa jusqu'à la durée du séjour dans le pays.",
    legendInstruction:
        "La couleur rouge d'un pays sur la carte indique un danger extrêmement élevé. Dans notre cas, il s'agit d'un conflit armé. La couleur jaune correspond à un risque considérable mais non imminent. Le vert correspond à une zone sûre. Le gris signifie qu'il y a tant à savoir dans ce monde!",
    legend: 'Légende',
    chatBotInstruction:
        "Tu veux de l'aide plus spécifique? Achetez Extra et demandez à Travy, votre partenaire assisté par l'IA ! Un petit questionnaire vous sera proposé lors de la première prise de contact pour obtenir une approche plus personnalisée de Travy. Ensuite, n'hésitez pas à poser vos questions!",
    chatBot: 'Chat-bot',
    map: 'Carte',
    mapInstructions:
        "Dans cette section, choisissez l'un des sujets suivants : les conflits géopolitiques en cours dangers sociaux internes danger potentiel de conflit armé. La couleur indique le niveau de risque pour chacune des thématiques choisis. Cliquez ensuite sur un pays et lisez les informations les plus récentes sur le conflit.",
    notificationsInstruction:
        'Recevoir des alertes en temps réel sur les risques dans certains pays',
    personalAccount: 'Compte personnel',
    accountInstruction:
        'Vous voulez rester informé ? Abonnez-vous aux notifications dans la rubrique profil.',

    // Notes
    artificialIntelligenc:
        "L'article est généré par l'intelligence artificielle. Vérifiez les données importantes.",
    personalizedData: 'Données personnalisées à jour',
    search: 'Recherche',

    consideration: 'Le chatbot ne prendra pas vos réponses en considération.',
    quit: 'Quitter',

    // Tariffs
    basic: 'Basique',
    conflictsWorldwide: 'Conflits au niveau mondial',
    socialIssues: 'Problèmes socioéconomiques',
    healthLabel: 'Santé',
    climateLabel: 'Climat',

    monthlyDataUpdates: 'Mises à jours quotidiennes et mensuelles',
    visaEntry: "Conditions d'entrée générale du visa",
    emergencyPush: "Notifications d'urgence",
    freeTrial: 'Essai gratuit - 3 jours',

    extra: 'Extra ',
    tailoredAI: 'Assistant personnalisé - IA',
    sightsAI: 'Consultant pour les sites touristiques- IA',
    hotelTicketsAI:
        "Conseiller pour vos réservations d'hôtels et attractions- IA",
    personalizedVisa: "Visa personnalisé pour les conditions d'entrée/visa",
    noTrial: "Pas d'essai gratuit, paiement à l'avance",
    basic3: 'Basique de 3 mois',
    extra3: 'Extra de 3 mois',
    popular: 'Populaire',
    getStarted: 'Commencer',
    titleSubscribes: 'Explorez le monde avec SafeTravy',
    benefits: 'Avantages',
    month: 'mois',
    buy: 'Acheter',
    currentTariff: 'Plan tarifaire actuel',
    notHistory: "Pas encore d'abonnements historiques",
    newSubscribtionMessage:
        'Demande reçue. Le nouvel abonnement sera activé dans quelques minutes.',
    upgradeSubscribtionMessage:
        'Demande reçue. Le nouvel abonnement sera activé dans quelques minutes.',
    downgradeSubscribtionMessage:
        "Demande reçue. Le nouvel abonnement sera activé après l'expiration de l'abonnement actuel.",
    tariffPlans: 'Plans tarifaires',
    womenLgbt: 'Informations personnalisées pour les femmes et les LGBTQ+',
    fullSubsctiption: "Acheter l'abonnement complet",

    // Questionare
    titleTravy: "Bonjour ! Je m'appelle Travy",
    chooseVariant: 'Choisissez ce que vous voulez',
    personalisedAnswer:
        "Répondez à l'enquête pour obtenir des réponses plus personnalisées",
    yourQuestions: 'Posez votre question',
    purpouse: 'Quel est le but de votre voyage ?',
    leisure: 'Loisirs',
    business: 'Entreprises',
    familyAffairs: 'Affaires familiales/affaires personnelles',
    next: 'Prochain',

    transportationKind:
        'Quel type de transport préférez-vous pour vous rendre à votre destination ?',
    landTransportation: 'Transport terrestre',
    airTransportation: 'Transport aérien',
    seaTransportation: 'Transport maritime',

    accommodation: "Quel type d'hébergement préférez-vous?",
    hotel: 'Hôtel',
    apartament: 'Appartement',
    hostel: "L'auberge",
    holidayHome: 'Maison de vacances',

    activities: "Quels sont les types d'activités qui vous intéressent ?",
    historicalSites: 'Visiter des sites historiques',
    parks: 'Parcs naturels et réserves',
    beach: 'Vacances à la plage',
    shopping: 'Achats',
    nightlife: 'Vie nocturne',
    sports: 'Activités sportives',
    culinary: 'Visites culinaires',

    considerations:
        'Avez-vous des exigences ou des considérations particulières dont nous devrions tenir compte?',
    dietary: 'Besoins alimentaires',
    mobilityAssistance: 'Assistance à la mobilité',
    medicalConditions: 'Conditions médicales ou considérations de santé',
    sensoryAccommodations: 'Aménagements sensoriels',
    no: 'Non',

    specificFears:
        'Avez-vous des craintes ou des préoccupations particulières dont nous devrions tenir compte?',
    heights: 'Peur des hauteurs',
    fearSpaces: 'Peur des espaces clos ou surpeuplés',
    flying: "Peur de l'avion",
    water: "Peur de l'eau ou des voyages en mer",
    animals: 'Peur des insectes ou des animaux',
    culturalDifferences:
        'Préoccupations concernant les différences culturelles',

    thank: 'Merci!',
    thanYouMessage:
        'Vos réponses seront prises en compte lorsque le chatbot répondra à vos questions.',

    enterMessage: 'Entrez votre message',
    canHeplYou: "Comment puis-je t'aider?",
    notesChatBot:
        "Les informations sont générées par l'intelligence artificielle. Vérifiez les données importantes.",

    titleSignIn: 'Bon retour sur SafeTravy',
    password: 'Mot de passe',
    continue: 'Continuer',
    forgotpassword: 'Mot de passe oublié',
    signInGoogle: 'Connectez-vous avec Google',
    signInFacebook: 'Connectez-vous avec Facebook',
    haventAccount: 'Vous n’avez pas de compte?',
    signUp: "S'inscrire",
    errorEmailPassword: 'Mot de passe ou adresse e-mail incorrect. Réessayez!',

    titleSignUp: 'Bienvenue à SafeTravy',
    confirmPassword: 'Confirmer le mot de passe',

    agreeTerms: 'En vous inscrivant, vous acceptez les',
    terms: 'Conditions générales',
    policy: 'Politique de confidentialité',
    signUpGoogle: "s'inscrire à Google",
    signUnFacebook: "s'inscrire sur Facebook",
    existAccount: 'Vous avez déjà un compte?',

    signIn: "S'identifier",
    forgotPasswordTitle: 'Mot de passe oublié',
    messageSendCode:
        'Saisissez votre adresse électronique pour réinitialiser votre mot de passe. Nous vous enverrons un code',
    rememberPassword: '¿Recuerdas tu contraseña?',

    resetPassword: 'Réinitialiser le mot de passe',
    verificationCodeMessage:
        'Saisissez le code de vérification qui vous a été envoyé par courrier électronique',
    sendAgain: 'Renvoyer',

    newPasswordTitle: 'Entrez un nouveau mot de passe',
    newPasswordMessage: 'Entrez et confirmez le nouveau mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmNewPassword: 'Confirmez le nouveau mot de passe',
    passwordChangedSuccessfully: 'Le mot de passe a été modifié avec succès',
    sunrise: 'Lever de soleil',
    sunset: 'Coucher de soleil',
    realFeel: "A l'impression d'être",
    wind: 'Vent',
    pressure: 'Pression',
    humidity: 'Humidité, %',
    detailedForecast: 'Prévisions détaillées',
    night: 'Nuit',
    morning: 'Matin',
    day: 'Jour',
    evening: 'Soirée',
    temperature: 'Température',
    windMetric: 'Vent, m/sec',
    feelsLike: "A l'impression d'être",
    viceMetric: 'Étau, mm',
    humidityTable: 'Humidité, %',
    precipitationProbability: 'Probabilité de précipitations',
    dangerWarning: 'Avertissement de danger',
    UVIndex: 'Indice UV',
    humidityForecast: 'Humidité:',
    emptyAlert: 'Il semble que tout soit calme',
    emptyAlertCurrentCity: 'dans la ville actuelle',

    // subscribe
    modalTitle: 'Reçu reçu',
    subtitleModal:
        'Le nouvel abonnement sera activé dans quelques minutes. Mais les actions sont correctes.',
    contactUsTitle: 'Contactez-nous',
}
