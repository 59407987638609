import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const ContactUs = () => {
    const { t } = useTranslation()

    const openPhone = () => {
        window.location.href = `tel:+350 54095364`
    }

    const sendEmail = () => {
        window.location.href = `mailto:team@safetravy.com`
    }
    return (
        <div>
            <p className={styles.contact_us_title}>{t('contactUsTitle')}</p>

            <p onClick={sendEmail} className={styles.contact_us_item}>
                team@safetravy.com
            </p>

            <p onClick={openPhone} className={styles.contact_us_item}>
                +350 54095364
            </p>
            <p className={styles.contact_us_address}>
                SUITE 4, 2ND FLOOR, THE WEST WING,
                <br />
                MONTARIK HOUSE, 3 BEDLAM COURT
                <br /> GIBRALTAR GX11 1AA
            </p>
        </div>
    )
}
