import {
    Error,
    FormControll,
    FormControlPassword,
    PrimaryButton,
} from '@/core/components'
import { ISignInForm } from '../../typing'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
    values: ISignInForm
    onChange: (key: keyof ISignInForm, val: any) => void
    errors: Partial<ISignInForm>
    submit: () => void
    errorApi?: string
}

export const SignInForm = ({
    values,
    onChange,
    errors,
    submit,
    errorApi,
}: IProps) => {
    const { t } = useTranslation()
    const starSymbol = '\u002A'

    return (
        <div className={styles.form_container}>
            <FormControll
                type='email'
                onChangeVal={val => onChange('email', val)}
                value={values.email}
                label={`${t('email')} ${starSymbol}`}
                error={errors.email}
                isErrorFocused={!!errorApi}
            />

            <div style={{ position: 'relative' }}>
                <FormControlPassword
                    onChangeVal={val => onChange('password', val)}
                    label={`${t('password')} ${starSymbol}`}
                    error={errors.password}
                    value={values.password}
                    isErrorFocused={!!errorApi}
                />

                <Error style={{ position: 'absolute', bottom: -20 }}>
                    {errorApi}
                </Error>
            </div>

            <div className={styles.btn_container}>
                <PrimaryButton
                    disabled={!!errorApi}
                    label={t('continue')}
                    onClick={submit}
                    style={{ width: '100%', height: 48 }}
                    labelStyle={{ fontSize: 14 }}
                />
            </div>
        </div>
    )
}
