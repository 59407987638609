interface IFaQItem {
    id: string
    question: string
    answer: string
}

export const FAQsConfig: IFaQItem[] = [
    {
        id: '1',
        question: 'Які документи необхідні для подорожі за кордон?',
        answer: 'Для подорожі за кордон вам зазвичай потрібні закордонний паспорт, віза (якщо вона потрібна для країни призначення) та страхування подорожі. Деякі країни можуть вимагати додаткові документи, наприклад, медичний сертифікат або підтвердження бронювання готелю.',
    },
    {
        id: '2',
        question: 'Які ризики для здоров’я можуть виникнути в іншій країні?',
        answer: 'Залежно від країни, можуть бути ризики заразитися інфекційними хворобами, як-от малярія, жовта лихоманка чи COVID-19. Рекомендується проконсультуватися з лікарем, зробити необхідні щеплення та придбати медичне страхування.',
    },
    {
        id: '3',
        question:
            'Як підготуватися до можливих природних катастроф під час подорожі?',
        answer: 'Перед подорожжю ознайомтеся з потенційними природними катастрофами в країні (наприклад, землетруси, тайфуни або повені). Заздалегідь дізнайтеся, як реагувати в таких ситуаціях, і завжди звертайте увагу на місцеві попередження та вказівки.',
    },
    {
        id: '4',
        question:
            'Чи безпечно користуватися громадським транспортом у країні, яку я відвідую?',
        answer: 'Безпека громадського транспорту залежить від країни та регіону. Рекомендується ознайомитися з відгуками інших туристів та перевірити рекомендації від місцевих органів влади. В деяких випадках може бути безпечніше використовувати офіційні таксі або послуги відомих сервісів, таких як Uber.',
    },
    {
        id: '5',
        question: 'Як зменшити ризик крадіжок і шахрайства під час подорожі?',
        answer: 'Щоб уникнути крадіжок, не залишайте цінні речі без нагляду, використовуйте готельні сейфи та будьте обережними з незнайомцями. Рекомендується не носити великі суми готівки та бути обачними з банкоматами. Також стежте за своїми картковими даними і уникайте сумнівних угод.',
    },
]
