import { useRisksCategory } from '@/core/hooks'
import { CategoryRisks, RiskLevel, SubcategoryRisks } from '@/core/typing'
import { getOwerallRisksReq, getRisksReq } from '@/modules/risks/api'
import { IRiskCountry } from '@/modules/risks/typing'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { isNewCategory } from '@/modules/risks/helpers'

export const useGetCountries = () => {
    const [countriesPlaceIds, setPlaceIds] = useState<{
        [key: string]: RiskLevel
    }>(null)
    const [countryList, setList] = useState<IRiskCountry[]>(null)
    const [isLoading, setIsLoading] = useState(false)
    const { active } = useRisksCategory()

    useEffect(() => {
        if (!active) return

        if (active.category === CategoryRisks.Owerview) {
            getOwerallRisks()
        } else {
            getRisksCountries(active)
        }
    }, [active])

    const getOwerallRisks = async () => {
        setIsLoading(true)
        try {
            const { data } = await getOwerallRisksReq()
            const obj: any = {}
            data.forEach(it => {
                obj[it.countryPlaceId] = it.riskLevel
            })
            setPlaceIds(obj)
        } catch (error: any) {
            Sentry.captureException(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getRisksCountries = async (params: {
        category: CategoryRisks
        subcategory: SubcategoryRisks
    }) => {
        try {
            const { data } = await getRisksReq(params)
            const obj: any = {}
            data.forEach(it => {
                obj[it.countryPlaceId] = it.riskLevel
            })
            if (params?.subcategory || isNewCategory(params?.category)) {
                setList(data)
            } else {
                setList(null)
            }

            setPlaceIds(obj)
        } catch (error: any) {
            Sentry.captureException(error)
        }
    }

    return {
        getOwerallRisks,
        getRisksCountries,
        countriesPlaceIds,
        isLoading,
        countryList,
    }
}
