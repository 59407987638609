import { Icon } from '@/core/components'
import styles from './styles.module.css'
import { useMemo } from 'react'
import { createStyleSheet } from '@/core/helpers'
import { colorRiskSubCategory, iconSategoryConfig } from '../../configs'
import { INotification } from '../../typing'
import moment from 'moment'
import { ReadAllBtnAtom } from './atoms'
import { useTranslation } from 'react-i18next'

interface IProps {
    close: () => void
    isOne?: boolean
    notification: INotification
    readAll?: () => void
    isLoadingAllRead?: boolean
    isUnread: boolean
}
export const HeaderList = ({
    close,
    isOne,
    notification,
    isLoadingAllRead,
    readAll,
    isUnread,
}: IProps) => {
    const { t } = useTranslation()
    const header = useMemo(() => {
        if (!isOne) {
            return (
                <div className={styles.container}>
                    <p className={styles.title}>{t('allNotifications')}</p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 20,
                        }}>
                        <div className={styles.row}>
                            {isUnread ? (
                                <ReadAllBtnAtom
                                    onClick={readAll}
                                    isLoading={isLoadingAllRead}
                                />
                            ) : null}

                            <Icon
                                name='x'
                                size={24}
                                color='#717375'
                                onClick={close}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.container}>
                <div className={styles.is_one}>
                    <Icon
                        style={{
                            ...inlineStyle.iconContainer,
                            backgroundColor:
                                colorRiskSubCategory[notification?.type],
                        }}
                        name={iconSategoryConfig[notification?.type]}
                        size={20}
                        color='#FFFFFF'
                    />
                    <div style={inlineStyle.txtContent}>
                        <p className={styles.title}>{notification?.title}</p>
                        <p className={styles.date}>
                            {moment(notification?.createdAt).format('DD.MM.YY')}
                        </p>
                    </div>
                </div>
                <Icon name='x' size={24} color='#717375' onClick={close} />
            </div>
        )
    }, [isOne, isLoadingAllRead, isUnread])

    return <>{header}</>
}

const inlineStyle = createStyleSheet({
    iconContainer: {
        height: 40,
        width: 40,
        borderRadius: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    txtContent: {
        maxWidth: '90%',
    },
})
